import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../interceptor/axiosInterceptor";
import { PaginationInterface } from "../../../../types";
import { GetModerators } from "../../../../types/common";
const initialState = {};
export interface PostList{
 ans: string,
candidate_LINKED_IN_ID :string
full_NAME : string
id : string
location_LAT : string
location_LONG : string
prompt_ID : string
prompt_NAME : string
status : boolean
}
export interface PostResponse{
  size:number
  list:PostList[];

}
export interface candidateLinkedInPromptDetails{
candidate_LINKED_IN_ID: string
credCount : number
full_NAME: string
is_FRIEND: boolean
is_VERIFIED: boolean
location_ADDRESS: string
photo_URL: string
}
export interface FilteredUsersResponse{
  candidateLinkedInPromptDetails:candidateLinkedInPromptDetails[],
  size:number
}
export interface Post{
  message: string,
  code: number,
  data: PostResponse
  status: boolean
}

export const getAllModeratorsAction = async (pagination :PaginationInterface , id:string) : Promise<GetModerators>=>{
try{
  const response = await  axios.get(`linkedin-candidate/getAllModeratorByCommunity/${id}?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
export const assignPermission = async (payload:any) : Promise<any>=>{
  try{
    const response = await  axios.post(`linkedin-candidate/assign-permission?candidateLinkedInId=${payload?.candidateLinkedInId}&communityId=${payload?.communityId}&permissions=${payload?.permissions}
    `,)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
export const getFilteredUsers = async (candidateName:string) : Promise<any>=>{
  try{
    const response = await  axios.get(`linkedin-candidate/getAll/${candidateName}`)
    return response.data.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
  export const schedulePost = async (payload: any) : Promise<any> =>{
    const body = JSON.stringify(payload)
   try{
    const response = await  axios.post(`jobScheduler/schedulePosts`, body)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
  }
export const deleteModeratorAction = async (payload : any) : Promise<any> =>{
 try{
  const response = await  axios.delete(`linkedin-candidate/deleteModerators?candidateLinkedInId=${payload?.candidateLinkedInId}&communityId=${payload?.communityId}`)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
export const moderatorSlice = createSlice({
    name: "Moderator",
    initialState,
    reducers:{},
})

