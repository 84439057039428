import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface Toaster{
    open : boolean
    message:string
    severity:AlertColor
    handleClose:() => void
    width?:any
    
}
const Toaster:React.FC<Toaster> = ({open  , message , severity , handleClose,width="100%" }) => {
  
  return (
    <Stack spacing={2} 
    sx={{ width }}
    >
     
      <Snackbar open={open} autoHideDuration={4000}  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
        <Alert severity={severity} sx={{ width: 'fit-content' }}>
          {message}
        </Alert>
        
      </Snackbar>
     
    </Stack>
  );
}
export default Toaster