import React, { useState } from "react";
import { Layout } from "../../layout/Layout";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import UserList from "./UserList";
import ChatList from "./ChatList";
import Loader from "../Loader/Loader";
import Toaster from "../Toaster/Toaster";
import { AlertColor } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import blockUser from "../../Assets/Images/block-user.png";

export interface Message {
  id: string;
  type: string;
  fromId: string;
  toId: string;
  msg: string;
  sent: string;
  read: string;
}

export interface User {
  id: string;
  creation_DATE: string;
  sender_PHOTO: string;
  from_CANDIDATE_ID: string;
  is_RECEIVER_ALIAS: boolean;
  is_SENDER_SEEDER: boolean;
  to_CANDIDATE_ID: string;
  is_RECEIVER_SEEDER: boolean;
  last_MESSAGE: string;
  is_SENDER_ALIAS: boolean;
  sender_NAME: string;
  receiver_NAME: string;
  receiver_PHOTO: string;
}

interface UserStatus {
  exist: boolean;
  isFirebase: boolean;
  isBlocked: boolean;
  active: boolean;
  ratingStatus: boolean;
}

const SeederChat = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [chats, setChats] = useState<Message[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [chatStatus, setChatStatus] = useState<UserStatus>();

  const navigate = useNavigate();

  return (
    <Layout title="Seeder Messages">
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end", mb: "10px"  }}
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate("/compose-message");
            }}
          >
            Compose
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} >
              <UserList
                setLoading={setLoading}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpenAlert={setOpenAlert}
                setChats={setChats}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                setChatStatus={setChatStatus}
              />
            </Grid>
            <Grid item xs={7}>
              {chatStatus?.isBlocked && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Avatar
                    alt="Block User"
                    sx={{ width: 50, height: 50 }}
                    src={blockUser}
                  />
                  <Typography> This user is blocked </Typography>
                </Box>
              )}
              {chats?.length > 0 && !chatStatus?.isBlocked && (
                <ChatList
                  chats={chats}
                  selectedUser={selectedUser}
                  setSeverity={setSeverity}
                  setMessage={setMessage}
                  setOpenAlert={setOpenAlert}
                  chatStatus={chatStatus}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Loader loading={loading} />
      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
    </Layout>
  );
};

export default SeederChat;
