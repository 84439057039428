import axios from "axios";
import store from "../app/store";
import { logOut, logoutAdmin } from "../app/auth/auth";

var instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  /* other custom settings */
});
// Request interceptor: This runs before a request is sent
instance.interceptors.request.use(

    (config) => {
 const token = store?.getState()?.authReducer?.token

      if(token){
        config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
        config.headers['Authorization'] = 'Bearer ' + token
      }else{
        config.headers['Content-Type'] = 'application/json';
      }
      // You can modify the request configuration here, such as adding headers
      return config;
    },
    (error) => {
      // Handle any request error here
      return Promise.reject(error);
    }
  );

// Response interceptor: This runs before a response is handled
instance.interceptors.response.use(

    (response) => {
   // You can modify the response data here or handle it
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        setTimeout(() =>{
            store.dispatch(logoutAdmin())
          },2000)
        return  Promise.reject(error?.response?.data?.message || 'Token Expired') 
      }
      if(error?.response?.status === 400){
        return  Promise.reject(error?.response?.data?.error || 'Bad Request') 
      }
      if(error?.response?.status === 500){
        return  Promise.reject(error?.response?.data?.message || 'Internal Server Error') 
      }
      // Handle any response error here 
      return Promise.reject(error?.response?.data?.message || 'Error')
    // return Promise.resolve()
    }
  );
export default instance;

