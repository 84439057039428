import React from "react";
import Grid from '@mui/material/Grid';
import { getAnalytics } from "../../app/analytics/analytics";
import AppWidgetSummary from "../AppWidgetSummary/AppWidgetSummary";
import { Icon } from '@iconify/react';
import { AnalyticsResponse } from "../../app/analytics/analytics";
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import { AlertColor } from '@mui/material';

const GeneralisedAnalytics = function(){
  const [data, setData] = React.useState<AnalyticsResponse | undefined >(undefined);
  const [loading , setLoading] = React.useState<boolean>(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('');

    React.useEffect(() => {
      setLoading(true);
        // Fetch user data from an API when the component mounts
        getAnalytics()
        .then((res ) =>{
          setLoading(false);
          setData(res ? res : undefined)

        })
        .catch((err) =>{
          setLoading(false);
          setSeverity('error')
          setMessage(err ? err.message : "Error")
     
        })
      
     
      }, []);
return(

<>
<Grid container spacing={5}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <AppWidgetSummary title="Daily active users" total={`${data ? data?.dau : 0}`} icon={<Icon icon="ooui:user-active" style={{ fontSize: '30px' }} />} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}  lg={3}>
            <AppWidgetSummary title="Weekly active users" total={`${data ? data?.wau : 0}`} icon={<Icon icon="fa-solid:users" style={{ fontSize: '30px' }} />} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}  lg={3}>
            <AppWidgetSummary title="Referral Conversion Rate" total={`${data ? data?.referral_CONVERSION_RATE : 0}`} icon={<Icon icon="material-symbols:conversion-path" style={{ fontSize: '30px' }} />}   />
          </Grid>

          <Grid item xs={12} sm={4} md={4}  lg={3}>
            <AppWidgetSummary title="User Interaction Rate" total={`${data ? data?.user_INTERACTION_RATE : 0}`}  icon={<Icon icon="ph:user-switch-fill" style={{ fontSize: '30px' }} />} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}  lg={3}>
            <AppWidgetSummary title="Conversion Rate Save Profiles" total={`${data ? data?.crt_SAVE_PROFILES : 0}`}  icon={<Icon icon="heroicons-solid:save" style={{ fontSize: '30px' }} />} />
          </Grid>
</Grid>
<Loader loading={loading}/>
      <Toaster handleClose={() =>  setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
</>

)
}

export default GeneralisedAnalytics;
