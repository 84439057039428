import * as React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useAppDispatch } from '../../app/hooks';
import {  logOut , logoutAdmin} from '../../app/auth/auth';
import { useNavigate } from "react-router-dom";
import Loader from '../Loader/Loader';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { resetSeeders } from '../../app/seeders/seeders';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AlertColor, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import { twoFactorAuthenticationEnableMutate, twoFactorAuthenticationEnabledCheck } from '../../app/auth/twoFactorAuth';
import Toaster from '../Toaster/Toaster';
import { twoFactorAuthenticationStatus } from '../../pages/TwoFactorAuthenticationSlice';



export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity , setSeverity] = React.useState('');
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [loading , setLoading] = React.useState<boolean>(false);
  const [openPophover,setOpenPophover]= React.useState(false);
  const [checked, setChecked] = React.useState(false);

 
  const userInfo = useSelector((state: any) => state);

  const dispatch = useAppDispatch();

  const check2FAEnabled =async(email)=>{
    const response:any = await twoFactorAuthenticationEnabledCheck(email)
    setChecked(response?.data?.is2FaEnabled);
 
 }

  React.useEffect(() => {
    check2FAEnabled(userInfo?.authReducer?.email);
  }, [])
  

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenPophover((prev)=>!prev)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () =>{
    setLoading(true)
    dispatch(resetSeeders())
    setTimeout(() =>{
        dispatch(logoutAdmin())
        navigate('/login')
        setLoading(false)
    },1000)
    dispatch(
      twoFactorAuthenticationStatus({
        isQrGenerated: false,
        is2FaEnabled: false,
        is2FaVerified: false,
      })
    );
}
function getFirstWordOfEmail(email) {
  const [localPart] = email?.split('@');
  const words =localPart?.split(/[^a-zA-Z0-9]+/);
  return words[0];
  
}

const handleChangeEnable=async(payload)=>{

  setLoading(true);
  try{
    const response = await twoFactorAuthenticationEnableMutate(payload);
    
    if(response?.status){
    setOpenPophover(false);
    setSeverity("success");
    setMessage(response?.message)
    setOpenAlert(true);
    if(payload?.enabled){
      logout();
    }
  }
  else{
    setOpenPophover(false);
    setSeverity("error");
    setMessage(response?.message)
    setOpenAlert(true);
  }
  }
  catch(err:any){
    setOpenPophover(false);
    setSeverity("error");
    setMessage(err?.message || "Something went wrong")
    setOpenAlert(true);
  
  }
  finally{
    setLoading(false)
  }
}

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setChecked(event.target.checked);
  handleChangeEnable({enabled:event.target.checked,email:userInfo?.authReducer?.email})
};

  return (
    <React.Fragment>
      
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
          
            <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
          </IconButton>
        </Tooltip> */}
          <Tooltip title={getFirstWordOfEmail(userInfo?.authReducer?.email)}>
          <Avatar sx={{mr:"10px",width: 30, height:30}} onClick={handleClick}>{userInfo?.authReducer?.email?.charAt(0)?.toUpperCase()}</Avatar>
          </Tooltip>

          <Popover
        open={openPophover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      
      >
       <Grid container sx={{width:"200px", p:"10px"}}>
<Grid item xs={12} sx={{display:"flex", justifyContent:"space-between", mb:"10px", p:"10px",borderBottom:"2px solid", borderBottomColor:"#f4f4f4"}}> 
<Typography sx={{fontWeight:500,}}> Setting</Typography>
<CancelIcon onClick={()=>{
  setOpenPophover(false);
}} sx={{color:"primary.main"}}/>
</Grid>
<Grid item xs={12} sx={{display:"flex", justifyContent:"space-between", p:"10px",}}>
<Typography> 2FA</Typography>
<Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      size="small"
    />
</Grid>
       </Grid>
      </Popover>
        <Tooltip title="Log out">
        <LogoutIcon style={{cursor:"pointer"}} onClick={logout}/>
        </Tooltip>
    
      </Box>
      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu> */}
      <Loader loading={loading}/>
    
      <Toaster
        handleClose={() => {
          setOpenAlert(false);
        }}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
        width="auto"
      />
    </React.Fragment>
  );
}
