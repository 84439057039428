import React, { useState, useEffect } from "react";
import { Layout } from "../../layout/Layout";
import { GridColDef } from "@mui/x-data-grid";
import DataGridTable from "../../components/table/DataGridTable";
import {
  ReportedPostResponseDto,
  deleteReportedPosts,
  getReportedPosts,
} from "../../app/reportPosts/reportPost";
import Toaster from "../Toaster/Toaster";
import { AlertColor, Button } from "@mui/material";
import { Box } from "@mui/system";
import DeleteModal from "../Modal/DeleteModal";
import { DeleteResponse } from "../../app/reportedComments/reportedComments";

const ReportedPostTable = () => {
  const [data, setData] = useState<ReportedPostResponseDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [selected, setSelected] = useState<string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })

  const getDataHandler = async () => {
    setLoading(true);
    try {
      const response = await getReportedPosts(pagination);
      setData(response?.data?.reportedPostsResponseDtos || []);
      setRowCount(response?.data?.totalCount || 0)
    } catch (err: any) {
      setSeverity("error");
      setOpenAlert(true);
      setMessage(err?.message || "Something went wrong !");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataHandler();
  }, [pagination]);

  const handleDelete = () => {
    setOpenDeletePopup(true);
  };

 
  const columns: GridColDef[] = [
    {
      field: "postCreatorName",
      headerName: "Post Creator Name",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "postReporterName",
      headerName: "Post Reporter Name",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "ans",
      headerName: "Answer",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
  ];
  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }
  return (
    <Layout title="Reported-Posts">
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "7px" }}>
        <Button
          onClick={handleDelete}
          disabled={selected?.length > 0 ? false : true}
          variant="outlined"
          color="error"
        >
          Delete
        </Button>
      </Box>
      <DataGridTable
        columns={columns}
        row={data}
        loading={loading}
        checkboxSelection={true}
        setSelected={setSelected}
        pagenated={pagenated} 
        rowCount={rowCount}
      />
      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
      <DeleteModal
        description={"you want to delete selected reported posts?"}
        open={openDeletePopup}
        handleClose={() => {
          setOpenDeletePopup(false);
        }}
        handleDelete={async () => {
          setLoading(true);
          const payload = selected;
          const response: DeleteResponse = await deleteReportedPosts(payload);
          if (response?.code === 200) {
            getDataHandler();
            setSeverity("success");
            setOpenAlert(true);
            setMessage(
              response?.message || "Reported post delete Successfully."
            );
          } else {
            setOpenAlert(true);
            setSeverity("error");
            setMessage(
              response?.message || "Unable to delete reported post."
            );
          }
          setOpenDeletePopup(false);
          setLoading(false);
        }}
      />
    </Layout>
  );
};

export default ReportedPostTable;
