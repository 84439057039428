import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { message } from "../../../types/common";
import {Pagable} from '../../../types/index'
import {TipsPayload , AddTips} from '../../../types/tipsTypes'
const initialState = {};

export interface TipsList{
    createdAt: string,
    createdBy: string,
    id: string,
    title: string,
    body:string,
    updatedAt : string,
    updatedBy: string,
    status:boolean
}
export interface TipResponse{
  size:number
  list:TipsList[];

}

export interface TipsResponse{
  message: string,
  code: number,
  data: TipResponse
  status: boolean
}
export const getTipsAction = async () : Promise<TipsResponse> =>{
try{
  const response = await  axios.get(`tips/admin/getAll`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}

export const addTipsAction =async (payload: TipsPayload) : Promise<AddTips> =>{
  const body = JSON.stringify(payload)
try{
  const response = await  axios.post(`tips/add`, body)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}

export const deleteTipsAction = async (id : string) : Promise<message> =>{
 try{
  const response = await  axios.delete(`tips/${id}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
 }
export const tips = createSlice({
    name: "Tips",
    initialState,
    reducers:{},
})

