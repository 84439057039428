import React, { useState } from 'react';
import { Layout } from '../../layout/Layout';
import Button from '@mui/material/Button';
import UserGeneralisedComponent from './UserGeneralisedComponent';



export default function Users() {
  const [addModal, setAddModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [toggleStatus , setToggleStatus] = React.useState("")
  const [userId , setUserId] = React.useState("")
  const [editUserDetails , setEditUserDetails] = React.useState<any>()
  const [addUser,setAddUser]= useState(false);


  React.useEffect(() =>{
    setToggleStatus("")
  },[changeStatus])

 


  function changeStatus(status:string){
    status === 'Active' ? setToggleStatus('Deactivate') : setToggleStatus('Active')
  }
 
  const openAddModal = () => {
    setAddModal(!addModal)
  }

  function deleteUser(userId: string) {
    setUserId(userId)
    setDeleteModal(true)
  }

  function editUser(row:any) {
    setEditModal(true)
    setEditUserDetails(row)
  }

  const closeModal = () => {
    setAddModal(false);
    setDeleteModal(false)
    setEditModal(false)
  };

  return (
    <>
      <Layout  title="Users">
        <div>
        <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => {setAddModal(true)}} variant="contained">Upload</Button>
        <Button  variant="contained" sx={{ml:"5px"}} onClick={()=>{
          setAddUser(true);
        }}>Add User</Button>
        </div>
        <UserGeneralisedComponent userId={userId}  editDetails={editUserDetails} status={toggleStatus}  editUserModal={editModal} addUserModal={addModal} deleteUserModal={deleteModal}  closeModal={closeModal} createUser={addUser} setCreateUser={setAddUser}/>
      
</div>
          
      </Layout>
    </>
  );
}