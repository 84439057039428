import React , {useEffect , useState}from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import { addPoliticsAction, editPoliticsAction, getPoliticsAction, deletePoliticsAction } from '../../app/politics/politics'
import { AlternateEmail, Delete as DeleteIcon} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor, Grid, Autocomplete, TextField, Checkbox, FormControlLabel } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import {Type} from '../../enum/index'
import { BasePayload } from '../../../types/common'
import { nameSchema,EditSchema } from '../../schema/common'
import { PoliticsList } from '../../app/politics/politics'
import { getPostManagerAction,deletePostManagerAction,editPostManagerAction } from '../../app/seeders/postManager'
import Comments from '../Comments/Comments'
import { getCommunityAction } from '../../app/community/community'
import EditPost from './EditPost'
import DeleteModal from '../Modal/DeleteModal'
interface PoliticsProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editEducationModal:boolean
    politicsId:string
    editDetails:GridRowParams | null
    deletePolticsModal:boolean
    isComments?:boolean
}
const PostManagerGeneralisedComponent: React.FC<PoliticsProps> =  ({isComments,deletePolticsModal,politicsId , addModal , closeModal , columns , editEducationModal , editDetails }) => {
  const [politics, setPolitics] =  useState<any>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [communities , setCommunities] = React.useState<any>([])
  const [filterCommunity , setFilterCommunity] = React.useState<string>("")
  const [seedersOnly, setSeedersOnly] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('') 
  const [rowCount,setRowCount]= useState<number>(0);
  const [communityRowCount,setCommunityRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [paginationForCommunity , setpaginationForCommunity] = React.useState({
    pageNo:1,
    pageSize:10
  })

  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    const param={
      pageNo:pagination.pageNo,
      pageSize:pagination.pageSize,
      seeder:seedersOnly
    }
    getPostManagerAction(param)
      .then((res) => {
        setDataLoading(false);
        if(!res.status){
        }else{
          setRowCount(res?.data?.totalCount || 0)
          setPolitics(res.data.list ? res.data.list : []);
        }       
      })
      .catch((err) => {
        setSeverity('error')
        setOpenAlert(true)
        setMessage(err ? err.message : 'Error')
        setDataLoading(false);
      });
  }, [toggle,seedersOnly , pagination]);

  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getCommunityAction(paginationForCommunity)
      .then((res) => {
        if(!res?.status){
          setDataLoading(false);
        }else{
          setCommunities(res?.data?.list ? res.data?.list : []);
          setCommunityRowCount(res?.data?.totalCount)
          setDataLoading(false);
        }      
      })
      .catch((err) => {
          setDataLoading(false);
      });
  }, [paginationForCommunity]);


const initialvalues = {
        title:"",
        ans:"",
        communityPhoto:""
} 


const addPolitics = async(values :  BasePayload) =>{
  setLoading(true)
  //Call Api To Add User
addPoliticsAction(values)
    .then((res) =>{
     if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
     }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res?.message :  'Successfully Added')
      setToggle(!toggle)
     }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : 'Error')
    })
}
const editPolitics = async(values : BasePayload) =>{
  let url = values['postUrl'] ? values['postUrl'] .split(',') : ""
  values['postUrl'] = url[1]
  setLoading(true)
  //Call Api To Edit User
  editPostManagerAction(values)
  .then((res) =>{
if(!res?.status){
  setLoading(false)
  setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res?.message :'Record not found')
  
}else{
  setLoading(false)
  setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res?.message :'Successfully Updated')
      setToggle(!toggle)
}
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : 'Error')
  })
}
const deletePolitcs = async (educationId:string) => {
  setLoading(true)
  deletePostManagerAction(educationId)
  .then((res) =>{
   if(!res?.status){
    setLoading(false)
    setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Record not found')
   } else{
    setLoading(false)
    setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Successfully Deleted')
      setToggle(!toggle)
   }
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : 'Error')
   
  })
  //Call Api To Delete User
};
const addModalFields = [
    {
      name: 'name',
      label: 'Name',
      type:'text'
      
    },
    
];

  const handleSubmit = (values : BasePayload , type : string)=> {
    // Handle form submission here
    if(type === Type.Add){
      closeModal()
      addPolitics(values)
    }else if(type === Type.Edit){
      closeModal()
      editPolitics(values)
    }
  };

  const clearFilter=()=>{
    setFilterCommunity("")
    setSeedersOnly(false)
  }

  const handleCheckboxChange = (e) => {
    setSeedersOnly(e.target.checked);
  };

  const ModelClose=(data)=>{
      closeModal()
      setSeverity(data.serverity)
      setOpenAlert(data.alert)
      setMessage(data.message)
      setToggle(!toggle)
  }
  
  const politicsData = filterCommunity 
  ? politics.filter((val) => val.communityName === filterCommunity)
  : politics;

  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }
  const handleScroll = (event , setPage , rowCount? ,paginationSize?) => {
    const bottom =
      event.target.scrollHeight === event.target.clientHeight + event.target.scrollTop;
      if (bottom && paginationSize < rowCount) {
        setPage();
      }
  };
  function setPage(){
    setpaginationForCommunity((prev) =>{ return {...prev , pageSize: prev?.pageSize + 10}})
  }
  return <>
  <Grid container spacing={2}  p={1.5}>
      <Grid item xs={12} sm={2}>
          <Autocomplete
          size="small"
          disablePortal
          options={communities?.map((val) => val?.communityName || '')}
          fullWidth
          onChange={(e, newValue) => {
            if (newValue !== null) {
              setFilterCommunity(newValue);
            } else {
              setFilterCommunity('');
            }
          }}
          ListboxProps={
            {
              onScroll:(e) => handleScroll(e ,setPage , communityRowCount , paginationForCommunity?.pageSize )
            }
          }
          value={filterCommunity || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by community"
              name="filterCommunity"
            />
          )}
        />
            </Grid>
            <Grid item xs={12} sm={2}>
            <FormControlLabel 
            control={<Checkbox   
            checked={seedersOnly}
              onChange={handleCheckboxChange}/>}
              label="Seeders only" />
            </Grid>
            <Grid item xs={12} sm={2}>
            <Button  variant="contained" onClick={clearFilter}> Clear Filter</Button>
            </Grid>
        </Grid>
   <DataGridTable columns={columns} row={politicsData} loading={dataLoading} pagenated={pagenated} rowCount={rowCount} />
  <Modal
     open={isComments || false}
     title="Comments"
     onClose={closeModal}
     maxWidth='lg'
     form={<Comments commentData={editDetails}/>}
  />
    {/* { Add Modal } */}
    <Modal
        open={addModal}
        title="Add Politics"
        onClose={closeModal}
        form={<GenericForm title='Add Politics' validationSchema={nameSchema} type='Add Politics'  fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
      />
      {/* Edit Modal */}
      <Modal
              open={editEducationModal}
              title="Edit Post"
              onClose={closeModal}
              form={<EditPost closeModal={closeModal} editedDetails={editDetails} modelClose={ModelClose}/>}
     />    
       

{/* delete modal */}

<DeleteModal description={"you want to delete this post."} open={deletePolticsModal} handleClose={closeModal} handleDelete={()=>{
        deletePolitcs(politicsId);
        closeModal();
      }} />


         <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default PostManagerGeneralisedComponent;