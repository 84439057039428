// UserCrudComponent.js
import React, { useState, useEffect } from 'react';
import DataGridTable from '../../../table/DataGridTable';
import GenericForm from '../../../form/GenericForm';
import Modal from '../../../Modal/Modal';
import {  GridColDef } from '@mui/x-data-grid';
import { uploadCSV, getUsers  } from '../../../../app/users/users';
import {Type} from '../../../../enum/index'
import { uploadSchema } from '../../../../schema/upload';
import { AlertColor } from '@mui/material';
import Loader from '../../../Loader/Loader'
import Toaster from '../../../Toaster/Toaster'
import { useAppDispatch } from '../../../../app/hooks';
import { logoutAdmin } from '../../../../app/auth/auth';
import { getApprovedUserAction } from '../../../../app/community/community';
import { communityResponseDtos } from '../../../../../types/common';
import { useAppSelector } from '../../../../app/hooks';
import { selectCommunityState } from '../../../../app/communityEditDetails/communityEdit';
interface UserProps{
    addUser?:() => void
    columns : GridColDef[]
    addUserModal:boolean
    closeModal:() => void
    deleteUserModal:boolean
    editUserModal:boolean
    status:string
    editDetails:any | undefined
    userId:string
}
interface myFile{
myFile:File

}
const ApprovedUserGeneralisedComponent:React.FC<UserProps>  = ({ userId, editDetails, columns,addUserModal,closeModal , deleteUserModal , editUserModal,status}) => {

  const [users, setUsers] =  useState<communityResponseDtos[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('');
  const [loading , setLoading] = React.useState<boolean>(false)
  const dispatch = useAppDispatch();
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const state:any = useAppSelector(selectCommunityState).editDetails

  
  const initialValues = {
    myFile:undefined
 }

  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getApprovedUserAction(pagination , state?.id)
    .then((res : any) =>{
      setDataLoading(false);
      if(!res?.status){  
      }else{
        setUsers(res.data.communityResponseDtos ? res.data.communityResponseDtos.reverse() : [])
        setRowCount(res?.data?.totalCount || 0)
      }   
    })
    .catch((err:any) =>{
        setSeverity('error')
        setOpenAlert(true)
        setMessage(err ? err.message : "Error")
        setDataLoading(false);
      
    })

 
  }, [status , pagination]);

 
  const upload = async(values:myFile) =>{
    //Call Api To Add User
    const formData = new FormData();
     formData.append('file', values['myFile']);

    uploadCSV(formData)
    .then((res) =>{
      if(res?.status === 207){
        setSeverity('error')
        setOpenAlert(true)
        setMessage("wrong data format in uploaded file see the correct data format in downloaded file")
        return  res?.data;
      }else if(res?.status === 200){
        setSeverity('success')
        setOpenAlert(true)
        setMessage(res ? res.data.message : "Successfully Uploaded")
      }
    })
    .then((blob) =>{
  if(blob){
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `error.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  }
    })
    .catch((err:any) =>{
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err?.message : "Error")
    })

    
    
      
   
  }


  const handleSubmit = (values:myFile, type:string) => {
    // Handle form submission here
    if(type === Type.Upload){
      closeModal()
      upload(values)
    }
    
  };

  const addModalFields = [
    {
      name: 'myFile',
      label: 'Upload CSV',
      type:'file'
      
    }
 ];
 function pagenated(pageNo:number , pageSize:number){
  setpagination(() =>{
    return {
      pageNo:pageNo,
      pageSize:pageSize
    }
  })
}
  return(
    <>
    <DataGridTable columns={columns} row={users} loading={dataLoading} pagenated={pagenated} rowCount={rowCount}/>
{/* { Add Modal } */}
     <Modal
        open={addUserModal}
        title="Upload File"
        onClose={closeModal}
        form={<GenericForm title='Upload File' validationSchema={uploadSchema} closeModal={closeModal} label='CSV File' type={Type.Upload} fields={addModalFields}  initialValues={initialValues}    onSubmit={handleSubmit}/>}
      />
      <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>

</>
    
    
)


};

export default ApprovedUserGeneralisedComponent;