import * as Yup from 'yup';
interface FileWithSize extends File {
  size: number;
}
export const postSchema = Yup.object({
  communityId: Yup.string()
  .required('Community is required'),
  candidateLinkedInId: Yup.string()
      .required('Seeder is required'),
    body:Yup.string()
    .min(3,'Body must be at least of 3 characters')
    .matches(/^[A-Za-z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,'Enter valid characters only')
    .required('Content is required'),
    title: Yup.string()
      .min(3,'Title must be at least of 3 characters')
      .matches(/^[A-Za-z][A-Za-z ]*$/, 'Title must start with alphabetic character and only contain alphabets')
      .required('Title is required'),
    // locLat:Yup.string()
    // .matches(/^(-?)([0-9]{2,}|[1-8][0-9]|90)\.[0-9]{2,6}$/, 'Must contain only decimal values minimum 2 digits before and after decimal')
    // .required('Latitude is required'),
    // locLong:Yup.string()
    // .matches(/^(-?)([0-9]{2,}|[1-9][0-9]|[1-7][0-9]|180)\.[0-9]{2,6}$/, 'Must contain only decimal values minimum 2 digits before and after decimal')
    // .required('Longitude is required'),
    dateTime:Yup.date()
    .required('Date is required'),
    myFile: Yup.mixed()
    .nullable()
   .required('Image is required')
   .test('fileType', 'Only JPG, PNG, and JPEG are allowed', (value) => {
    if (!value) return true; // Allow empty values?
    return ['image/jpg', 'image/jpeg', 'image/png'].includes(value && (value as FileWithSize).type);
  }),
});