import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { Payload } from "../../../types/common";
import {Pagable} from '../../../types/index'
import  {message } from "../../../types/common";
import { RootState } from "../store";
const initialState = {
  communitites:null,
  actionType:'',
};
interface Communities{
  id: string, communityName: string
}
export interface SeederUsersList {
  aboutMe: string
  anonymousName: string
  communities: Communities[]
  email: string
  firstName: string
  fullName: string
  id: string
  isSeeder: boolean
  lastName: string
  photoUrl: string
  totalRatingUser: number
  }
  interface Data{
    size: number
    totalCount: number
    seederCandidateListResponseDtos:SeederUsersList[]
  
  }
  export interface SeederUsers{
      message: string,
      code: number,
      data: Data,
      status:boolean
  }  
interface pagination  {
  pageNo:number,
  pageSize:number
}
export interface CheckChatData{
     exist: boolean,
    isFirebase: boolean,
    isBlocked: boolean,
    active: boolean,
    ratingStatus: boolean
}
export interface CheckChatResponse{
  message: string,
  code: number,
  data: CheckChatData,
  status:boolean
}  
interface CommentPayload {
  candidateLinkedInId: string,
  comment: string,
  parentId: string | null,
  promptDetailsId: string,
  promptType: string
}

interface ChatParam {
  id:string,
  prompt:string
}

interface UpdateMessageData{
  aboutMe: string | null
anonymousCount : number | string
anonymousName: string
birthday: null | string
collegeInfos: []
commentCount: null | string
countryCode: string
createdAt: string
createdBy: string
currentLat: null | string
currentLong: null | string
email: string
employments: []
eventId: string | null
facebookUrl: string | null
fcmToken: string
firstName: string
followerCount: number | null
fullName: string
gender: null | string
hasMessage: boolean 
id: string
industry: null | string
instagramUrl: null | string
interestId: null | string
isBlocked: null | string
isBornAfter2004: boolean
isConnected: null | string
isEducation: boolean
isEmployment: boolean
isEndrose: null | string
isExist: null | string
isFriend:  null | string
isIndustry: boolean
isLinkedin: boolean
isLogin: boolean
isNetworking: boolean
isPolitics: boolean
isPortfolio: boolean
isProfile: boolean
isProfilePic: boolean
isPrompt: boolean
isSeeder: boolean
isSocialMedia: boolean
isStar:  null | string
isVerified: boolean
karmaCount: number 
lastName: string
linkedinUrl:  null | string
locationAddress:  null | string
locationLat:  null | string
locationLong:  null | string
mutualConnect:  null | string
mutualInterest: boolean
networkings: []
notificationStatus: boolean
phoneNumber: string
politics: null | string
portfolioUrl: null | string
profilePhotoUrl: string
promptCount:  null | string
promptDetails: []
ratingStatus: boolean
refreshToken: null | string
softFilterValue: null |string
status: boolean
statusCount: number
stripeCustomerId: null | string
stripeEventUpdateDate: null | string
stripeSession: null | string
tiktokUrl: null | string
token: null | string
totalRating : string
totalRatingUser: string
twitterUrl:  null | string
updatedAt: string 
updatedBy:  null | string
}
export interface UpdateMessageResponse{
  message: string,
  code: number,
  data:  UpdateMessageData,
  status:boolean
}  
interface UpdateMessage{
  id:string,
  hasMessage:boolean
}
export const getSeederAction = async (pagination:pagination , filteredValue?:string) : Promise<SeederUsers> =>{
try{
  const response = await  axios.get(`linkedin-candidate/seeder/getAllSeederUsers?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}&searchName=${filteredValue ? filteredValue : ""}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
//check chat
export const checkChat = async (seederID : string , userId:string , isUserAnon : boolean) : Promise<CheckChatResponse> =>{
  try{
    const response = await  axios.get(`linkedin-candidate/seeder/status/${seederID}/${userId}?isUserIdAnon=${isUserAnon}`)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
//get seeder messages 
export const getSeederMessages = async (pagination:any) : Promise<any> =>{
  try{
    const response = await  axios.get(`linkedin-candidate/seeder/getSeederMessages?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
export const getSeederCandidatesByCommunity = async (payload:any) : Promise<any> =>{
  try{
    const response = await  axios.get(`/linkedin-candidate/seeder/getSeederCandidatesByCommunity/${payload?.id}?pageNumber=${payload?.pageNo}&pageSize=${payload?.pageSize}`)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
  export const getSeederMessageChat = async (payload:any) : Promise<any> =>{
    try{
      const response = await  axios.get(`/linkedin-candidate/seeder/getSeederMessages?pageNumber=1&pageSize=10`)
      return response.data;
    }catch(err:any){
      throw new Error(err)
    }
    }
  export const getSeederChatData = async (payload:ChatParam) : Promise<any> =>{
    try{
      const response = await axios.get(`/linkedin-candidate/seeder/getRepliesByCommentId/${payload?.id}?prompt_type=${payload?.prompt}`)
      return response.data;
    }catch(err:any){
      throw new Error(err)
    }
    }
  
    export const deleteSeederComment = async (id:string) =>{
      try{
        const response = await axios.delete(`/linkedin-candidate/seeder/deleteCommentById?commentId=${id}`)
        return response.data;
      }catch(err:any){
        throw new Error(err)
      }
      }

export const addSeederAction = async (payload: any) : Promise<any> =>{
  // const body = JSON.stringify(payload)
 try{
  const response = await  axios.post(`linkedin-candidate/seeder/add`,payload)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}

export const addSeederCommentAction = async (payload: CommentPayload) : Promise<any> =>{
  const body = JSON.stringify(payload)
 try{
  const response = await  axios.post(`linkedin-candidate/seeder/addComment`,body)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
// update has message
export const updateHasMessage = async (payload: UpdateMessage) : Promise<UpdateMessageResponse> =>{
  const body = JSON.stringify(payload)
 try{
  const response = await  axios.post(`linkedin-candidate/seeder/update-has-message`,body)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
// notification
export const sendSingleNotification = async (payload: any) : Promise<any> =>{
  const body = JSON.stringify(payload)
 try{
  const response = await  axios.post(`linkedin-candidate/seeder/send-single-notification`,body)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
export const editSeederAction = async (payload: any) : Promise<any> =>{
  const body = JSON.stringify(payload)
 try{
  const response = await  axios.put(`linkedin-candidate/seeder/edit`,body)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
export const deleteSeederAction =async (id : string) : Promise<any> =>{
  try{
   const response = await  axios.delete(`linkedin-candidate/seeder/delete/${id}`)
   return response.data;
  }catch(err:any){
   throw new Error(err)
  }
 }
export const seederSlice = createSlice({
    name: "seeders",
    initialState,
    reducers:{
      setCommunitie: (state, action) => {
        return {
          ...state,
          communitites: action.payload,
        };
      },
      setType: (state, action) => {
        return {
          ...state,
          actionType: action.payload,
        };
      },
      resetSeeders: (state) => {
        return {
          communitites:null,
          actionType:'',
        };
      },
     
    },
})

export const { setCommunitie , setType , resetSeeders} = seederSlice.actions;
export const selectSeederState = (state: RootState) => state?.seederReducer;
export default seederSlice.reducer;