import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
    name: "drawer",
    initialState: {
        open: true,
      },
    reducers:{
        toggleDrawer(state:any) {
          return {
            ...state,
            open: !state.open
          }
          },
     
    },
})

export const { toggleDrawer } = drawerSlice.actions;
export const drawerStatus= (state: any) => state?.drawerReducer;
export default drawerSlice.reducer;