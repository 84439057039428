import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { ModalProps } from './type'

const Modal: React.FC<ModalProps> = ({ open,icon, title, onClose , form,maxWidth = 'sm',fullWidth=true}) => {
  return (

<Dialog fullWidth={fullWidth}
maxWidth ={maxWidth} open={open} onClose={onClose}>
         
         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            marginLeft:'5px',
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

      <DialogContent >
        {form}
      </DialogContent>
    
</Dialog>
  );
};

export default Modal;