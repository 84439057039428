import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import {Pagable} from '../../../types/index'
import { PaginationInterface } from "../../../types/index";
const initialState = {
    users:null
};
export interface UserResponseList {
    id: string,
    email:string,
    full_name: string,
    photo_url: string
}
export interface UsersResponse{
    size:number
    list:UserResponse[];
  
  }
  
export interface UserResponse{
    message: string,
    code: number,
    data: UserResponse
    status: boolean
 }


 interface User {
    id: string;
    email: string;
    full_name: string;
    photo_url: string | null;
    anonymous_name: string | null;
}

interface Data {
    size: number;
    list: User[];
}

interface ApiResponseOfNonSeeders {
    message: string;
    code: number;
    data: Data;
    status: boolean;
}

interface UserAddDetails {
    aliasName: string | null;
    email: string |null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    password: string |null;
    fcmToken:null;
    isBornAfter2004:boolean
}

interface ResponseOfUser {
    message: string;
    code: number;
    data: {
        id: string;
        createdAt: string;
        updatedAt: string;
        fullName: string;
        email: string;
        profilePhotoUrl: string;
        firstName: string;
        lastName: string;
        birthday: string | null;
        locationLat: number | null;
        locationLong: number | null;
        locationAddress: string | null;
        gender: string | null;
        industry: string | null;
        politics: string | null;
        networkings: string | null;
        employments: string | null;
        collegeInfos: string | null;
        promptDetails: string | null;
        isProfile: boolean;
        isProfilePic: boolean;
        isIndustry: boolean;
        isPolitics: boolean;
        isEducation: boolean;
        isEmployment: boolean;
        isNetworking: boolean;
        isPrompt: boolean;
        isSocialMedia: boolean;
        isPortfolio: boolean;
        status: boolean;
        twitterUrl: string | null;
        facebookUrl: string | null;
        instagramUrl: string | null;
        tiktokUrl: string | null;
        linkedinUrl: string | null;
        isLogin: boolean | null;
        portfolioUrl: string | null;
        notificationStatus: boolean;
        stripeCustomerId: string | null;
        eventId: string | null;
        stripeEventUpdateDate: string | null;
        stripeSession: string | null;
        createdBy: string | null;
        updatedBy: string | null;
        statusCount: number;
        token: string;
        mutualConnect: string | null;
        isVerified: boolean;
        isLinkedin: boolean;
        totalRating: number | null;
        totalRatingUser: number | null;
        fcmToken: string | null;
        softFilterValue: string | null;
        isFriend: boolean | null;
        isExist: boolean | null;
        refreshToken: string;
        phoneNumber: string;
        promptCount: number | null;
        isStar: boolean | null;
        isEndrose: boolean | null;
        isConnected: boolean | null;
        isBlocked: boolean | null;
        countryCode: string;
        ratingStatus: boolean;
        commentCount: number | null;
        currentLat: number | null;
        currentLong: number | null;
        aboutMe: string | null;
        interestId: string | null;
        mutualInterest: string | null;
        followerCount: number;
        isSeeder: boolean;
        isBornAfter2004: boolean | null;
        karmaCount: number | null;
        hasMessage: boolean | null;
        password: string;
        anonymousCount: number;
        anonymousName: string;
        aliasUrl:string
    };
    status: boolean;
}

interface EditUserDetails{
  
    aboutMe: string,
    anonymousName: string,
    firstName: string,
    id: string,
    lastName: string,
    profilePhotoUrl?: string
  
}

interface ResponseOfEditDetails{
  message: string;
  code: number;
  data: UserData;
  status: boolean;
}

interface UserData {
  id: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  email: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
  birthday: string | null;
  locationLat: number | null;
  locationLong: number | null;
  locationAddress: string | null;
  gender: string | null;
  industry: string | null;
  politics: string | null;
  networkings: any[];
  employments: any[];
  collegeInfos: any[];
  promptDetails: any[];
  isProfile: boolean;
  isProfilePic: boolean;
  isIndustry: boolean;
  isPolitics: boolean;
  isEducation: boolean;
  isEmployment: boolean;
  isNetworking: boolean;
  isPrompt: boolean;
  isSocialMedia: boolean;
  isPortfolio: boolean;
  status: boolean;
  twitterUrl: string | null;
  facebookUrl: string | null;
  instagramUrl: string | null;
  tiktokUrl: string | null;
  linkedinUrl: string | null;
  isLogin: boolean | null;
  portfolioUrl: string | null;
  notificationStatus: boolean;
  stripeCustomerId: string | null;
  eventId: string | null;
  stripeEventUpdateDate: string | null;
  stripeSession: string | null;
  createdBy: string | null;
  updatedBy: string;
  statusCount: number;
  token: string | null;
  mutualConnect: string | null;
  isVerified: boolean;
  isLinkedin: boolean;
  totalRating: number | null;
  totalRatingUser: number | null;
  fcmToken: string | null;
  softFilterValue: string | null;
  isFriend: boolean | null;
  isExist: boolean | null;
  refreshToken: string | null;
  phoneNumber: string;
  promptCount: number | null;
  isStar: boolean | null;
  isEndrose: boolean | null;
  isConnected: boolean | null;
  isBlocked: boolean | null;
  countryCode: string;
  ratingStatus: boolean;
  commentCount: number | null;
  currentLat: number | null;
  currentLong: number | null;
  aboutMe: string | null;
  interestId: string | null;
  mutualInterest: boolean;
  followerCount: number;
  isSeeder: boolean;
  isBornAfter2004: boolean;
  karmaCount: number;
  hasMessage: boolean | null;
  password: string;
  anonymousCount: number;
  anonymousName: string;
  aliasUrl:string
}

export const getUsers = async (pagination:PaginationInterface , filteredValue:string) : Promise<UserResponse> =>{
    try{
        const response = await  axios.get(`linkedin-candidate/getAll?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}&searchName=${filteredValue ? filteredValue : ""}`)
        return response.data;

    }catch(err:any){
        throw new Error(err)
    }
 
} 
export const getAllNonSeeders = async () : Promise<ApiResponseOfNonSeeders> =>{
    try{
        const response = await  axios.get(`linkedin-candidate/getAllNonSeeders`)
        return response.data;

    }catch(err:any){
        throw new Error(err)
    }
 
}
export const uploadCSV = async (payload: FormData) : Promise<any> =>{
   try{
    const response = await  axios.post(`linkedin-candidate/upload`,payload,{
      
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    
})
return response;
   }catch(err:any){
    throw new Error(err)
   }
}

export const addUsers = async (
    payload: UserAddDetails
  ): Promise<ResponseOfUser> => {
    try {
      const response = await axios.post(
        `/linkedin-candidate/create-user`,
        payload
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  
  export const deleteUsers = async (
    payload: {id:string}
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `/linkedin-candidate/delete-user`,
        payload
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  export const editUsers = async (
    payload: EditUserDetails
  ): Promise<ResponseOfEditDetails> => {
    try {
      const response = await axios.post(
        `/linkedin-candidate/edit-user`,
        payload
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

export const userSlice = createSlice({
    name: "users",
    initialState,
    reducers:{},
})


