import axios from "../../interceptor/axiosInterceptor";
import { DeleteResponse } from "../reportedComments/reportedComments";
import { PaginationInterface } from "../../../types";
interface ApiResponse {
  message: string;
  code: number;
  data: Data;
  status: boolean;
}

interface Data {
  size: number;
  reportedPostsResponseDtos: ReportedPostResponseDto[];
  totalCount: number;
}

export interface ReportedPostResponseDto {
  id: string;
  postCreatorName: string;
  postCreatorPhotoUrl: string;
  promptType: string;
  isAlias: boolean;
  ans: string | null;
  postUrl: string | null;
  title: string;
  postDetailsId: string;
  postReporterName: string;
  postReporterPhotoUrl: string;
  communityName: string;
  communityPhotoUrl: string;
  hexColor: string;
  communityType: string;
}


export const getReportedPosts = async (pagination:PaginationInterface): Promise<ApiResponse> => {
  try {
    const response = await axios.get(
      `report/admin/ReportedPosts?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`
    );
    
    return response.data;
  } catch (err:any) {
    throw new Error(err);
  }
};

export const deleteReportedPosts= async (payload: Array<string>): Promise<DeleteResponse> => {

  try {
    const response = await axios.delete(
      `report/admin/ReportedPosts`,
      {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};