import React from "react";
import { Layout } from "../../layout/Layout";
import GeneralisedAnalytics from "./GeneralisedAnalytics";

const Analytics = function(){
return(
<Layout title="Analytics">
<GeneralisedAnalytics/>
</Layout>
)
}

export default Analytics;
