import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { message } from "../../../types/common";
import { CommunityPayload } from "../../components/form/type";
import { PaginationInterface } from "../../../types/index";
import { GetCommunity } from "../../../types/common";
import { CommunityMembers , editCommunityMembers } from "../../../types/communityType";
const initialState = {};

export interface CommunityList{
  communityName: string
  communityType: string
  createdBy: string
  description: string
  id: string
  photoUrl: string
  updatedBy: string
  
}
export interface CommunityResponse{
  size:number
  list:CommunityList[];
  totalCount:number

}

export interface CommunityResponse{
  message: string,
  code: number,
  data: CommunityResponse
  status: boolean
}
interface CommunityAdd{
  message: string,
  code: number,
  data: CommunityAddResponse,
  status: boolean
}
interface CommunityAddResponse{
  communityCreatorEmail: string
  communityCreatorId: string
  communityCreatorName: string
  communityCreatorPhoto:string
  communityName: string
  communityTopics: []
  communityType: string
  description : string
  id: string
  isJoined: boolean
  memberCount: number
  pendingCount: number
  photoUrl: string
  selfCreated: boolean
}

export const getCommunityAction = async (pagination:any) : Promise<CommunityResponse> =>{
try{
  const response = await  axios.get(`linkedin-candidate/community/getAll?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
export const getApprovedUserAction = async (pagination:PaginationInterface , id:string) : Promise<GetCommunity> =>{
  try{
    const response = await  axios.get(`linkedin-candidate/community/getUsersByCommunity/${id}?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
export const getCommunityTopicsAction = async (pagination:any , filteredValue) : Promise<any> =>{
  try{
    const response = await  axios.get(`linkedin-candidate/community-topic/getAllCommunityTopic?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}&searchName=${filteredValue ? filteredValue : ""}`)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
export const getCommunityMembersAction = async (pagination:PaginationInterface) : Promise<CommunityMembers> =>{
    try{
      const response = await  axios.get(`linkedin-candidate/community/getAllCommunityMembers??pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
      return response.data;
    }catch(err:any){
      throw new Error(err)
    }
}
export const editCommunityMembersAction = async (payload:{id:string , displayMemberCount:number}) : Promise<editCommunityMembers> =>{
  const body = JSON.stringify(payload)
  try{
    const response = await  axios.post(`linkedin-candidate/community/editCommunityMember`, body)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
}
export const addCommunityAction =async (payload: CommunityPayload) : Promise<CommunityAdd> =>{
  const body = JSON.stringify(payload)
try{
  const response = await  axios.post(`linkedin-candidate/community/add`, body)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
export const editCommunityDescriptionAction = async (payload: any) : Promise<any> =>{
  const body = JSON.stringify(payload)
try{
  const response = await  axios.post(`linkedin-candidate/community/edit`, body)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
export const editCommunityIconAction = async (values: any) : Promise<any> =>{
  const payload = {
    ...values,
    description: values?.description ? values?.description.trim().replace(/ {2,}/g, '') : null
  };
  const body = JSON.stringify(payload)
try{
  const response = await  axios.post(`linkedin-candidate/community/edit`, body)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}

export const deleteCommunityAction = async (id : string) : Promise<message> =>{
 try{
  const response = await  axios.delete(`linkedin-candidate/community/delete/${id}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
 }
export const community = createSlice({
    name: "Community",
    initialState,
    reducers:{},
})

