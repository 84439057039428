import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Typography,
  Avatar,
  CardHeader,
  Divider,
  AlertColor,
} from "@mui/material";
import {addSeederCommentAction, getSeederChatData,deleteSeederComment, getSeederAction} from '../../app/seeders/seeders'
import CommentList from './CommentList'; 
import {CommentData} from './CommentList';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import { FormControlLabel, Checkbox } from '@mui/material';
import { SeederUsersList } from "../../../types/common";

// Main App component
const Index = ({commentData}) => {
  const [author, setAuthor] = useState<string>("");
  const [commentText, setCommentText] = useState<string>("");
  const [update, setUpdate] = useState<boolean>(false);
  const [data, setData] = useState<any>([])
  const [commentDetail, setCommentDetail]=useState<CommentData[]>([]);
  const [loading , setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('');
  const [rowCount,setRowCount]= useState<number>(0);
  const [filteredValue,setFilteredValue]= useState<string>("");
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [isAlias,setIsAlias]= useState(false);

  useEffect(()=>{
    getSeederAction(pagination , filteredValue)
    .then((res) =>{
      if(!res.status){
      }else{
        setData(res?.data?.seederCandidateListResponseDtos ? res?.data?.seederCandidateListResponseDtos : []);
        setRowCount(res?.data?.totalCount)
      }    
    })
    .catch((err) =>{
    })
    const payload1 ={
      id:commentData?.promptDetailsId,
      prompt: commentData?.postType
    }
    getSeederChatData(payload1).then((res)=>{
      if(res.data.list){
      setCommentDetail(res.data.list)
      }
    }).catch((error)=>{
    })
  },[commentData,update , pagination , filteredValue])
  const handleScroll = (event , setPage , rowCount? ,paginationSize?) => {
    const bottom =
      event.target.scrollHeight === event.target.clientHeight + event.target.scrollTop;
      if (bottom && paginationSize < rowCount) {
        setPage();
      }
  };
  function setPage(pageSize){
    setpagination((prev) =>{ return {...prev , pageSize:pageSize ? pageSize : prev.pageSize + 10}})
  }
  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (commentText) {
      let value:Array<SeederUsersList> = data.filter((val:any)=>val.fullName === author)
      const payload={
        candidateLinkedInId: value[0]?.id,
        comment: commentText,
        parentId: null,
        promptDetailsId: commentData?.promptDetailsId,
        promptType: commentData?.postType,
        isAlias:isAlias,
      }
      setUpdate(true)
      addSeederCommentAction(payload)
      .then((res) =>{
        setAuthor("")
        setCommentText("");
        setUpdate(false);
        setIsAlias(false);
      })
      .catch((err) =>{
      })
    }
  };

  const deleteData=(deleteId)=>{
    setUpdate(true) 
    setLoading(true)
    deleteSeederComment(deleteId).then((res)=>{
      if(!res?.status){
        setLoading(false)
        setSeverity('error')
        setOpenAlert(true)
        setMessage(res?.data?.message? res.data.message : 'Request failed due to some issue')
      }else{
        setLoading(false)
        setSeverity('success')
        setOpenAlert(true)
        setMessage(res?.data?.message? res.data.message : 'Successfully Deleted')
      }
      setUpdate(false) 
    }).catch((err)=>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : 'Error')
    })
  }

  const addReply = (commentId:string, replyText:string,username:string,aliasVal:boolean) => {
    const payload={
      candidateLinkedInId: username,
      comment: replyText,
      parentId: commentId,
      promptDetailsId: commentData?.promptDetailsId,
      promptType: commentData?.postType,
      isAlias:aliasVal
    }
    setUpdate(true)
    addSeederCommentAction(payload)
    .then((res) =>{
      setUpdate(false)  
      if(!res?.status){
        setLoading(false)
        setSeverity('error')
        setOpenAlert(true)
        setMessage(res?.message? res.message : 'Request failed due to some issue')
      }else{
        setLoading(false)
        setSeverity('success')
        setOpenAlert(true)
        setMessage(res?.data?.message? res.data.message : 'Reply Added Successfully')
      }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : 'Error')
    })
  };

  const calculateHoursAgo = (creationDateString) => {
    const now = new Date();
    const creationDate = new Date(creationDateString);

    const differenceInMilliseconds = now.getTime() - creationDate.getTime();
    const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    
    return differenceInHours;
  };

  function timeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
        return `${Math.floor(secondsPast)} seconds ago`;
    }
    if (secondsPast < 3600) {
        return `${Math.floor(secondsPast / 60)} minutes ago`;
    }
    if (secondsPast < 86400) {
        return `${Math.floor(secondsPast / 3600)} hours ago`;
    }
    if (secondsPast < 2592000) {
        return `${Math.floor(secondsPast / 86400)} days ago`;
    }
    if (secondsPast < 31104000) {
        return `${Math.floor(secondsPast / 2592000)} months ago`;
    }
    return `${Math.floor(secondsPast / 31104000)} years ago`;
}

  const hoursAgo = timeAgo(commentData.creationDate);
  return (
    <>
    <Grid container spacing={1} padding={2}>
      <Grid  item xs={12} sm={12}>
      <Box sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
      <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <img 
              src={commentData?.communityPhoto} 
              alt="photo" 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            />
          </Avatar>
        }
        title={`${commentData.communityName} - ${hoursAgo}`}
        subheader={commentData.fullName}
      />
      <Divider />
      <CardContent>
        <Typography variant="body1" color="text.secondary">
          {commentData?.title || 'no data'} 
        </Typography>
      </CardContent>
      <CardContent>
        <Typography variant="h6" color="text.primary">
          {commentData?.ans || 'no data'} 
        </Typography>
      </CardContent>
    </Card>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
      <Card>
        <CardContent>
      <Typography variant="h4" gutterBottom>
        Comments as Seeder
      </Typography>
   
      <form onSubmit={handleCommentSubmit}>
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
        Choose Seeder
      </Typography>
        <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={data.map((val:any) => `${val?.fullName}   (${val?.anonymousName})`  || "")}
              fullWidth
              ListboxProps={
                {
                  onScroll:(e) => handleScroll(e , setPage , rowCount , pagination?.pageSize)
                }
              }
              onChange={(event, newValue) => {
                if(newValue !== null){
                const arr = newValue?.split(" ")
                  let result = ""
                  for(let i=0 ; i< 2 ; i++){
                    if(i !== 0){
                      result+=" "
                    }
           
                    result+=arr[i]
                  }
                  setAuthor(result ? result : "")
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setFilteredValue(e?.target?.value)}
                  name="status"
                  label="Seeders"
                />
              )}
              value={author}
            />
            </Grid>
            <Grid item xs={12} sm={12}>
        <TextField
          label="Add a comment"
          variant="outlined"
          fullWidth
          value={commentText}
          minRows={5} 
          onChange={(e) => setCommentText(e.target.value)}
          sx={{ mt: 2 }}
        />

<FormControlLabel
          control={<Checkbox
            id="isAlias"
            checked={isAlias}
            onChange={()=>{
              setIsAlias((prv)=>!prv)
            }}
            color="primary"
          />}
          label="Use Alias"
        />

        </Grid>
        </Grid>
        <Button type="submit" variant="contained">
          Post Comment
        </Button>
      </form>
      </CardContent>
      </Card>
      <CommentList comments={commentDetail} addReply={addReply} userList={data} deleteData={deleteData}/>
    </Box>
    </Grid>
    </Grid>
      <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
  );
};
export default Index;




