import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";
import { checkChat, getSeederMessages } from "../../app/seeders/seeders";
import {
  doc,
  collection,
  onSnapshot,
  Unsubscribe,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Message, User } from "./SeederChat";

interface UserList {
  id: string;
  creation_DATE: string;
  is_SENDER_ALIAS: boolean;
  is_RECEIVER_ALIAS: boolean;
  sender_NAME: string;
  receiver_NAME: string;
  sender_PHOTO: string;
  to_CANDIDATE_ID: string;
  last_MESSAGE: string;
  from_CANDIDATE_ID: string;
  receiver_PHOTO: string;
  is_SENDER_SEEDER: boolean;
  is_RECEIVER_SEEDER: boolean;
}

const UserList = ({
  setChats,
  setLoading,
  setSeverity,
  setMessage,
  setOpenAlert,
  setSelectedUser,
  selectedUser,
  setChatStatus,
}) => {
  const [seeder, setSeeder] = useState<UserList[]>([]);
  const [isNotification, setNotification] = useState<boolean>(false);
  const [notifiedUser, setNotifiedUser] = useState<string>("");
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const isApiCallInProgressRef = React.useRef<boolean>(false);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })

  const gridRef = React.useRef<HTMLDivElement>(null);

  function hashGen(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) + hash + str.charCodeAt(i);
    }
    return hash >>> 0;
  }

  function listenToFriendUpdates(networkDocId: string) {
    const friendsCollectionRef = collection(db, `network_users/${networkDocId}/friend_list`);
  
    onSnapshot(friendsCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === 'modified' && !isApiCallInProgressRef.current) {
          setNotifiedUser(change?.doc?.id);
          isApiCallInProgressRef.current = true;
          const updatedPagination = await updatePagination(1);
          await getDataHandler(updatedPagination);
          setNotification(true);
          isApiCallInProgressRef.current = false;
        }
      });
    }, (error) => {
      console.error(`Error listening to friend subcollection for network ${networkDocId}:`, error);
    });
  }
const updatePagination = (size) => {
  return new Promise((resolve) => {
    setpagination((prevPagination) => {
      const updatedPagination = { ...prevPagination, pageSize: prevPagination.pageSize + size };
      resolve(updatedPagination);
      return updatedPagination;
    });
  });
};

const handleScroll = async () => {
  if (gridRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
    // Check if we're near the bottom
    if (scrollTop + clientHeight >= scrollHeight - 10 && pagination.pageSize < totalCount) {
      const updatedPagination = await updatePagination(10);
      getDataHandler(updatedPagination);
    }
  }
};

useEffect(() => {
  if (gridRef.current) {
    gridRef.current.addEventListener("scroll", handleScroll);
  }
  return () => {
    if (gridRef.current) {
      gridRef.current.removeEventListener("scroll", handleScroll);
    }
  };
}, [pagination.pageSize, totalCount]);
useEffect(() =>{
  seeder.forEach((item : UserList) =>{
    listenToFriendUpdates(item ? item?.from_CANDIDATE_ID : "" );
  })

},[seeder])
  useEffect(() => {
    if (selectedUser?.id) {
      const conditionFirst = `${
        hashGen(`${selectedUser?.from_CANDIDATE_ID}#AnonChat`) <=
        hashGen(`${selectedUser?.to_CANDIDATE_ID}#AnonChat`)
          ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat_${selectedUser?.to_CANDIDATE_ID}#AnonChat`
          : `${selectedUser?.to_CANDIDATE_ID}#AnonChat_${selectedUser?.from_CANDIDATE_ID}#AnonChat`
      }`;
      const conditionSecond = `${
        hashGen(`${selectedUser?.from_CANDIDATE_ID}`) <=
        hashGen(`${selectedUser?.to_CANDIDATE_ID}`)
          ? `${selectedUser?.from_CANDIDATE_ID}_${selectedUser?.to_CANDIDATE_ID}`
          : `${selectedUser?.to_CANDIDATE_ID}_${selectedUser?.from_CANDIDATE_ID}`
      }`;
      const chatId = selectedUser?.is_SENDER_ALIAS
        ? conditionFirst
        : conditionSecond;
      // const chatId =  selectedFriend?.is_anon_member ?`${selectedFriend?.member_id >= selectedSeeder?.id ?   `${selectedFriend?.member_id}_${selectedSeeder?.id}#AnonChat` : `${selectedSeeder?.id}#AnonChat_${selectedFriend?.member_id}` }`  : `${selectedFriend?.member_id >= selectedSeeder?.id ? `${selectedFriend?.member_id}`}`;
      const chatRef = doc(db, "chats", chatId);
      // Reference to the messages collection within the chat document
      const messagesRef = collection(chatRef, "messages");
      const q = query(messagesRef, orderBy("sent"));
      // Subscribe to real-time updates
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messageList: Message[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as Omit<Message, "id">;
          const message: Message = { id: doc.id, ...data };
          messageList.push(message);
        });

        setChats(messageList ? messageList : []);
      });
      return () => {
        unsubscribe(); // Unsubscribe from the real-time updates when component unmounts
      };
    } else {
      return;
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!selectedUser?.id) return;

    setLoading(true);

    let unsubscribeUserDoc: Unsubscribe | null = null;
    let unsubscribeSubCollection: Unsubscribe | null = null;

    const snap = () => {
      try {
        const userDocRef = doc(
          db,
          "network_users",
          selectedUser?.from_CANDIDATE_ID
        );
        unsubscribeUserDoc = onSnapshot(userDocRef, (userDocSnapshot) => {
          if (userDocSnapshot.exists()) {
            const subCollectionRef = collection(userDocRef, "friend_list");
            unsubscribeSubCollection = onSnapshot(
              subCollectionRef,
              (subCollectionSnapshot) => {
                const friends = subCollectionSnapshot.docs.map((subDoc) =>
                  subDoc.data()
                );

                setLoading(false);
              }
            );
          } else {
            setLoading(false);
          }
        });
      } catch (err) {
        setLoading(false);
      }
    };

    snap();

    return () => {
      if (unsubscribeUserDoc) unsubscribeUserDoc();
      if (unsubscribeSubCollection) unsubscribeSubCollection();
    };
  }, [selectedUser]);

  function relativeTime(timestamp: string): string {
    const now: Date = new Date();
    const pastDate: Date = new Date(timestamp);
    const diffInSeconds: number = Math.floor(
      (now.getTime() - pastDate.getTime()) / 1000
    );

    const secondsInMinute: number = 60;
    const secondsInHour: number = 3600;
    const secondsInDay: number = 86400;
    const secondsInMonth: number = 2592000;
    const secondsInYear: number = 31536000;

    if (diffInSeconds < secondsInMinute) {
      return "now";
    } else if (diffInSeconds < secondsInHour) {
      const minutes: number = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes}Min ago`;
    } else if (diffInSeconds < secondsInDay) {
      const hours: number = Math.floor(diffInSeconds / secondsInHour);
      return `${hours}Hr ago`;
    } else if (diffInSeconds < secondsInMonth) {
      const days: number = Math.floor(diffInSeconds / secondsInDay);
      return `${days}D ago`;
    } else if (diffInSeconds < secondsInYear) {
      const months: number = Math.floor(diffInSeconds / secondsInMonth);
      return `${months}M ago`;
    } else {
      const years: number = Math.floor(diffInSeconds / secondsInYear);
      return `${years}Y ago`;
    }
  }

  async function checkChatStatus(
    senderId: string,
    receiverId: string,
    isUserAnon: boolean
  ) {
    setLoading(true);
    checkChat(senderId, receiverId, isUserAnon)
      .then((res) => {
        setLoading(false);
        if (!res.status) {
          setSeverity("error");
          setOpenAlert(true);
          setMessage(res?.message || "Request failed due to duplicity");
        } else {
          setChatStatus(res?.data && res?.data);
        }
      })
      .catch((err) => {
        setSeverity("error");
        setOpenAlert(true);
        setMessage(err ? err.message : "Error");
        setLoading(false);
      });
  }

  function handleClick(seeder: User) {
    const conditionFirst = seeder?.is_SENDER_SEEDER
      ? seeder?.from_CANDIDATE_ID
      : seeder?.to_CANDIDATE_ID;
    const conditionSecond = seeder?.is_RECEIVER_SEEDER
      ? seeder?.from_CANDIDATE_ID
      : seeder?.to_CANDIDATE_ID;

    checkChatStatus(conditionFirst, conditionSecond, seeder?.is_RECEIVER_ALIAS);
  }

  const getDataHandler = async (pagination) => {
    setLoading(true);
    try {
      const res = await getSeederMessages({
        pageNo: pagination?.pageNo,
        pageSize: pagination?.pageSize,
      });
      setSeeder(res?.data?.list);
      setTotalCount(res?.data?.totalCount);
    } catch (err: any) {
      setSeverity("error");
      setMessage(err?.message || "Something went wrong!");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataHandler(pagination);
  }, []);

  return (
    <Grid
      container
      ref={gridRef}
      sx={{
        minHeight: "75vh",
        maxHeight: "75vh",
        overflow: "auto",
        flexWrap:'nowrap',
        flexDirection:'column',
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "primary.main",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: " #c9c7c7",
        },
      }}
    >
      {seeder?.map((item, index) => (
        <Grid
          item
          xs={12}
          sx={{
            cursor: "pointer",
            border: "1px solid",
            borderColor: "#c9c7c7",
            minHeight:"100px",
            p: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: index % 2 === 0 ? "white" : "#e6e8e6",
          }}
          onClick={() => {
            setNotification(false)
            setSelectedUser(item);
            handleClick(item);
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt={`img`}
                src={
                  !item?.is_SENDER_SEEDER
                    ? item?.sender_PHOTO
                    : item?.receiver_PHOTO
                }
                sx={{ width: 40, height: 40 }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  pl: "7px",
                }}
              >
                {!item?.is_SENDER_SEEDER
                  ? item?.sender_NAME?.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter: string) => letter?.toUpperCase()
                    )
                  : item?.receiver_NAME?.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter: string) => letter?.toUpperCase()
                    )}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "10px", mt: "5px" }}>
              {`${item?.last_MESSAGE?.substring(0, 50)}${
                item?.last_MESSAGE?.length > 50 ? "..." : ""
              }` || "  "}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography sx={{ fontSize: "8px" }}>
              {relativeTime(item.creation_DATE)}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: "5px" }}>
              <Avatar
                alt={`${item?.sender_NAME}-img`}
                src={
                  !item?.is_RECEIVER_SEEDER
                    ? item?.sender_PHOTO
                    : item?.receiver_PHOTO
                }
                sx={{ width: 20, height: 20 }}
              />

              <Typography
                sx={{ fontSize: "10px", ml: "5px", fontWeight: "500" }}
              >
                {!item?.is_RECEIVER_SEEDER
                  ? item?.sender_NAME?.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter: string) => letter?.toUpperCase()
                    )
                  : item?.receiver_NAME?.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter: string) => letter?.toUpperCase()
                    )}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default UserList;
