import * as React from 'react';
import { styled } from '@mui/material/styles';
import { DataGrid, GridCellParams , GridToolbar , GridRowParams ,GridRowSelectionModel , GridRowId} from '@mui/x-data-grid';
import { DataGridTableProps } from './types';
import { Paper, Box } from '@mui/material';
import { useAppSelector , useAppDispatch } from '../../app/hooks';
import { selectSeederState, setCommunitie } from '../../app/seeders/seeders';


function DataGridTable<T>({handleEdit , savePost ,  singleRowSelection, handleFilter,setCommunityFieldCallBack , columns ,row, loading = false , checkboxSelection,onCheck,setSelected , pagenated , rowCount }:DataGridTableProps<T>) {
  const [rowSelectionModel, setRowSelectionModel] =React.useState<GridRowSelectionModel>([]);
  const [selectedFields, setSelectedFields] =React.useState<any>([]);
  const [disableMultipleRowSelection, setDisableMultipleRowSelection] =
  React.useState(false);
  const [pagination , setPagination] =React.useState<any>();
  const [check , setCheck] = React.useState<boolean>(checkboxSelection ? checkboxSelection : false);
  const dispatch = useAppDispatch();
  const comm:any = useAppSelector(selectSeederState)?.communitites
  const actionType:string = useAppSelector(selectSeederState)?.actionType
  function handleClick(params:GridCellParams,e:React.MouseEvent<HTMLElement>){
    e.stopPropagation();
  }
  const handleSelectionModelChange = (newSelection:any  ) => {
    if(singleRowSelection){
      if (newSelection?.length > 0) {
        // Set the selection model to the last selected item
        const selectedRow = [newSelection[newSelection.length - 1]];
        setRowSelectionModel(selectedRow ? selectedRow : [] );
        savePost && savePost(selectedRow);
      }
    }else{
      const newArray = newSelection?.map((item:any) => ({
        id: item,
      }));
      setCommunityFieldCallBack && setCommunityFieldCallBack(newSelection)
      dispatch(setCommunitie(newArray ? newArray : []))
        setRowSelectionModel(newSelection);
        setSelectedFields(newSelection)
    }
    
  };
  React.useEffect(() =>{
   dispatch(setCommunitie([]))
      },[])

      React.useEffect(() =>{
        if(setSelected){
          setSelected(selectedFields);
        }
           },[selectedFields,setSelected])

  React.useEffect(() =>{
if(actionType === 'Edit'){
  const arrayOfIds = comm?.map((item:any) => item.id);
  setSelectedFields(arrayOfIds ? arrayOfIds : [])
}
  },[])
  function handlePageination(page:any){
    setPagination(page)
    if(typeof pagenated === 'function'){
      pagenated(page.page + 1 , page.pageSize)
    }

  }

  return (

    <Paper elevation={0} sx={{ height: '70vh', width: '100%' }}>
      <DataGrid
        rows={row ? row : []}
        rowSelectionModel={actionType === "Edit" ?  selectedFields   : rowSelectionModel }
        onRowSelectionModelChange={handleSelectionModelChange}
        columns={columns ? columns : []}
        rowCount={rowCount ? rowCount : 0}
        paginationMode="server"
        onCellEditStop={(params , e:any) => handleEdit && handleEdit(params , e)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },        
        }}
        loading={loading}
        pageSizeOptions={[5, 10 , 20 , 50 , 100]} 
        onCellClick={handleClick}
        sx={{
          fontSize: '0.80rem'
        }}
        onPaginationModelChange={handlePageination}
        checkboxSelection={checkboxSelection ? checkboxSelection : false}
        // onRowSelectionModelChange={onCheck}
        slots={{
          toolbar: GridToolbar,
          // filterPanel: CustomToolbar
        }}
        onFilterModelChange={(e) => handleFilter && handleFilter(e?.items[0]?.value)}
        slotProps={{
          filterPanel: {
            columnsSort: 'asc',
            filterFormProps: {
              // Customize inputs by passing props
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small',
                  clearIcon: false,
                },
               
              },
              deleteIconProps: {
                sx: {
                  '& .MuiSvgIcon-root': { color: '#d32f2f' },
                },
              },
            },
            sx: {
              // Customize inputs using css selectors
              '& .MuiDataGrid-filterForm': { p: 2 },
              '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
              '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormValueInput': { width: 200 },
              '& .MuiDataGrid-filterFormValueInput > svg': { width: 0 },
            },
          },
        }}
      />
    </Paper>
  );
};

export default DataGridTable;