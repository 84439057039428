import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Typography,
  Avatar,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import BasicDateTimePicker from '../DateTimepicker/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CircleNotificationsOutlined } from '@mui/icons-material';
import { getSeederAction } from '../../../app/seeders/seeders';

interface CommentProps {
  id:any,
  comment: any;
  addReply: (
    parentId: string,
    replyText: string,
    userId: string,
    isAlias: boolean,
    childCommentDate?: string,
    id?:any,
    photoUrl?:any,
    checkDate?:any
  ) => void;
  userList: any;
  deleteData: any
  updateComments: (commentId: string, newReply: any) => void;
  parentDate?: any;
  editComment?:any
  childDate?:any,
  handleScroll?: (e?:any , setPage?:any , rowCount?:any , size?:any) => void,
  rowCount?:number
}

const Comment = ({
  id,
  comment,
  addReply,
  userList,
  deleteData,
  updateComments,
  parentDate,
  editComment,
  childDate,
}: CommentProps) => {
  const [replyText, setReplyText] = useState<string>('');
  const [showReplyForm, setShowReplyForm] = useState<boolean>(false);
  const [hideComment, setHideComment] = useState<boolean>(false);
  const [author, setAuthor] = useState<string>('');
  const [aliasVal, setAliasVal] = useState(comment?.isAlias || false);
  const [seederDetails, setSeederDetails] = useState<any>(null);
  const [parentCommentDate, setParentCommentDate] = useState<any>(parentDate);
  const [childCommentDate, setChildCommentDate] = useState<any>(childDate);
  const [childCommentDateOnChange, setChildCommentDateOnChnage] = useState<any>();
  const [commentID, setCommentID] = useState<null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editDate, setEditDate] = useState<any>(parentDate);
  const [editText, setEditText] = useState<string>(comment.commentText);
  const [mode , setMode] = useState<string>("")
  const [data, setData] = useState<any>(userList)
  const [rowCount,setRowCount]= useState<number>(0);
  const [filteredValue,setFilteredValue]= useState<string>("");
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })


  useEffect(()=>{
    getSeederAction(pagination , filteredValue)
    .then((res) =>{
      if(!res.status){
      }else{
        setData(res?.data?.seederCandidateListResponseDtos ? res?.data?.seederCandidateListResponseDtos : []);
        setRowCount(res?.data?.totalCount)
      }    
    })
    .catch((err) =>{
    })
 
  },[pagination , filteredValue ])
  const handleScroll = (event , setPage , rowCount? ,paginationSize?) => {
    const bottom =
      event.target.scrollHeight === event.target.clientHeight + event.target.scrollTop;
      if (bottom && paginationSize < rowCount) {
        setPage();
      }
  };
  const handleReplySubmit = (e) => {
    e.preventDefault();
    if (replyText) {
      const username = aliasVal ? seederDetails?.anonymousName:  seederDetails?.fullName;
      addReply(comment?.id, replyText, username || '', aliasVal, seederDetails?.id,childCommentDateOnChange ? dayjs(childCommentDateOnChange) : dayjs(parentCommentDate).add(1,'minute'),aliasVal ? seederDetails?.aliasUrl : seederDetails?.photoUrl , parentDate);
      setReplyText('');
      setAuthor('');
      setShowReplyForm(false);
      setAliasVal(false);
      setHideComment(true)
      // setChildCommentDate(null)
      setChildCommentDateOnChnage(null)
      // setReplyDate(null);
    }
  };

  const handleDelete = (id) => {
    deleteData(id);
  };

  const handleEdit = (id:string) => {
    setMode('Edit')
    setIsEditing(true);
  };
  function setPage(pageSize){
    setpagination((prev) =>{ return {...prev , pageSize:pageSize ? pageSize : prev.pageSize + 10}})
  }
  const handleSaveEdit = () => {

    editComment(id , editText , editDate , aliasVal)
    setIsEditing(false);
  };
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Grid container>
          <Box display="flex" alignItems="center" width={'100%'}>
            <Grid item display="flex" justifyContent={'left'} sm={1} xs={12}>
              <Avatar>
                <img
                  src={comment?.isAlias ? comment?.aliasUrl || comment?.photoUrl : comment?.photoUrl  }
                  alt="photo"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Avatar>
            </Grid>
            <Grid item sm={9} xs={12}>
              <Typography variant="h6" sx={{ pl: '12px' }}>
                {comment?.isAlias ? comment?.anonName || comment?.author : comment?.author}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12}>
              {!comment.isDeleted && (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => handleDelete(comment.id)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    <DeleteIcon />
                  </Button>
                  <Button onClick={() => handleEdit(comment.id)} sx={{ mt: 1 }}>
                    <EditIcon />
                  </Button>
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>

        {isEditing ? (
          <Box mt={1}>
             <Grid container spacing={1} padding={2}>
             <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={userList}
                  fullWidth
                  getOptionLabel={(option: any) =>
                    `${option?.fullName} (${option?.anonymousName || ''})`
                  }
                  onChange={(event, newValue) => {
                    if (newValue && typeof newValue === 'object' && 'fullName' in newValue) {
                      setAuthor(newValue.fullName);
                      setSeederDetails(newValue || null);
                    } else {
                      setAuthor('');
                    }
                  }}
                  defaultValue={comment?.author || ""}
                  renderInput={(params) => <TextField {...params} label="Seeders" />}
                  value={userList.find((item) => item?.fullName === comment?.author || item?.anonymousName === comment?.author) || null}
                />
              </Grid>
         <Grid item xs={12}>
         <TextField
              variant="outlined"
              fullWidth
              defaultValue={comment?.reply || comment?.comment}
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
            />
         </Grid>
               <Grid item xs={12}>
                <BasicDateTimePicker
                  mode={mode}
                  checkingValue={comment?.checkingDate}
                  previousDateAndTime={editDate && dayjs(editDate).subtract(1 , 'minute') }
                  onDateTimeChange={(newValue) => {setEditDate(newValue)}}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isAlias"
                      checked={aliasVal}
                      onChange={() => setAliasVal((prev) => !prev)}
                      color="primary"
                    />
                  }
                  label="Use Alias"
                />
              </Grid>
            <Button onClick={handleSaveEdit} variant="contained" sx={{ mt: 1 }}>
              Save
            </Button>
            </Grid>
          </Box>
        ) : (
          <>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {comment.isDeleted ? '[deleted]' : comment?.comment || comment?.reply}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {`Scheduled For => ${!childCommentDate ? dayjs(editDate ? editDate : parentCommentDate).format('YYYY/MM/DD h:mm A') : dayjs(childCommentDate).format('YYYY/MM/DD h:mm A')}`}
            </Typography>
            <Button onClick={() => {setShowReplyForm(!showReplyForm) , setCommentID(comment?.id)}} sx={{ mt: 1 }}>
              {showReplyForm ? 'Cancel' : <>
                <ReplyIcon /> Reply
              </>}
            </Button>
            <Button onClick={() => setHideComment(!hideComment)} sx={{ mt: 1 }}>
              <ChatIcon /> {hideComment ? 'Hide comments' : 'Show comments'}
            </Button>
          </>
        )}

        {showReplyForm && (
          <form onSubmit={handleReplySubmit} style={{ marginTop: '10px' }}>
            <Grid container spacing={1} padding={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                 
                  options={data}
                  fullWidth
                  getOptionLabel={(option: any) =>
                    `${option?.fullName} (${option?.anonymousName || ''})`
                  }
                  ListboxProps={
                    {
                      onScroll:(e) => handleScroll &&  handleScroll(e , setPage , rowCount , pagination?.pageSize)
                    }
                  }
                  
                  onChange={(event, newValue) => {
                    if (newValue && typeof newValue === 'object' && 'fullName' in newValue) {
                      setAuthor(newValue.fullName);
                      setSeederDetails(newValue || null);
                    } else {
                      setAuthor('');
                    }
                  }}
                  renderInput={(params) => <TextField {...params}  required   onChange={(e) => setFilteredValue(e?.target?.value)} label="Seeders" />}
                  value={data.find((item) => item?.fullName === author) || null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Reply"
                  variant="outlined"
                  fullWidth
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  sx={{ mt: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <BasicDateTimePicker
                  previousDateAndTime={editDate ? editDate : parentDate}
                  onDateTimeChange={(newValue) => {setChildCommentDateOnChnage(newValue)}}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isAlias"
                      checked={aliasVal}
                      onChange={() => setAliasVal((prev) => !prev)}
                      color="primary"
                    />
                  }
                  label="Use Alias"
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" sx={{ mt: 1 }}>
              Submit
            </Button>
          </form>
        )}

        {hideComment && (
          <>
         
            {comment.replies.map((reply) => (
              <Box key={reply.id} sx={{ ml: 4, mt: 2 }}>
                <Comment
                  id={reply.id}
                  comment={reply}
                  addReply={addReply}
                  userList={userList}
                  deleteData={deleteData}
                  updateComments={updateComments}
                  childDate={childCommentDateOnChange ? dayjs(reply?.date) : dayjs(reply?.date)}
                  editComment={editComment}
                  parentDate={dayjs(reply?.date)}
                 
                />
              </Box>
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default Comment;
