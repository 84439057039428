import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
// import EducationGeneralisedComponent from './EducationGeneralisedComponent'
import PostManagerGeneralisedComponent from './PostManagerGeneric'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';

export default function PostManager() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [politicsId, setPolticsId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [isComments, setIsComments] = React.useState(false);
    const [editEducationDetails , setEditEducationDetails] = React.useState<GridRowParams | null>(null)

const closeModal = () =>{
        setAddModal(false)
        setEditModal(false)
        setDeleteModal(false)
        setIsComments(false)
}
const editPoliticsModal = (params :GridRowParams) =>{
    setEditModal(true)
    setEditEducationDetails(params ? params.row : [])

}
const commentModal = (params :GridRowParams) =>{
  setIsComments(true)
  setEditEducationDetails(params ? params.row : [])

}
function deletePoliticsModal(id: string) {
  setPolticsId(id)
  setDeleteModal(true)
}
const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'ans', headerName: 'Description', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'fullName', headerName: 'Posted By', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'communityName', headerName: 'Community', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')},
  { field: 'creationDate', headerName: 'Posted On', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
        icon={<MarkChatUnreadIcon />}
        label="Comments"
        showInMenu
        onClick={() => {commentModal(params)}}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deletePoliticsModal(params?.row?.id)}}
          />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          showInMenu
          onClick={() => { editPoliticsModal(params)} }
        />,
      ];
    },
  }

]
  return (
  <>
  <Layout  title="Post Manager">
    <div>
    {/* <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => setAddModal(true)} variant="contained">Add Politics</Button></div> */}
  
        <PostManagerGeneralisedComponent isComments={isComments} deletePolticsModal={deleteModal} politicsId={politicsId} editDetails={editEducationDetails}  editEducationModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  />
    </div>
  </Layout>
  </>
  )
}
