import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import {Pagable} from '../../../types/index'
import {NotificationScheduledPayload} from '../../../types/tipsTypes'
import { DeleteResponse } from "../reportedComments/reportedComments";
import { PaginationInterface } from "../../../types/index";

const initialState = {
};
interface NotificationScheduled{
    jobId: string
    jobGroup: string
    message: string
    status: boolean
}
export interface NotificationSchedulerResponse{
    title: string,
    body: string,
    dateTime: string
}

export const getJobScheduled= async (pagination:PaginationInterface) : Promise<Pagable<NotificationSchedulerResponse>> =>{
   try{
    const response = await  axios.get(`linkedin-candidate/jobScheduler/getAll?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize} `)
    return response.data.data;
   }catch(err:any){
    throw new Error(err)
   }
}
export const addNotificationScheduler = async (payload: NotificationScheduledPayload) : Promise<NotificationScheduled> =>{
    let body = JSON.stringify(payload)
   try{
    const response = await  axios.post(`linkedin-candidate/jobScheduler/scheduleNotification `, body)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
  }

  export const deleteScheduleNotification = async (payload: string): Promise<DeleteResponse> => {

    try {
      const response = await axios.delete(
        `linkedin-candidate/jobScheduler/delete/${payload}`,
      
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };
  
export const notificationSlice = createSlice({
    name: "NotificationScheduler",
    initialState,
    reducers:{},
})

