import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { LoginPayload } from "../../../types/login";
import { useAppDispatch } from "../hooks";
import { RootState } from "../store";

const initialState = {
   current:""
};


export const communityEditStateSlice = createSlice({
    name: "communityEditState",
    initialState,
    reducers: {
        setState: (state, action) => {
          return {
            ...state,
            current: action.payload,
          };
        },
       
    },
   
})

export const { setState } = communityEditStateSlice.actions;
export const selectCommunityEditState = (state: RootState) => state.communityEdit;
export default communityEditStateSlice.reducer;