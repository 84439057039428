import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form, Field } from "formik";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { db } from "../../firebase";
import { doc, setDoc, collection, getDoc } from "firebase/firestore";
import {
  sendSingleNotification,
  updateHasMessage,
} from "../../app/seeders/seeders";

const MessangeSentForm = ({
  selectedUser,
  setSeverity,
  setMessage,
  setOpenAlert,
}) => {
  const [formLoad, setFormLoad] = useState(false);

  const MessageSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
  });

  function hashGen(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) + hash + str.charCodeAt(i);
    }
    return hash >>> 0;
  }

  async function sendNotification(values) {
    const payload = {
      body: "",
      chatMessage: values?.Message,
      groupId: null,
      receiverAlias: selectedUser?.is_RECEIVER_ALIAS ? true : false,
      receiverId: selectedUser?.to_CANDIDATE_ID
        ? selectedUser?.to_CANDIDATE_ID
        : "",
      senderAlias: selectedUser?.is_SENDER_ALIAS ? true : false,
      senderId: selectedUser?.from_CANDIDATE_ID
        ? selectedUser?.from_CANDIDATE_ID
        : "",
      title: "",
    };

    try {
      await sendSingleNotification(payload);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function updateMessage() {
    const payload = {
      id: selectedUser?.to_CANDIDATE_ID,
      hasMessage: true,
    };
    try {
      await updateHasMessage(payload);
    } catch (err) {
      console.log("err", err);
    }
  }

  return formLoad ? (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress size={20} />
    </Box>
  ) : (
    <Formik
      initialValues={{ message: "" }}
      validationSchema={MessageSchema}
      onSubmit={async (
        values: {
          message: string;
        },
        { setSubmitting, resetForm }
      ) => {
        setFormLoad(true);
        try {
          const now = new Date();
          const milliseconds = now.getTime();
          const conditionFirst = `${
            hashGen(`${selectedUser?.from_CANDIDATE_ID}#AnonChat`) <=
            hashGen(`${selectedUser?.to_CANDIDATE_ID}#AnonChat`)
              ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat_${selectedUser?.to_CANDIDATE_ID}#AnonChat`
              : `${selectedUser?.to_CANDIDATE_ID}#AnonChat_${selectedUser?.from_CANDIDATE_ID}#AnonChat`
          }`;
          const conditionSecond = `${
            hashGen(`${selectedUser?.from_CANDIDATE_ID}`) <=
            hashGen(`${selectedUser?.to_CANDIDATE_ID}`)
              ? `${selectedUser?.from_CANDIDATE_ID}_${selectedUser?.to_CANDIDATE_ID}`
              : `${selectedUser?.to_CANDIDATE_ID}_${selectedUser?.from_CANDIDATE_ID}`
          }`;
          const chatDocRef = doc(
            db,
            "chats",
            selectedUser?.is_SENDER_ALIAS ? conditionFirst : conditionSecond
          );
          const subcollectionRef = collection(chatDocRef, "messages");
          const messageDocRef = doc(
            subcollectionRef,
            `${milliseconds ? milliseconds : ""}`
          );
          const messageDocRefSnapShot = await getDoc(messageDocRef);

          const data = {
            toId: selectedUser?.is_SENDER_SEEDER
              ? selectedUser?.from_CANDIDATE_ID
              : selectedUser?.to_CANDIDATE_ID,
            msg: values?.message,
            read: "",
            type: "text",
            fromId: selectedUser?.is_SENDER_SEEDER
              ? selectedUser?.is_SENDER_ALIAS
                ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat`
                : selectedUser?.from_CANDIDATE_ID
              : selectedUser?.to_CANDIDATE_ID,
            sent: milliseconds ? `${milliseconds}` : "",
          };
          let messageDocUpdated = false;
          if (messageDocRefSnapShot.exists()) {
            await setDoc(messageDocRef, data, { merge: true });
            messageDocUpdated = true;
          } else {
            await setDoc(messageDocRef, data);
            messageDocUpdated = true;
          }

          setSeverity("success");
          setOpenAlert(true);
          setMessage("Message sent successfully!");
        } catch (error: any) {
          setSeverity("error");
          setOpenAlert(true);
          setMessage(error?.message || "Unable to send message!");
        } finally {
          setFormLoad(false);
        }
        sendNotification({ Message: values?.message });
        updateMessage();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <Field
            as={TextField}
            name="message"
            label="Enter your message"
            multiline
            rows={2.5}
            variant="outlined"
            error={touched.message && Boolean(errors.message)}
            helperText={touched.message && errors.message}
            style={{ marginLeft: "12px", width: "96%" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              m: "10px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Send
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MessangeSentForm;
