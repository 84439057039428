import React , {useEffect , useState}from 'react'
import Modal from '../../../Modal/Modal'
import GenericForm from '../../../form/GenericForm'
import DataGridTable from '../../../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import { deletePostAction , getPostAction} from '../../../../app/post/post'
import { Delete as DeleteIcon} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import Loader from '../../../Loader/Loader'
import Toaster from '../../../Toaster/Toaster'
import {Type} from '../../../../enum/index'
import { BasePayload } from '../../../../../types/common'
import { postSchema } from '../../../../schema/post'
import { moderatorObjectListDtosResponse } from '../../../../../types/common'
import { PromptTypes } from '../../../../enum/index'
import { getFilteredUsers , schedulePost } from '../../../../app/post/post'
import { moderatorSchema } from '../../../../schema/moderators'
import { PermissionsTypes } from '../../../../enum/index'
import { useAppSelector } from '../../../../app/hooks'
import { selectCommunityState } from '../../../../app/communityEditDetails/communityEdit'
import { candidateLinkedInPromptDetails } from '../../../../app/post/post'
import { getAllModeratorsAction, deleteModeratorAction } from '../../../../app/community/moderators/moderators'
import { assignPermission } from '../../../../app/community/moderators/moderators'
interface EducationProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editPromptModal:boolean
    editDetails:GridRowParams | null
    promptId:string
    deletePromptModal:boolean
}
const ModeratorGeneralisedComponent: React.FC<EducationProps> =  ({deletePromptModal,promptId, addModal , closeModal , columns , editPromptModal , editDetails }) => {
  const [prompt, setPrompt] =  useState<Array<moderatorObjectListDtosResponse>>([]);
  const [users, setUsers] =  useState<Array<candidateLinkedInPromptDetails>>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('');
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const communityState:any = useAppSelector(selectCommunityState)?.editDetails

  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getAllModeratorsAction(pagination , communityState?.id)
      .then((res) => {
        setDataLoading(false);
        if(!res.status){
        }else{
          setPrompt(res.data ? res?.data?.moderatorObjectListDtos : []);
          setRowCount(res?.data?.totalCount || 0)
        }     
      })
      .catch((err) => {
        setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : 'Error')
          setDataLoading(false);
      });

  }, [toggle ,pagination]);

const getUser = (name:string) =>{
  setLoading(true)
  getFilteredUsers(name)
  .then((res) =>{
    setLoading(false)
    setUsers(res.candidateLinkedInPromptDetails ? res.candidateLinkedInPromptDetails.reverse() : [])
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err?.message : 'Error')
  })
}
const addModerator = async(values:BasePayload) =>{
  const payload ={
    ...values,
    communityId:communityState?.id,
    communityType:communityState?.communityType,
  }
  setLoading(true)
  //Call Api To Add User
  assignPermission(payload)
    .then((res) =>{
    if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
    }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Successfully Added')
      setToggle(!toggle)
    }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : 'Error')
    })
}
const initialvalues = {
  candidateLinkedInId:'',
  permissions:'',
} 
const closeDropdown = () =>{
  setUsers([])
}



const deletePost = async (id:string) => {
  setLoading(true)
  const payload={
    candidateLinkedInId:id,
    communityId:communityState?.id,
  }
  deleteModeratorAction(payload)
  .then((res) =>{
    if(!res?.status){
      setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Record not found')
    }else{
      setLoading(false)
    setSeverity('success')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Successfully Deleted')
    setToggle(!toggle)
    }
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : 'Error')
   
  })
  //Call Api To Delete User
};
const items = [
  {
    candidate_LINKED_IN_ID:PermissionsTypes.PostAndComments
  },
  {
    candidate_LINKED_IN_ID:PermissionsTypes.Posts
  },
  {
    candidate_LINKED_IN_ID:PermissionsTypes.Comments
  },
  
]
const addModalFields = [
  {
    name: 'candidateLinkedInId',
    label: 'Name',
    type:'select',
    items:users,
    key:'full_NAME',
    callBack:getUser,
    close:closeDropdown,
    uniqueKey:'candidate_LINKED_IN_ID',
    required:true
    
  },
  {
    name: 'permissions',
    label: 'permissions',
    type:'select',
    items:items,
    key:'candidate_LINKED_IN_ID',
    required:true
    
  },
   
];
const editModalFields = [
  {
    name: 'name',
    label: 'Name',
    type:'text'
    
  },
];

  const handleSubmit = (values : BasePayload , type : string)=> {
    // Handle form submission here
    if(type === Type.Add){
      closeModal()
      addModerator(values)
    }
    
};

function pagenated(pageNo:number , pageSize:number){
  setpagination(() =>{
    return {
      pageNo:pageNo,
      pageSize:pageSize
    }
  })
}
  return <>
   <DataGridTable columns={columns} row={prompt} loading={dataLoading} pagenated={pagenated} rowCount={rowCount}/>
    {/* { Add Modal } */}
    <Modal
        open={addModal}
        title="Add Moderator"
        onClose={closeModal}
        form={<GenericForm title='Add Moderator' validationSchema={moderatorSchema}  type='Add Post' fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
      />
      {/* Edit Modal */}
      <Modal
              open={editPromptModal}
              title="Edit Post"
              onClose={closeModal}
              form={<GenericForm title='Save' imageUploadField='postUrl' validationSchema={postSchema}  editedDetails={editDetails} type='Edit Post'  fields={editModalFields} initialValues={initialvalues}   onSubmit={handleSubmit}></GenericForm>}
      />
      {/* Delete Modal */}
          <Modal
              open={deletePromptModal}
              title="Are you sure you want to delete this post?"
              onClose={closeModal}
              icon={<DeleteIcon/>}
              form={<div style={{display:'flex' , justifyContent:'space-around'}}>
              <Button onClick={() =>{closeModal(),deletePost(promptId)} } type='button' variant="contained" color="primary">Delete</Button>
              <Button onClick={closeModal} type='button' variant="contained" color="primary">Cancel</Button>
            </div>}
      />
        <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default ModeratorGeneralisedComponent;