import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import DataGridTable from "../../components/table/DataGridTable";
import { GridColDef } from "@mui/x-data-grid";
import {
  ApiResponseOfReportedBugs,
  BugReport,
  deleteReportedBugs,
  getReportedBugs,
} from "../../app/reportedBug/reportedBugs";
import Toaster from "../Toaster/Toaster";
import { AlertColor, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DeleteResponse } from "../../app/reportedComments/reportedComments";
import DeleteModal from "../Modal/DeleteModal";

const ReportedBugs = () => {
  const [data, setData] = useState<Array<BugReport>>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [selected, setSelected] = useState<string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })

  const getDataHandler = async () => {
    setLoading(true);
    try {
      const response: ApiResponseOfReportedBugs = await getReportedBugs(pagination);
      setData(response?.data?.reportedBugsResponseDtos || []);
      setRowCount(response?.data?.totalCount || 0)
    } catch (err: any) {
      setSeverity("error");
      setOpenAlert(true);
      setMessage(err?.message || "Something went wrong !");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataHandler();
  }, [pagination]);

  const columns: GridColDef[] = [
    {
      field: "bugReporterName",
      headerName: "Bug Reporter Name",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },

    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
  ];
  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }
  return (
    <Layout title="Reported-Bugs">
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "7px" }}>
        <Button
          onClick={() => {
            setOpenDeletePopup(true);
          }}
          disabled={selected?.length > 0 ? false : true}
          variant="outlined"
          color="error"
        >
          Delete
        </Button>
      </Box>

      <DataGridTable
        columns={columns}
        row={data}
        loading={loading}
        checkboxSelection={data?.length > 0 ? true : false}
        setSelected={setSelected}
        pagenated={pagenated} 
        rowCount={rowCount}
      />

      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />

      <DeleteModal
        description={"you want to delete selected reported bugs?"}
        open={openDeletePopup}
        handleClose={() => {
          setOpenDeletePopup(false);
        }}
        handleDelete={async () => {
          setOpenDeletePopup(false);
          setLoading(true);
          const payload = selected;
          const response: DeleteResponse = await deleteReportedBugs(payload);
          if (response?.code === 200) {
            getDataHandler();
            setSeverity("success");
            setOpenAlert(true);
            setMessage(
              response?.message || "Reported bug delete Successfully."
            );
          } else {
            setOpenAlert(true);
            setSeverity("error");
            setMessage(response?.message || "Unable to delete reported bug.");
          }

          setLoading(false);
        }}
      />
    </Layout>
  );
};

export default ReportedBugs;
