import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { PolicyPayload , AddPolicy } from "../../../types/common";
const initialState = {
};



export const getTermsAndConditions = async () : Promise<string> =>{
   try{
    const response = await  axios.get(`policy/TERMSANDCONDITIONS`)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
}

export const addTermsAndConditions = async (payload: PolicyPayload) : Promise<AddPolicy> =>{
    const body = JSON.stringify(payload)
   try{
    const response = await  axios.post(`policy/add`,body)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
  }
export const termsAndConditionSlice = createSlice({
    name: "TermsAndConditions",
    initialState,
    reducers:{},
})

