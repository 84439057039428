import { configureStore , combineReducers , Store } from "@reduxjs/toolkit";
import rootReducers from "./rootReducers";
import logger from "redux-logger";
const rootReducer = combineReducers(rootReducers)
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>{
        return getDefaultMiddleware({
            serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
          })
    }
  
})
export default store;
export let persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;