import * as Yup from 'yup';

export const tipSchema = Yup.object({
    title: Yup.string()
      .min(3,'Title must be at least of 3 characters')
      .matches(/^[A-Za-z][A-Za-z ]*$/, 'Title must start with alphabetic character and only contain alphabets')
      .required('Title is required'),
      body:Yup.string()
      .min(3,'Body must be at least of 3 characters')
      .matches(/^[A-Za-z][A-Za-z ]*$/, 'Body must start with alphabetic character and only contain alphabets')
      .required('Body is required'),
});