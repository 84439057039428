import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import PostGeneralisedComponent from './PostsGeneralisedComponent'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
export default function Post() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [promptId, setPromptId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [editFeedDetails , setEditFeedDetails] = React.useState<GridRowParams | null>(null)

const closeModal = () =>{
        setAddModal(false)
        setEditModal(false)
        setDeleteModal(false)
}
const editFeedModal = (params :GridRowParams) =>{
    setEditModal(true)
    setEditFeedDetails(params ? params?.row : [])

}
function deletePromptModal(id: string) {
  setPromptId(id)
  setDeleteModal(true)
}

function formatAMPM(date) {
  var year =new Date(date).getFullYear()
  var month =new Date(date).getMonth() +1
  var day =new Date(date).getDate()
  var hours = new Date(date).getHours();
  var minutes :(string | number) = new Date(date).getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = year+"/" + month + "/" + day +" "+hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const columns: GridColDef[] = [
  { field: 'communityName', headerName: 'Community', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'seederName', headerName: 'Seeder', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'dateTime', headerName: 'Scheduled Time', flex: 1,
  renderCell: (params) => {
    return params.value ? formatAMPM(params.value) : 'No Data';
  }
},
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deletePromptModal(params?.row?.id)}}
          />
      ];
    },
  }

]
  return (
  <>
  <Layout  title="Post Scheduler">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => setAddModal(true)} variant="contained">Schedule Post</Button></div>
        <PostGeneralisedComponent promptId={promptId} deletePromptModal={deleteModal}  editDetails={editFeedDetails}  editPromptModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  />
    </div>
  </Layout>
  </>
  )
}
