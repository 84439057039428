import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom'
import FriendList from './friendsList'
export default function SeederMessages() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [educationId, setEducationId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [editEducationDetails , setEditEducationDetails] = React.useState<GridRowParams | null>(null)
    const navigate = useNavigate();

const closeModal = () =>{
    setAddModal(false)
    setEditModal(false)
    setDeleteModal(false)
}
const editEducationModal = (params :GridRowParams) =>{
    setEditModal(true)
    setEditEducationDetails(params?.row)

}
function deleteEducationModal(id: string) {
  setEducationId(id)
  setDeleteModal(true)
}
const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deleteEducationModal(params?.row?.id)}}
          />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          showInMenu
          onClick={() => {editEducationModal(params)} }
        />,
      ];
    },
  }

]
  return (
  <>
  <Layout  title="Seeder Messages">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() =>  navigate("/compose-message")} variant="contained">Compose</Button></div>
      <FriendList/>  {/* <SeederMessagesGeneralisedComponent educationId={educationId}  deleteEducationModal={deleteModal} editDetails={editEducationDetails}  editEducationModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  /> */}
    </div>
  </Layout>
  </>
  )
}
