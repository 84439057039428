import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import TextField from '@mui/material/TextField';

// Extend Day.js with the isSameOrBefore plugin
dayjs.extend(isSameOrBefore);

interface Props {
  previousDateAndTime: any;
  onDateTimeChange: (formattedDateTime: string | null) => void;
  mode?:string,
  checkingValue?:any
}

export default function BasicDateTimePicker({ previousDateAndTime, onDateTimeChange , mode , checkingValue }: Props) {
  // Initialize the value state
  const [value, setValue] = React.useState<Dayjs | null>(
    previousDateAndTime ? dayjs(previousDateAndTime).add(1, 'minute') : null
  );
  const [error, setError] = React.useState<string | null>(null);

  const handleChange = (newValue: Dayjs | null) => {
    const dateAndTime = mode === "Edit" ? checkingValue : previousDateAndTime
    if (newValue && dateAndTime) {
      // Check if the selected date is the same as the previous date
      if (newValue.isSame(dateAndTime, 'day')) {
        // Validate that the new time is at least one minute ahead of the previous time
        if (newValue.isBefore(dayjs(dateAndTime).add(1, 'minute'))) {
          setError('The selected time must be at least one minute ahead of the previous time.');
          return;
        }
      } 
      // If the new date is after the previous date, no time restriction
      else if (newValue.isBefore(dateAndTime)) {
        setError('The selected date and time must be after the previous date and time.');
        return;
      }
    }

    // Clear any error and update state
    setError(null);
    setValue(newValue);

    // Convert Day.js object to a formatted string with both date and time (e.g., YYYY-MM-DD HH:mm:ss)
    const formattedDateTime = newValue ? newValue.format('YYYY-MM-DD H:mm:ss') : null;
    onDateTimeChange(formattedDateTime);  // Pass formatted date-time string
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Comment Date & Time"
        value={value}
        onChange={handleChange}
        slotProps={{
          textField: {
            helperText: error,
          },
        }}
        minDateTime={previousDateAndTime ? dayjs(previousDateAndTime) : undefined}
      />
    </LocalizationProvider>
  );
}
