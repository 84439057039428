import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import store from './app/store';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import App from './App';
import theme from './theme';
import { Provider } from 'react-redux';
import { persistor } from "./app/store";
import { PersistGate } from 'redux-persist/es/integration/react';
// import { GlobalStyles } from '@mui/system';
import { globalStyle } from './theme';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <GlobalStyles styles={globalStyle} />
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
    </Provider>
  </ThemeProvider>,
);
