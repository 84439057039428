import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { PostScheduleResponse, message } from "../../../types/common";
import { PaginationInterface } from "../../../types";
const initialState = {};
export interface PostList{
 ans: string,
candidate_LINKED_IN_ID :string
communityName:string
dateTime:string
id : string
seederName:string
timeZone:string,
title:string
}
export interface PostResponse{
list:PostList[]
size:number,
totalRecord:number

}
export interface candidateLinkedInPromptDetails{
candidate_LINKED_IN_ID: string
credCount : number
full_NAME: string
is_FRIEND: boolean
is_VERIFIED: boolean
location_ADDRESS: string
photo_URL: string
}
export interface FilteredUsersResponse{
  candidateLinkedInPromptDetails:candidateLinkedInPromptDetails[],
  size:number
}
export interface Post{
  message: string,
  code: number,
  data: PostResponse
  status: boolean
}

export const getPostAction = async (pagination:PaginationInterface) : Promise<Post>=>{
try{
  const response = await  axios.get(`linkedin-candidate/jobScheduler/getAllPosts?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
export const getFilteredUsers = async (candidateName:string) : Promise<FilteredUsersResponse>=>{
  try{
    const response = await  axios.get(`linkedin-candidate/getAll/${candidateName}`)
    return response.data.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
  export const schedulePost = async (payload: any) : Promise<PostScheduleResponse> =>{
    const body = JSON.stringify(payload)
   try{
    const response = await  axios.post(`linkedin-candidate/jobScheduler/schedulePosts`, body)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
  }
export const deletePostAction = async (id : string) : Promise<message> =>{
 try{
  const response = await  axios.delete(`linkedin-candidate/jobScheduler/delete/${id}`)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
export const postSlice = createSlice({
    name: "Post",
    initialState,
    reducers:{},
})

