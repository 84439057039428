import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { LoginPayload } from "../../../types/login";
import { useAppDispatch } from "../hooks";
import { RootState } from "../store";

const initialState = {
    editDetails: null,
    photoUrl:null,
    file:null,
    url:null
};


export const communityEditSlice = createSlice({
    name: "communityEdit",
    initialState,
    reducers: {
        setEditDetails: (state, action) => {
          return {
            ...state,
            editDetails: action.payload,
          };
        },
        setImage: (state, action) => {
          return {
            ...state,
            photoUrl: action.payload,
          };
        },
        setfile: (state, action) => {
          return {
            ...state,
            file: action.payload,
          };
        },
        setUrl: (state, action) => {
          return {
            ...state,
            url: action.payload,
          };
        }
    },
   
})

export const { setEditDetails , setImage , setfile , setUrl} = communityEditSlice.actions;
export const selectCommunityState = (state: RootState) => state.communityReducer;
export default communityEditSlice.reducer;