import React, { useState, useEffect } from "react";
import GenericForm from "../../form/GenericForm";
import * as Yup from "yup";
import { getCommunityAction } from "../../../app/community/community";
import { getRedditContentScraper } from "../../../app/content/subRedditScraper";
import { PaginationInterface } from "../../../../types";
import { Pagination } from "@mui/material";

interface CommunityTopicResponse {
  id: string;
  name: string;
  imageUrl: string;
  hexColor: string;
}

interface Community {
  id: string;
  communityName: string;
  photoUrl: string;
  description: string;
  hexColor: string;
  createdBy: string;
  updatedBy: string;
  communityType: "PUBLIC" | "ANON" | "PRIVATE";
  communityTopicResponses: CommunityTopicResponse[];
}

interface Pagination {
  pageNo: number;
  pageSize: number;
}
interface DataObject {
  pagination: Pagination;
  id: string;
}

interface ApiResponse<T> {
  message: string;
  code: number;
  data: T;
  status: boolean;
}
interface Payload {
  communityId: string;
  subredditName: string;
  number_of_posts: number;
  sort_option: string;
}

const RedditContentScraperForm = ({
  setLoading,
  setData,
  setSeverity,
  setOpenAlert,
  setMessage,
}) => {
  const [community, setCommunity] = useState<Community[]>([]);
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  React.useEffect(() =>{
getCommunity(pagination)
  },[pagination])
  const getCommunity = async (Data: any) => {
    try {
      setLoading(true);
      const res: ApiResponse<any> = await getCommunityAction(Data);

      if (res?.status && res?.data) {
        setCommunity(res?.data?.list || []);
        setLoading(false);
        setRowCount(res?.data?.totalCount)
      } else {
        setCommunity([]);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setSeverity("error");
      setOpenAlert(true);
      setMessage(error ? error?.message : "Error");
    }
  };

  const handleSubmit = async (data: Payload) => {
    if (!data?.communityId && !data?.subredditName) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Please enter community name or subreddit name.");
      return;
    }

    if (data?.number_of_posts <= 0) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Post no should be greater than zero(0).");
      return;
    }

    setLoading(true);

    try {
      let queryString = "";
      for (const key in data) {
        if (data.hasOwnProperty(key) && data[key] !== "") {
          queryString += `${key}=${data[key]}&`;
        }
      }

      queryString = queryString?.slice(0, -1);

      const response = await getRedditContentScraper(queryString);

      if (response?.code === 200) {
        setData(response?.data?.subredditScraperResponses);
        setLoading(false);
        if (
          response?.data?.subredditScraperResponses?.length === 0 ||
          response?.data === null
        ) {
          setOpenAlert(true);
          setSeverity("warning");
          setMessage(response?.message || "No Data Found.");
        }
      }
    } catch (err: any) {
      setLoading(false);
      setOpenAlert(true);
      setSeverity("error");
      setMessage(err?.message || "Error");
    }
  };

  const validation = Yup.object({
    communityId: Yup.string(),
    subredditName: Yup.string(),
    number_of_posts: Yup.number().required("No of Posts is required."),
    sort_option: Yup.string().required("Sort Option is required."),
  });

  const initialvalues = {
    communityId: "",
    subredditName: "",
    number_of_posts: "",
    sort_option: "",
  };
  function setPage(pageSize){
    setpagination((prev) =>{ return {...prev , pageSize:pageSize ? pageSize : prev.pageSize + 10}})
  }
  function reset(){
    setpagination((prev) =>{
      return {
        ...prev , 
        pageSize:10
      }
    })
  }
  const fields = [
    {
      label: "Select Community",
      name: "communityId",
      type: "drop",
      items: community,
      key: "communityName",
      callBack: setPage,
      totalCount: community?.length,
      reset:reset,
      payload:{
        pagination:true
      },
      rowCount:rowCount,
      paginationSize:pagination?.pageSize,
    },
    {
      label: "Enter the Subreddit Name",
      name: "subredditName",
      type: "text",
    },
    {
      label: "Number of posts per subreddit",
      name: "number_of_posts",
      type: "number",
      required: true,
    },
    {
      label: "Sort Option",
      name: "sort_option",
      required: true,
      type: "select",
      items: [
        {
          candidate_LINKED_IN_ID: "new",
          key: "new",
        },
        {
          candidate_LINKED_IN_ID: "hot",
          key: "hot",
        },
        {
          candidate_LINKED_IN_ID: "top",
          key: "top",
        },
        {
          candidate_LINKED_IN_ID: "rising",
          key: "rising",
        },
      ],

      key: "candidate_LINKED_IN_ID",
    },
  ];

  return (
    <GenericForm
      title="Search"
      validationSchema={validation}
      type="Reddit Content Scraper"
      fields={fields}
      initialValues={initialvalues}
      onSubmit={handleSubmit}
    />
  );
};

export default RedditContentScraperForm;
