import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import Toaster from "../components/Toaster/Toaster";
import { AlertColor } from "@mui/material";
import { useLocation } from "react-router-dom";
import OTPPopup from "./OTPPopup";
import {
  twoFAResponse,
  twoFactorAuthenticationGetQr,
  twoFactorAuthenticationOTPVerification,

} from "../app/auth/twoFactorAuth";
import { twoFactorAuthenticationStatus } from "./TwoFactorAuthenticationSlice";
import { useAppDispatch } from "../app/hooks";


const defaultTheme = createTheme();

const GoogleAuthenticator = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState<any>(null);
  const [otpPopup, setOtpPopup] = React.useState(false);


  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const getQRCodeHandler = async () => {
    setLoading(true);
    try {
      const response: Blob = await twoFactorAuthenticationGetQr(
        location?.state?.email
      );
      const url = URL.createObjectURL(response);

      setQrCodeUrl(url);
    } catch (error) {
      console.error("Error getting qr:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!location.state) {
      navigate('/')
    }
    if (!location?.state?.isQrGenerated) {
      getQRCodeHandler();
    }
    location?.state?.isQrGenerated ? setOtpPopup(true) : false;
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const otpCode = otp?.join("");
    if (otpCode?.length === 6) {
      try {
        const payload = {
          totpKey: otpCode,
          email: location?.state?.email,
        };
        const response: twoFAResponse<boolean | null> = await twoFactorAuthenticationOTPVerification(
          payload
        );
        if (response?.status) {
          dispatch(
            twoFactorAuthenticationStatus({
              isQrGenerated: location?.state?.isQrGenerated,
              is2FaEnabled: true,
              is2FaVerified: true,
            })
          );
          navigate("/users");
        } else {
          setSeverity("error");
          setOpenAlert(true);
          setMessage(response?.message || "Enter valid OTP");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setSeverity("error");
      setOpenAlert(true);
      setMessage("Enter 6 digits valid OTP.");
    }
  };

  const renderInstructions = () => (
    <>
      <Typography
        variant="body2"
        sx={{ fontWeight: 500, fontSize: "20px", mb: "10px", p: "10px" }}
      >
        Two-Factor Authentication(2FA)
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 450,
          p: "10px",
          color: "primary.main",
          mb: "10px",
          borderBottom: "2px solid",
          borderBottomColor: "#f4f4f4",
        }}
      >
        Configuring Google Authenticator
      </Typography>
      <Typography variant="body2" sx={{ pl: "10px" }}>
        1. Install Google Authenticator (iOS-Android)
      </Typography>
      <Typography variant="body2" sx={{ pl: "10px" }}>
        2. In the authenticator app, select "+" icon.
      </Typography>
      <Typography variant="body2" sx={{ pl: "10px" }}>
        3. Select "Scan a barcode (or QR Code)" and use the phone's camera to
        scan this barcode.
      </Typography>
      <Typography variant="body2" sx={{ pl: "10px" }}>
        4. Enter the authentication code:
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 450,
          p: "10px",
          color: "primary.main",
          mb: "10px",
          borderBottom: "2px solid",
          borderBottomColor: "#f4f4f4",
        }}
      >
        Scan QR Code
      </Typography>
    </>
  );

  const renderOtpFields = () =>
    otp?.map((_, index) => (
      <TextField
        key={index}
        value={otp[index]}
        onChange={(e) => handleChange(e, index)}
        onKeyDown={(e) => handleKeyDown(e, index)}
        size="small"
        inputProps={{
          style: { textAlign: "center", width: "35px" },
          maxLength: 1,
        }}
        inputRef={(el) => (inputsRef.current[index] = el)}
        sx={{ mr: "5px" }}
      />
    ));

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "70%",
          margin: "auto",
          padding: "10px",
          mt: "10px",
        }}
      >
        {!location?.state?.isQrGenerated && (
          <Grid item xs={12}>
            {renderInstructions()}
          </Grid>
        )}

        {!location?.state?.isQrGenerated && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
             {qrCodeUrl && (
              <Box
                component="img"
                sx={{ height: 150, mt: "20px", mb: "20px" }}
                src={qrCodeUrl}
                alt="QR Code"
              />
            )}
          </Grid>
        )}
        {!location?.state?.isQrGenerated && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderOtpFields()}
          </Grid>
        )}

        {!location?.state?.isQrGenerated && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Verify OTP
            </Button>
          </Grid>
        )}
      </Grid>
      <Loader loading={loading} />
      <OTPPopup
        open={otpPopup}
        handleClose={() => {
          setOtpPopup(false);
        }}
        email={location?.state?.email}
      />
      <Toaster
        handleClose={() => {
          setOpenAlert(false);
        }}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
    </ThemeProvider>
  );
};

export default GoogleAuthenticator;
