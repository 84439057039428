import React from "react";
import { Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { deleteSubRedditManager } from "../../app/content/subredditManager";
import { UpvoteBoosterResponse } from "../form/type";
import { Box } from "@mui/system";

const SubredditCard = ({
  data,
  setLoading,
  setOpenAlert,
  setSeverity,
  setMessage,
  fetchData,
}) => {
  return (
    <Grid container sx={{ mb: "10px" }}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#ede6e6",
          padding: "10px",
          border: "1px solid",
          borderColor: "#c9c7c7",
          fontWeight: "400",
          mb: "10px",
        }}
      >
        <Typography sx={{ fontWeight: "500" }}>
          {data?.communityName}
          <Chip
            label={data?.communityType}
            color={
              data?.communityType === "PRIVATE"
                ? "primary"
                : data?.communityType === "PUBLIC"
                ? "success"
                : "warning"
            }
            size="small"
            sx={{ ml: "5px", fontSize: "10px" }}
          />
        </Typography>
      </Grid>

      <Typography sx={{ fontSize: "14px", fontWeight: "500", mb: "10px" }}>
        Subreddits:
      </Typography>

      {data?.subreddits?.map((subreddit: any) => (
        <Grid
          item
          key={subreddit?.id}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid",
            borderColor: "#c9c7c7",
            mb: "5px",
            ml: "20px",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {subreddit?.subRedditName}{" "}
          </Typography>
          <Tooltip title="Delete">
            <Button
              sx={{ border: "none", width: "24px" }}
              onClick={async () => {
                setLoading(true);
                const response: UpvoteBoosterResponse<boolean> =
                  await deleteSubRedditManager(subreddit.id);
                if (response.code == 200) {
                  setOpenAlert(true);
                  setSeverity("success");
                  setLoading(false);
                  fetchData();
                  setMessage(
                    response?.message || "Record Deleted Successfully."
                  );
                } else {
                  setOpenAlert(true);
                  setSeverity("error");
                  setLoading(false);
                  setMessage(
                    response?.message || "Unable to delete the record."
                  );
                }
              }}
            >
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                sx={{ color: "red" }}
              />
            </Button>
          </Tooltip>
        </Grid>
      ))}

      {data?.subreddits?.length === 0 ? (
        <Grid item xs={12} sx={{ ml: "20px" }}>
          {" "}
          <Typography sx={{ fontSize: "12px" }}> No Data</Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default SubredditCard;
