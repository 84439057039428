import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
const initialState = {};
export interface AnalyticsResponse{
      dau: number,
      wau: number,
      referral_CONVERSION_RATE: number,
      user_INTERACTION_RATE: number,
      crt_SAVE_PROFILES: number

  }
export const getAnalytics = async () : Promise<AnalyticsResponse> =>{
   try{
    const response = await  axios.get(`analytics/data`)
    return response.data.data;

   }catch(err:any){ 
    throw new Error(err)
   }
  
}


export const analytics = createSlice({
    name: "Analytics",
    initialState,
    reducers:{}
})

