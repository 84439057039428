import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DataGridTable from '../table/DataGridTable';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSeederState } from '../../app/seeders/seeders';
import { getCommunityAction } from '../../app/community/community';
import { setCommunitie } from '../../app/seeders/seeders';
const columns: GridColDef[] = [
    { field: 'communityName', headerName: 'Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   type: 'actions',
    //   flex: 1,
    //   getActions: (params: GridRowParams) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         showInMenu
    //         onClick={() => {deletePromptModal(params?.row?.id)}}
    //         />,
    //       <GridActionsCellItem
    //         icon={<ManageAccountsIcon />}
    //         label="Manage"
    //         showInMenu
    //         onClick={() => {setManage(true) , dispatch(setEditDetails(params?.row))} }
    //       />,
    //     ];
    //   },
    // }
  
  ]
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface dialogProps{
    dialogOpen:boolean,
    handleCommunityClose:() => void,
    setCommunityFieldCallBack?:(value:any) => void
}
const FullScreenDialog :  React.FC<dialogProps> = ({setCommunityFieldCallBack,dialogOpen , handleCommunityClose}) =>{
  const [open, setOpen] = React.useState(false);
  const [community, setCommunity] =  React.useState<any>([]);
  const [toggle , setToggle] = React.useState<boolean>(false);
  const [loading , setLoading] = React.useState<boolean>(false);
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [severity , setSeverity] = React.useState<string>('');
  const [rowCount,setRowCount]= React.useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [initialValue,setInitialValue]= React.useState([]);

  const dispatch = useAppDispatch()
  const com:any = useAppSelector(selectSeederState)?.communitites
  const actionType = useAppSelector(selectSeederState)?.actionType
  React.useEffect(() => {
    setDataLoading(true);
    getCommunityAction(pagination)
      .then((res) => {
        if(!res?.status){
          setDataLoading(false);
        }else{
          setCommunity(res.data?.list ? res.data?.list : []);
          setRowCount(res?.data?.totalCount || 0)
          setDataLoading(false);
        }      
      })
      .catch((err) => {
        setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          setDataLoading(false);
          setDataLoading(false);
      });
      setInitialValue(com);
  }, [pagination]);

  const handleClose = () => {
    if (actionType !== "Edit") {
      dispatch(setCommunitie([]));
    } else {
      dispatch(setCommunitie(initialValue));
    }
    handleCommunityClose();
  };
  function handleSave(){

    const newArray = community
  .filter((item:any) => com?.map((idObj:any) => idObj.id).includes(item.id)) // Filter community array based on matching IDs
  ?.map(({ id , communityName }:any) => ({ id, communityName })); 
  const updatedCommunity=newArray?.length>=1 ? newArray :initialValue

  dispatch(setCommunitie(updatedCommunity))
  setInitialValue(updatedCommunity)
      handleCommunityClose()
     
  }
  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Communities
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DataGridTable setCommunityFieldCallBack={setCommunityFieldCallBack} checkboxSelection={true} columns={columns} row={community} pagenated={pagenated} rowCount={rowCount}  loading={dataLoading} />
      </Dialog>
    </React.Fragment>
  );
}

export default FullScreenDialog;