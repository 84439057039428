import * as Yup from 'yup';

export const loginSchema = (inputType:string) =>{
  let schema;
  
  if (inputType === "username") {
   return  schema = Yup.object({
      username: Yup.string().min(2).max(20).required('Username is required'),
      password: Yup.string().min(4,'Password must be at least 4 characters').max(15).required('Password is required')
    });
  } else {
    return schema = Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(4,'Password must be at least 4 characters').max(15).required('Password is required')
    });
  }
}