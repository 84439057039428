import * as React from 'react';
import MyRoutes from './routes/MyRoutes';
import ErrorBoundary from './components/ErrorBoundry/ErrorBoundary';
export default function App() {

return (
<ErrorBoundary>
<MyRoutes/>
</ErrorBoundary>


  );
}
