import * as Yup from 'yup';

export const notificationSchema = Yup.object({
    title: Yup.string()
      .min(3,'Title must be at least of 3 characters')
      .matches(/[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;'"<>,.?~|\\\/-]+$/, 'Enter valid characters only')
      .required('Title is required'),
      body:Yup.string()
      .min(3,'Body must be at least of 3 characters')
      .matches(/[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;'"<>,.?~|\\\/-]+$/, 'Enter valid characters only')
      .required('Body is required'),
      dateTime:Yup.date()
        .required('Date is required')
});