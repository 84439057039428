import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import CommunityGeneralisedComponent from './CommunityGeneralisedComponent'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {Type} from '../../enum/index'
import NestedList from '../List/List'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CommunityTypes } from '../../enum/index'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useAppSelector , useAppDispatch} from '../../app/hooks'
import { selectCommunityState, setEditDetails, setImage , setUrl} from '../../app/communityEditDetails/communityEdit'
import { selectCommunityEditState } from '../../app/CommunityEdit/communityEdit'
import { Box } from '@mui/system' //
export default function Community() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [promptId, setPromptId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [operationType , setOperationType] = React.useState("")
    const [editTipsDetails , setEditTipsDetails] = React.useState<any | null>(null)
    const [name , setName] = React.useState("")
    const [manage , setManage] = React.useState(false)
  const dispatch = useAppDispatch();
  const communityState:any = useAppSelector(selectCommunityState)?.editDetails
  const state = useAppSelector(selectCommunityEditState).current
const closeModal = () =>{
        setAddModal(false)
        setEditModal(false)
        setDeleteModal(false)
        setManage(false)
}
const editPromptModal = (params :any | null) =>{
  setOperationType(Type.Edit)
    setEditModal(true)
    setEditTipsDetails(params ? params : [])

}
function callBackEdit(name : string){
  setManage(false)
  if(name === 'icon'){
    const details = {
      id:communityState ? communityState?.id : "",
      photoUrl:communityState ? communityState?.photoUrl : "",
      communityType:communityState ? communityState?.communityType : "",
      name:'icon'
    }
    editPromptModal(details)
  }else if(name === 'desc'){
    const details = {
      id:communityState ? communityState?.id : "",
      description : communityState ? communityState?.description : "",
      communityType:communityState ? communityState?.communityType : "",
      name:'desc'
    }
    editPromptModal(details)
  }else if(name === 'type'){
    const details = {
      id:communityState ? communityState?.id : "",
      communityType:communityState ? communityState?.communityType : "",
      name:'type'
    }
    editPromptModal(details)
  }else if(name === 'topic'){
    const details = {
      id:communityState ? communityState?.id : "",
      communityType:communityState ? communityState?.communityType : "",
      communityTopic:communityState ? communityState?.communityTopicResponses : "",
      name:'topic'
    }
    editPromptModal(details)
  }else if(name === 'name'){
    const details = {
      id:communityState ? communityState?.id : "",
      communityName:communityState ? communityState?.communityName : "",
      name:'name'
    }
    editPromptModal(details)
  }else if(name === 'color'){
    const details = {
      id:communityState ? communityState?.id : "",
      hexColor:communityState ? communityState?.hexColor : "",
      name:'color'
    }
    editPromptModal(details)
  }
  
}
function deletePromptModal(id: string) {
  setPromptId(id)
  setDeleteModal(true)
}
const columns: GridColDef[] = [
  {
    field: "communityName",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box sx={{ color: params?.row?.hexColor }}>
          {" "}
          {params.value ? params.value : "No Data"}
        </Box>
      );
    },
  },
  { field: 'description', headerName: 'Description', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data') },
  { field: 'communityType', headerName: 'Type', flex: 1,renderCell: (params) => (params.value ? params.value :CommunityTypes.Public) },
  { field: 'photoUrl',type:"actions", headerName: 'Logo', width:10, flex: 1,renderCell: (params) =>  <Card>
  <CardMedia
    component="img"
    alt="not available"
    height="45"
    image={params ? params.value : ''} // Replace with the URL of your image
  />
</Card>},
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deletePromptModal(params?.row?.id)}}
          />,
        <GridActionsCellItem
          icon={<ManageAccountsIcon />}
          label="Manage"
          showInMenu
          onClick={() => {setManage(true) , dispatch(setEditDetails(params?.row))} }
        />,
      ];
    },
  }

]

  return (
  <>
  <Layout  title="Community">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => {setAddModal(true), setOperationType(Type.Add)}} variant="contained">Add Community</Button></div>
        <CommunityGeneralisedComponent name={name} callBackEdit={callBackEdit} manage={manage} operationType={operationType} promptId={promptId} deletePromptModal={deleteModal}  editDetails={editTipsDetails}  editPromptModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  />
    </div>
  </Layout>
  </>
  )
}
