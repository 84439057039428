import { Button, Card, CardMedia, FormControl, Input, TextField } from '@mui/material';
import { Container, styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Field, useFormik } from 'formik';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from "@mui/material/CircularProgress";
import { EditSchema } from '../../schema/common';
import Box from '@mui/material/Box';
import { editPostManagerAction , deleteImageAction } from '../../app/seeders/postManager';
import Tooltip from '@mui/material/Tooltip';

const ImageContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row',

});
const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};
const ClearIconContainer = styled('div')({
    display: 'block', // Initially hide the clear icon
    cursor: 'pointer',
    color:"grey",
    verticalAlign:"center",
  });
const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const EditPost = ({ editedDetails, modelClose , closeModal }) => {
    const [file, setFile] = React.useState(editedDetails?.postUrl ? editedDetails?.postUrl : '');
    const [disable, setDisable] = React.useState<boolean>(false)
    const [deleteProgress, setDeleteProgress] = React.useState<boolean>(false)
    const [message, setMessage] = React.useState<string>("")

    const initialvalues = {
        title: editedDetails.title,
        ans: editedDetails.ans,
        postUrl: ""
    }

        const deleteImage  = async (id:string) => {
            setDeleteProgress(true)
            deleteImageAction(id)
            .then((res) =>{
            setDeleteProgress(false)
             if(!res?.status){
                closeModal()
             } else{
                setFile("")
                setMessage('Image has been deleted')
             }
            })
            .catch((err) =>{
              setDeleteProgress(false)
             closeModal()
            })
            //Call Api To Delete User
          }; 
      
       
      
    const formik = useFormik({
        initialValues: editedDetails ? editedDetails : initialvalues,
        validationSchema: EditSchema,
        onSubmit: (values) => {
            let url = values['postUrl'] ? values['postUrl'].split(',') : ""
            values['postUrl'] = url[1]
            editPostManagerAction(values)
                .then((res) => {
                    if (!res?.status) {
                        const data = {
                            alert: true,
                            message: res?.message ? res?.message : 'Record not found',
                            serverity: 'error',
                        }
                        modelClose(data)
                    } else {
                        const data = {
                            alert: true,
                            message: res?.message ? res?.message : 'Successfully Updated',
                            serverity: 'success',
                        }
                        modelClose(data)
                    }
                }).catch((error) => {
                   
                })
        }
    })


    const handleFileChange = (e: React.BaseSyntheticEvent) => {
        const myFile = e?.target?.files[0];
        if (myFile && myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg") {
            formik.setFieldValue('myFile', myFile ? myFile : file)
            setFile(myFile)
            getBase64(myFile)
        }
        else if (myFile) {
            formik.setFieldValue('myFile', myFile ? myFile : file)
            setFile(myFile ? myFile : file)
            setTimeout(() => {
                formik.handleBlur(e);
            }, 100);
        }
    };

    function getBase64(file: File) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            formik.setFieldValue("postUrl" ? "postUrl" : "", reader ? reader.result : "");
            setDisable(false)
        };
        reader.onerror = function (error) {
        };
    }
    return (
        <>
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <TextField
                    autoComplete='off'
                    fullWidth
                    required={true}
                    id="title"
                    name="title"
                    label="Title"
                    variant="outlined"
                    margin="normal"
                    type='text'
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    style={{ marginTop: '23px' }}
                />
                <Textarea
                    style={{ marginTop: '23px' }}
                    id="ans"
                    name="ans"
                    value={formik.values.ans}
                    aria-label="minimum height"
                    minRows={3}
                    required={true}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Content" />
                {formik.touched.ans && formik.errors.ans ?
                    <span style={{ color: 'red' }}>
                        {`${formik.errors.ans}`}</span> : null}

                <div>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <div style={{ cursor: "pointer" }}>
                            <Button disabled={disable} style={{ width: "100%", marginBottom: "13px" }} component="label" variant="contained" startIcon={<CameraAltIcon />}>
                                <label style={{ cursor: "pointer" }}
                                    htmlFor="postUrl"
                                >Upload Image
                                    <Input
                                        type="file"
                                        id="postUrl"
                                        name="postUrl"
                                        onChange={(e) => {
                                            handleFileChange(e)
                                        }}
                                        onBlur={formik.handleBlur}
                                        style={{ width: "0px" }}
                                    />
                                </label>
                            </Button>
                        </div>
                      {!deleteProgress ?   <Container style={{display:"flex" , justifyContent:'space-between' , alignItems:'center'}}>
                            <ImageContainer>
                                <div>
                                   <Card>
                                        <CardMedia
                                            component="img"
                                            height="45"
                                            image={typeof (file) === typeof ("") ? file : URL?.createObjectURL(file)}
                                        /> 
                                    </Card>
                                </div>
                            </ImageContainer>
                            {file && (
                                <ClearIconContainer onClick={() =>{ 
                                    if(editedDetails?.postUrl){
                                        deleteImage(editedDetails ? editedDetails?.id : "")
                                    }else{
                                        setFile("")
                                        formik.setFieldValue("postUrl" ? "postUrl" : "", "");
                                    }
                                    
                                    }} className="clear-icon-container">
                                    <Tooltip title="Remove image"><ClearIcon /></Tooltip>
                                </ClearIconContainer>
                            )}
                        </Container> :  <CircularProgress size={20} />}
                    </FormControl>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <Button style={{ marginTop: '20px' }} color="primary" variant="contained" type="submit">
                        Update Post
                    </Button>
                </div>
            </form>
        </>
    )
}
export default EditPost;