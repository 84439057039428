import React , {useEffect , useState}from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import { addTipsAction, getTipsAction , deleteTipsAction } from '../../app/tips/tips'
import { Delete as DeleteIcon} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import {Type} from '../../enum/index'
import {Payload} from '../../../types/common'
import { tipSchema } from '../../schema/tip'
import { TipsPayload } from '../../../types/tipsTypes'
import { TipResponse } from '../../app/tips/tips'
import { TipsList } from '../../app/tips/tips'
interface TipsProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editPromptModal:boolean
    editDetails:GridRowParams | null
    promptId:string
    deletePromptModal:boolean
    operationType:string
}
const TipsGeneralisedComponent: React.FC<TipsProps> =  ({operationType , deletePromptModal,promptId, addModal , closeModal , columns , editPromptModal , editDetails }) => {
  const [tips, setTips] =  useState<Array<TipsList>>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity , setSeverity] = useState<string>('');
  const [filteredData , setFilteredData] = useState<Array<TipResponse>>([]);

  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getTipsAction()
      .then((res) => {
        if(!res?.status){
          setDataLoading(false);
        }else{
          setTips(res.data?.list ? res.data?.list?.reverse() : []);
          setDataLoading(false);
        }      
      })
      .catch((err) => {
        setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          setDataLoading(false);
        setDataLoading(false);
      });
  }, [toggle]);

const initialvalues = {
        title:"",
        body:""
} 


const addTip = async(values:TipsPayload) =>{
  setLoading(true)
  //Call Api To Add User
  addTipsAction(values)
    .then((res) =>{
      if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
      }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(operationType === Type.Edit ? 'Successfully Updated': 'Successfully Added')
      setToggle(!toggle)
      }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
    })
}

const deleteTip = async (id:string) => {
  setLoading(true)
    deleteTipsAction(id)
  .then((res) =>{
    if(!res?.status){
      setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Record not found')
    }else{
      setLoading(false)
    setSeverity('success')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Successfully Deleted')
    setToggle(!toggle)
    }
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : "Error")
   
  })
  //Call Api To Delete User
};
const addModalFields = [
    {
      name: 'title',
      label: 'Title',
      type:'text',
      required:true
      
    },
    {
      name: 'body',
      label: 'Body',
      type:'text',
      required:true
      
    }
];
const editModalFields = [
  {
    name: 'title',
    label: 'Title',
    type:'text',
    required:true
    
  },
  {
    name: 'body',
    label: 'Body',
    type:'text',
    required:true
    
  }
];

  const handleSubmit = (values : TipsPayload , type : string)=> {
    // Handle form submission here
    if(type === Type.Add || type == Type.Edit){
      closeModal()
      addTip(values)
    }
    
};
  return <>
   {/* <DataGridTable columns={columns} row={tips} loading={dataLoading} /> */}
    {/* { Add Modal } */}
    <Modal
        open={addModal}
        title="Add Tip"
        onClose={closeModal}
        form={<GenericForm title='Add Tip' validationSchema={tipSchema} type='Add Tip' fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
      />
      {/* Edit Modal */}
      <Modal
              open={editPromptModal}
              title="Edit Tip"
              onClose={closeModal}
              form={<GenericForm title='Save' validationSchema={tipSchema} editedDetails={editDetails} type='Edit Tip'  fields={editModalFields} initialValues={initialvalues}   onSubmit={handleSubmit}></GenericForm>}
      />
      {/* Delete Modal */}
          <Modal
              open={deletePromptModal}
              title="Are you sure you want to delete the tip?"
              onClose={closeModal}
              icon={<DeleteIcon/>}
              form={<div style={{display:'flex' , justifyContent:'space-around'}}>
              <Button onClick={() =>{closeModal(),deleteTip(promptId)}}  type='button' variant="contained" color="primary">Delete</Button>
              <Button onClick={closeModal} type='button' variant="contained" color="primary">Cancel</Button>
            </div>}
      />
        <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default TipsGeneralisedComponent;