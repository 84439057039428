import React from 'react'
import {useFormik} from 'formik'
import { loginSchema } from '../../schema/auth'
import {GeneralizedLoginProps} from '../../../types/login'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const styles = {
  backgroundColor: "white",
  border: 'none',
  outline: 'none',
  color: 'white',
  marginBottom: '10px', // Add custom margin-bottom
  '&:hover': {
    '& fieldset': {
     border:'0px',
     
    },
  },
  '& fieldset': {
    border: '0px',
    display: 'none', // Do not display the fieldset by default
  },
  '& input::placeholder': {
    color: 'white',
     // Set your desired color for the placeholder
  },
  '& label': {
    color: 'black', // Set your desired color for the input label in default state
  },
  '& label.Mui-focused': {
    color: 'black',
    // display:'none' // Set your desired color for the input label when focused
  },
  
};

const GeneralizedLogin: React.FC<GeneralizedLoginProps> = ({title,userLabel,passwordLabel,inputType,btnText,onSubmit}) => {
    const initialValues = {
        [inputType]:"",
        password:""
    }
    const {handleChange,handleBlur,handleSubmit,handleReset:reset,errors,touched,values} = useFormik({
        initialValues,
        validationSchema:loginSchema(inputType),
        onSubmit: (values) => {
          //Here we will call onSubmit function which is coming from props
          onSubmit(values)
        },
        
    })
    function Copyright(props: any) {
      return (
        <Typography variant="body2" color="white" align="center" {...props}>
          {'©Copyright RBAL LLC '}  
          {' '}
       
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );
    }
  return (
<>
<Box  component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
<TextField
  sx={styles}
  autoFocus={false}
  margin="normal"
  required
  fullWidth
  id="email"
  label="Email Address"
  autoComplete="off"
  type='text'
  placeholder={userLabel}
  variant="filled"
  // value={values[inputType]}
  name={inputType}
  onBlur={handleBlur}
  onChange={handleChange}
/>
{touched[inputType] && errors[inputType] ? <span style={{color:'white'}}>{errors[inputType]}</span> : null}
<TextField
  required
  sx={styles}
  autoFocus={false}
   margin="normal"
   fullWidth
   name="password"
   label="Password" 
   type="password"
   id="password"
   variant="filled"
   autoComplete="off"
   onBlur={handleBlur}
   onChange={handleChange}
/>

{touched.password && errors.password ? <span style={{color:'white'}}>{errors.password}</span> : null}
<Button
  type="submit"
  fullWidth
  variant="contained"
  sx={{ mt: 3, mb: 2 }}
>
  Sign In
</Button>
<Copyright sx={{ mt: 5 }} />
</Box>
</>

  )
}
export default GeneralizedLogin;

