import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Box } from "@mui/system";
import { Button, Tooltip } from "@mui/material";
import { addSubredditManager } from "../../app/content/subredditManager";
import { UpvoteBoosterResponse } from "../form/type";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

interface SubredditPostResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  communityId: string;
  subRedditName: string;
  createdBy: string;
  updatedBy: string | null;
}

const SubredditForm = ({
  data,
  setOpenAlert,
  setMessage,
  setSeverity,
  fetchData,
}) => {
  const [formLoad, setFormLoad] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Subreddit name is required.")
        .min(2, "Subreddit name must be at least 2 characters.")
        .max(50, "Subreddit name must be 50 characters or less."),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFormLoad(true);
      const payload = {
        communityId: data.id,
        subredditName: values.name,
      };

      try {
        const response: UpvoteBoosterResponse<SubredditPostResponse> =
          await addSubredditManager(payload);

        if (response?.code === 200) {
          setSeverity("success");
          fetchData();
          setMessage(response?.message || "Record Added Successfully.");
          resetForm();
          setOpenAlert(true);
        }
      } catch (err) {
        setSeverity("error");
        setMessage("Unable to create subreddit.");
        setOpenAlert(true);
      } finally {
        setFormLoad(false);
      }
    },
  });

  return formLoad ? (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress size={20} />
    </Box>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <input
          id="name"
          name="name"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          style={{ flexGrow: 1 }}
        />
        <Tooltip title={formik?.values?.name?.length ? "Submit" : "Create"}>
          <Button type="submit" sx={{ border: "none" }}>
            {formik?.values?.name?.length > 0 ? (
              <CheckIcon fontSize="small" />
            ) : (
              <ControlPointOutlinedIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      </Box>
      {formik.touched.name && formik.errors.name ? (
        <div style={{ color: "red", fontSize: "10px" }}>
          {formik.errors.name}
        </div>
      ) : null}
    </form>
  );
};

export default SubredditForm;
