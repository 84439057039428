import React, { useEffect, useState } from "react";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Layout } from "../../layout/Layout";
import { AlertColor, Button } from "@mui/material";
import NotificationSchedulerGeneralisedComponent from "./NotificationSchedulerGeneralisedComponent";
import { Type } from "../../enum/index";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfirmationPopup from "../Modal/ConfirmationPopup";
import { NotificationSchedulerResponse, deleteScheduleNotification, getJobScheduled } from "../../app/notificationScheduler/NotificationScheduler";
import { DeleteResponse } from "../../app/reportedComments/reportedComments";
import Toaster from "../Toaster/Toaster";

export default function NotificationScheduler() {
  const [addModal, setAddModal] = useState<boolean>(false);
  const [operationType, setOperationType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectDelete, setSelectDelete] = useState("");
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [data,setData]= useState<Array<NotificationSchedulerResponse>>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })

  const closeModal = () => {
    setAddModal(false);
  };

  const getScheduleNotificationHandler = async () => {
    setDataLoading(true);
    try {
      const response = await getJobScheduled(pagination);
      setRowCount(response?.totalRecord || 0)
      response.list = response.list?.map((data) =>{ return {...data , dateTime:new Date(data.dateTime).toLocaleString('en-US', {hour12: true, hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric'})}})
      setData(response?.list || []);
   
    } catch (err: any) {
      setSeverity("error");
      setOpenAlert(true);
      setMessage(err?.message || "Something went wrong !");
    } 
    finally{
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getScheduleNotificationHandler()
  }, [pagination]);

  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }
  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "body",
      headerName: "Body",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "dateTime",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params: GridRowParams) => {
        return [
          <Button
            onClick={() => {
              setSelectDelete(params?.row?.id);
              setOpenConfirm(true);
            }}
            variant="contained"
            size="small"
            color="error"
            endIcon={<HighlightOffIcon />}
          >
            Cancel
          </Button>,
        ];
      },
    },
  ];
  return (
    <Layout title="Schedule Notification">
      <div
        style={{
          textAlign: "right",
          marginBottom: "10px",
          outline: "none",
        }}
      >
        {" "}
        <Button
          onClick={() => {
            setAddModal(true), setOperationType(Type.Add);
          }}
          variant="contained"
        >
          Schedule Notification
        </Button>
      </div>
      <NotificationSchedulerGeneralisedComponent
        operationType={operationType}
        columns={columns}
        closeModal={closeModal}
        addModal={addModal}
        data={data}
        dataLoading={dataLoading}
        getScheduleNotificationHandler={getScheduleNotificationHandler}
        rowCount={rowCount}
        pagenated={pagenated}
      />
      <ConfirmationPopup
        description={"you want to cancel this schedule notification."}
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
          setSelectDelete("");
        }}
        handleConfirm={async () => {
          const response: DeleteResponse = await deleteScheduleNotification(
            selectDelete
          );
          if (response?.code === 200) {
            setSeverity("success");
            setOpenAlert(true);
            getScheduleNotificationHandler();
            setMessage(
              response?.message || "Schedule Notification Cancel Successfully."
            );
          } else {
            setOpenAlert(true);
            setSeverity("error");
            setMessage(
              response?.message || "Unable to cancel schedule notification."
            );
          }
          setSelectDelete("");
          setOpenConfirm(false);
        }}
        btn1="Yes,I want to cancel"
        btn2="No,I want to keep"
      />
      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
    </Layout>
  );
}
