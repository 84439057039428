import React, { useRef, useState } from "react";
import { Modal, Typography, Button, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  twoFAResponse,
  twoFactorAuthenticationOTPVerification,
  twoFactorAuthenticationOTPVerificationPayload,
} from "../app/auth/twoFactorAuth";
import { twoFactorAuthenticationStatus } from "./TwoFactorAuthenticationSlice";
import { useAppDispatch } from "../app/hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

const OTPPopup = ({ open, handleClose, email }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const inputsRef = useRef<any>([]);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleChange = (e, index) => {
    setError("");
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    setError("");
    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
  };

  const renderOtpFields = () =>
    otp?.map((_, index) => (
      <TextField
        key={index}
        value={otp[index]}
        onChange={(e) => handleChange(e, index)}
        onKeyDown={(e) => handleKeyDown(e, index)}
        size="small"
        inputProps={{
          style: { textAlign: "center", width: "10px" },
          maxLength: 1,
        }}
        inputRef={(el) => (inputsRef.current[index] = el)}
        sx={{ mr: "5px" }}
      />
    ));

  const handleSubmit = async () => {
    setLoading(true);
    const otpCode = otp?.join("");
    if (otpCode?.length === 6) {
      try {
        const payload: twoFactorAuthenticationOTPVerificationPayload = {
          email: email,
          totpKey: otpCode,
        };

        const response: twoFAResponse<boolean | null> =
          await twoFactorAuthenticationOTPVerification(payload);

        if (response?.status) {
          dispatch(
            twoFactorAuthenticationStatus({
              isQrGenerated: true,
              is2FaEnabled: true,
              is2FaVerified: true,
            })
          );

          navigate("/users");
        } else {
          setError(response?.message || "Enter valid OTP");
        }
      } catch (error: any) {
        setLoading(false);
        setError(error?.message || "something went wrong");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError("Enter 6 digits valid OTP.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
    >
      <Grid container sx={style}>
        <Grid item xs={12} sx={{ mb: "20px" }}>
          <Typography sx={{ fontWeight: 500, textAlign: "center" }}>
            {" "}
            Two-Factor Authentication(2FA) Code
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderOtpFields()}
        </Grid>
        {error && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", p: "5px" }}
          >
            <Typography sx={{ color: "red" }}> {error}</Typography>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "20px",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {loading ? "loading..." : "Verify Code"}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default OTPPopup;
