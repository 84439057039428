import * as Yup from 'yup';

export const nameAndDescSchema = Yup.object({
    name: Yup.string()
      .min(3,'Name must be at least of 3 characters')
      .matches(/^[A-Za-z][A-Za-z ]*$/, 'Name must start with alphabetic character and only contain alphabets')
      .required('Name is required'),
      description:Yup.string()
      .min(3,'Description must be at least 3 characters')
      .matches(/^[A-Za-z][A-Za-z ]*$/, 'Description must start with alphabetic character and only contain alphabets')
      .required('Description is required '),
});

export const nameSchema = Yup.object({
    name: Yup.string()
    .min(3,'Name must be at least of 3 characters')
      .matches(/^[A-Za-z][A-Za-z ]*$/, 'Name must start with alphabetic character and only contain alphabets')
      .required('Name is required'),
});

export const EditSchema = Yup.object({
  title: Yup.string()
  .min(3,'Title must be at least of 3 characters')
    .matches(/^[A-Za-z][A-Za-z ]*$/, 'Tile must start with alphabetic character and only contain alphabets')
    .required('Title is required'),
  ans:Yup.string().required("Content is required")
});