import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppDispatch } from '../app/hooks';
import { loginAction } from '../app/auth/auth';
import GeneralizedLogin from '../components/login/GeneralizedLogin';
import Toaster from '../components/Toaster/Toaster';
import { AlertColor } from '@mui/material';
import { LoginPayload } from '../../types/login'
import { useNavigate } from "react-router-dom";
import Loader from '../components/Loader/Loader';
// import logo from '../images/full-logo.webp';
import logo from '../images/netwrk.png'
import { twoFactorAuthenticationEnabledCheck, twoFactorAuthenticationEnabledCheckResponse, twoFAResponse } from '../app/auth/twoFactorAuth';
import { twoFactorAuthenticationStatus } from './TwoFactorAuthenticationSlice';



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignInSide() {
  const dispatch = useAppDispatch();
  const [loading , setLoading] = React.useState<boolean>(false);
  const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity , setSeverity] = React.useState('');
  
    const navigate = useNavigate();

    const check2FAEnabled =async(email:string)=>{
   const response:twoFAResponse<twoFactorAuthenticationEnabledCheckResponse> = await twoFactorAuthenticationEnabledCheck(email)

   dispatch(twoFactorAuthenticationStatus({...response?.data,is2FaVerified:false}));

   if(response?.data?.is2FaEnabled){
         navigate("/authenticate", {state:{...response?.data,email:email}})
  }
  else{
     navigate("/users")
  }

}


  function handleSubmit (values : any ) {
    setLoading(true)
        dispatch(loginAction(values))
        .unwrap()
        .then(() => {
          setLoading(false)
          check2FAEnabled(values?.email);
        })
        .catch((err) =>{
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
        })
    
      }
function handleCloseAlert(){
    setOpenAlert(false)
}
  return (
    <>
     <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid style={{backgroundColor:"#3775e6" ,color:'white'}} item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
            <img src={logo} width='60%'  alt='Your Logo' />
            {/* </Avatar> */}
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <GeneralizedLogin textInputPlaceholder="Enter the username" passwordInputPlaceholder="Enter the password" title="Login" userLabel='Email' passwordLabel='Password' btnText='submit' inputType='email' onSubmit={handleSubmit}/>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    <Loader loading={loading}/>
   
    <Toaster handleClose={handleCloseAlert} severity={severity as AlertColor} open={openAlert} message={message}/>
    </>
   
  );
}