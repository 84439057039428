import React from 'react';
import {  GridColDef, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Layout } from '../../../../layout/Layout';
import Button from '@mui/material/Button';
import ApprovedUserGeneralisedComponent from './ApprovedUserGenric';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function ApprovedUsers() {
  const [addModal, setAddModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [toggleStatus , setToggleStatus] = React.useState("")
  const [userId , setUserId] = React.useState("")
  const [editUserDetails , setEditUserDetails] = React.useState<any>()

  React.useEffect(() =>{
    setToggleStatus("")
  },[changeStatus])

  const columns: GridColDef[] = [
    { field: 'full_NAME', headerName: 'Full-Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
    { field: 'photo_URL',type:"actions", headerName: 'Logo',  width:10, flex: 1,renderCell: (params) => <Stack direction="row" spacing={2}>
    <Avatar alt="Remy Sharp" src={params ? params.value : ""} />
  </Stack> },
  ]
  function changeStatus(status:string){
    status === 'Active' ? setToggleStatus('Deactivate') : setToggleStatus('Active')
  }
 
  const openAddModal = () => {
    setAddModal(!addModal)
  }

  function deleteUser(userId: string) {
    setUserId(userId)
    setDeleteModal(true)
  }

  function editUser(row:any) {
    setEditModal(true)
    setEditUserDetails(row)
  }

  const closeModal = () => {
    setAddModal(false);
    setDeleteModal(false)
    setEditModal(false)
  };

  return (
    <>
      <Layout  title="Approved Users">
        <div>
        {/* <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => {setAddModal(true)}} variant="contained">Upload</Button></div> */}
        <ApprovedUserGeneralisedComponent userId={userId}  editDetails={editUserDetails} status={toggleStatus} columns={columns} editUserModal={editModal} addUserModal={addModal} deleteUserModal={deleteModal}  closeModal={closeModal}/>
        </div>
          
      </Layout>
    </>
  );
}