import * as Yup from 'yup';
interface FileWithSize extends File {
  size: number;
}
export const uploadSchema = Yup.object({
    myFile: Yup.mixed()
    .nullable()
   .required('File is required')
   .test('fileType', 'Only CSV is allowed', (value) => {
    if (!value) return true; // Allow empty values?
    return ["text/csv"].includes(value && (value as FileWithSize).type);
  }),
});
     