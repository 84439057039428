import React, { useState, useEffect , useRef} from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { AlertColor } from '@mui/material';
import './scrollbar.css'
import boy from '../../Assets/Images/anonymous.png'
import secondBoy from '../../Assets/Images/boySecond.jpg'
import deleteUser from '../../Assets/Images/delete-user.png'
import blockUser from '../../Assets/Images/block-user.png'
import Tooltip from '@mui/material/Tooltip';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form, Field, ErrorMessage , useFormik } from 'formik';
import { Box } from "@mui/system";
import { updateHasMessage } from "../../app/seeders/seeders";
import { sendSingleNotification , checkChat } from "../../app/seeders/seeders";
import './message.css'
import { CheckChatData } from "../../app/seeders/seeders";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBTypography,
  MDBTextArea,
  MDBCardHeader,
  MDBInputGroup
} from "mdb-react-ui-kit";
import Loader from '../Loader/Loader';
import Toaster from '../Toaster/Toaster';
import GenericForm from "../form/GenericForm";
import { getSeederAction , getSeederMessages} from "../../app/seeders/seeders";
import {db} from '../../firebase'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { doc, setDoc , addDoc , collection , updateDoc , getDoc , onSnapshot , query , getDocs , Unsubscribe , orderBy , deleteDoc} from "firebase/firestore";
import { setToken } from "../../app/auth/auth";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function FriendList() {
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [seederMessages,setSeederMessages ] = useState([]);
  const [chats , setChats] = useState<any>([]);
  const [chatStatus , setChatStatus] = useState<CheckChatData >();
  const [seeders, setSeeders] = useState<any>([]);
  const [friendList, setFriendList] = useState<any>([]);
  const [selectedSeeder, setSelectedSeeder] = useState<any>([]);
  const [selectedFriend, setSelectedFriend] = useState<any>(null);
  const [formLoad , setFormLoad]= useState<boolean>(false);
  const [latestMessage , setLatestMessage]= useState<any>("");
  const [toggle , setToggle]= useState<boolean>();
  const [deleteOpenModal , setDeleteOpenModal]= useState<boolean>(false);
  const [isConversation , setIsConversation] = useState<string>('')
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [formKey, setFormKey] = React.useState(0);
  const [isChat, setIsChat] = React.useState<boolean>(false);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 300
  });
  const formik = useFormik({
    initialValues: {
      Message:""
    },
    onSubmit : async (values,{resetForm}) => {
      setFormLoad(true)
      await sendMessage(values)
      sendNotification(values)
      updateMessage()
      resetForm()
      setFormLoad(false)
    },
  });
  
  function hashGen(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) + hash) + str.charCodeAt(i); 
    }
    return hash >>> 0;
  }
  useEffect(() => {
    setLoading(true);
    getSeederMessages(pagination)
      .then((res) => {
        setLoading(false);
        if (!res.status) {
          setSeverity('error')
          setOpenAlert(true)
          setMessage(res?.message ? res.message : 'Request failed due to duplicity')
        } else {
          setSeederMessages(res?.data?.list ? res?.data?.list : [])
        }
      })
      .catch((err) => {
        setSeverity('error');
        setOpenAlert(true);
        setMessage(err ? err.message : "Error");
        setLoading(false);
      });
  }, [pagination , toggle]);
 
  // useEffect(() => {
  //   if (!selectedSeeder?.id) return;
  //   setIsConversation('')
  //   setLoading(true);

  //   // Define unsubscribe variables with proper types
  //   let unsubscribeUserDoc: Unsubscribe | null = null;
  //   let unsubscribeSubCollection: Unsubscribe | null = null;

  //   const snap = () => {
  //     try {
  //       const userDocRef = doc(db, "network_users", selectedSeeder?.id);
  //       unsubscribeUserDoc = onSnapshot(userDocRef, (userDocSnapshot) => {
  //         if (userDocSnapshot.exists()) {
  //           const subCollectionRef = collection(userDocRef, "friend_list")
  //           unsubscribeSubCollection = onSnapshot(subCollectionRef, (subCollectionSnapshot) => {
  //             const friends = subCollectionSnapshot.docs.map(subDoc => subDoc.data());
  //             friendList.length === 0 &&  setIsConversation('No conversation found')
  //             setFriendList(friends);
  //             setLoading(false); // Set loading to false after fetching friend list
  //           });

  //         } else {
  //           setIsConversation('No conversation found')
  //           setLoading(false); // Set loading to false if user document doesn't exist
  //         }
  //       });
  //     } catch (err) {
  //       console.error("Error fetching documents: ", err);
  //       setLoading(false); // Set loading to false in case of error
  //     }
  //   };

  //   snap();

  //   return () => {
  //     if (unsubscribeUserDoc) unsubscribeUserDoc();
  //     if (unsubscribeSubCollection) unsubscribeSubCollection();
  //   };
  // }, [selectedSeeder]);
  useEffect(() =>{
    if (selectedSeeder?.id) {
      const conditionFirst = `${hashGen(`${selectedSeeder?.from_CANDIDATE_ID}#AnonChat`) <= hashGen(`${selectedSeeder?.to_CANDIDATE_ID}#AnonChat`) ? `${selectedSeeder?.from_CANDIDATE_ID}#AnonChat_${selectedSeeder?.to_CANDIDATE_ID}#AnonChat` : `${selectedSeeder?.to_CANDIDATE_ID}#AnonChat_${selectedSeeder?.from_CANDIDATE_ID}#AnonChat`}`
      const conditionSecond = `${hashGen(`${selectedSeeder?.from_CANDIDATE_ID}`) <= hashGen(`${selectedSeeder?.to_CANDIDATE_ID}`) ? `${selectedSeeder?.from_CANDIDATE_ID}_${selectedSeeder?.to_CANDIDATE_ID}` : `${selectedSeeder?.to_CANDIDATE_ID}_${selectedSeeder?.from_CANDIDATE_ID}`}`
      const chatId = selectedSeeder?.is_SENDER_ALIAS  ? conditionFirst : conditionSecond;
      // const chatId =  selectedFriend?.is_anon_member ?`${selectedFriend?.member_id >= selectedSeeder?.id ?   `${selectedFriend?.member_id}_${selectedSeeder?.id}#AnonChat` : `${selectedSeeder?.id}#AnonChat_${selectedFriend?.member_id}` }`  : `${selectedFriend?.member_id >= selectedSeeder?.id ? `${selectedFriend?.member_id}`}`;
    const chatRef = doc(db, 'chats', chatId);
    // Reference to the messages collection within the chat document
    const messagesRef = collection(chatRef, 'messages');
    const q = query(messagesRef , orderBy("sent"))
    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, snapshot => {
      const messageList:any = [];
      snapshot.forEach(doc => {
        messageList.push({ id: doc.id,  ...doc.data()});
      });
      setChats(messageList ? messageList : [])
      
    })
    return () => {
      unsubscribe(); // Unsubscribe from the real-time updates when component unmounts
    }
    }else{
      return;
    }  
   
  }, [selectedSeeder]);
  useEffect(() => {
    scrollToBottom();
  }, [chats]);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };
  function sendNotification(values){
    setLoading(true);

    const payload = {
        body: "",
        chatMessage: values?.Message,
        groupId: null,
        receiverAlias: selectedSeeder?.is_RECEIVER_ALIAS ? true : false,
        receiverId: selectedSeeder?.to_CANDIDATE_ID  ? selectedSeeder?.to_CANDIDATE_ID  :    "",
        senderAlias: selectedSeeder?.is_SENDER_ALIAS ? true : false,
        senderId: selectedSeeder?.from_CANDIDATE_ID  ? selectedSeeder?.from_CANDIDATE_ID  :    "",  
        title: ""
      
    }
      //Call Api To Add User
       sendSingleNotification(payload)
        .then((res) =>{
        if(!res?.status){
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          // setMessage(res?.message ? res.message : 'Request failed due to duplicity')
        }else{
          setLoading(false)
          setSeverity('success')
          setOpenAlert(true)
          // setMessage(res?.message ? res.message :  "Successfully Added")
        }
        })
        .catch((err) =>{
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          
        })
  }
  function updateMessage(){
    setLoading(true);

    const payload = {
      id:selectedSeeder?.to_CANDIDATE_ID,
      hasMessage:true
      
    }
      //Call Api To Add User
      updateHasMessage(payload)
        .then((res) =>{
        if(!res?.status){
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          // setMessage(res?.message ? res.message : 'Request failed due to duplicity')
        }else{
          setLoading(false)
          setSeverity('success')
          setOpenAlert(true)
          // setMessage(res?.message ? res.message :  "Successfully Added")
        }
        })
        .catch((err) =>{
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          
        })
  }
  async function checkChatStatus(senderId:string , receiverId:string , isUserAnon:boolean){
    setLoading(true);
    checkChat(senderId,receiverId , isUserAnon)
      .then((res) => {
        setLoading(false);
        if (!res.status) {
          setSeverity('error')
          setOpenAlert(true)
          setMessage(res?.message ? res.message : 'Request failed due to duplicity')
        } else {
          setChatStatus(res?.data && res?.data )
        }
      })
      .catch((err) => {
        setSeverity('error');
        setOpenAlert(true);
        setMessage(err ? err.message : "Error");
        setLoading(false);
      });
  }
   function handleClick(seeder: any) {
    const conditionFirst = seeder?.is_SENDER_SEEDER ?  seeder?.from_CANDIDATE_ID : seeder?.to_CANDIDATE_ID
    const conditionSecond = seeder?.is_RECEIVER_SEEDER  ?   seeder?.from_CANDIDATE_ID : seeder?.to_CANDIDATE_ID

     checkChatStatus(conditionFirst , conditionSecond , seeder?.is_RECEIVER_ALIAS)
     .then(() =>{
      setSelectedSeeder(seeder ? seeder : [])
      setIsChat(true)
     })
     .catch((err) =>{
      setSeverity('error');
      setOpenAlert(true);
      setMessage(err ? err.message : "Error");
      setLoading(false);
     })
   
  }
  function timeAgo(timestamp: number): string {
    const now = Date.now();
    const timeDiff = now - timestamp;

    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;

    if (timeDiff < msInMinute) {
      return "now";
    } else if (timeDiff < msInHour) {
      const minutes = Math.floor(timeDiff / msInMinute);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (timeDiff < msInDay) {
      const hours = Math.floor(timeDiff / msInHour);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (timeDiff < 2 * msInDay) {
      return "yesterday";
    } else {
      const days = Math.floor(timeDiff / msInDay);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }
  function relativeTime(timestamp: string): string {
    const now: Date = new Date();
    const pastDate: Date = new Date(timestamp);
    const diffInSeconds: number = Math.floor((now.getTime() - pastDate.getTime()) / 1000);

    const secondsInMinute: number = 60;
    const secondsInHour: number = 3600;
    const secondsInDay: number = 86400;
    const secondsInMonth: number = 2592000;
    const secondsInYear: number = 31536000;

    if (diffInSeconds < secondsInMinute) {
        return 'just now';
    } else if (diffInSeconds < secondsInHour) {
        const minutes: number = Math.floor(diffInSeconds / secondsInMinute);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInDay) {
        const hours: number = Math.floor(diffInSeconds / secondsInHour);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInMonth) {
        const days: number = Math.floor(diffInSeconds / secondsInDay);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInYear) {
        const months: number = Math.floor(diffInSeconds / secondsInMonth);
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
        const years: number = Math.floor(diffInSeconds / secondsInYear);
        return `${years} year${years > 1 ? 's' : ''} ago`;
    }
}
function close(){
  setDeleteOpenModal(false)
}

  async function sendMessage(values:any ){
    // setLoading(true)
    try{
      const now = new Date();
      const milliseconds = now.getTime();
      const conditionFirst = `${hashGen(`${selectedSeeder?.from_CANDIDATE_ID}#AnonChat`) <= hashGen(`${selectedSeeder?.to_CANDIDATE_ID}#AnonChat`) ? `${selectedSeeder?.from_CANDIDATE_ID}#AnonChat_${selectedSeeder?.to_CANDIDATE_ID}#AnonChat` : `${selectedSeeder?.to_CANDIDATE_ID}#AnonChat_${selectedSeeder?.from_CANDIDATE_ID}#AnonChat`}`
      const conditionSecond = `${hashGen(`${selectedSeeder?.from_CANDIDATE_ID}`) <= hashGen(`${selectedSeeder?.to_CANDIDATE_ID}`) ? `${selectedSeeder?.from_CANDIDATE_ID}_${selectedSeeder?.to_CANDIDATE_ID}` : `${selectedSeeder?.to_CANDIDATE_ID}_${selectedSeeder?.from_CANDIDATE_ID}`}`
      const chatDocRef = doc(db, 'chats', selectedSeeder?.is_SENDER_ALIAS ? conditionFirst : conditionSecond )
      const subcollectionRef = collection(chatDocRef, 'messages');
      const messageDocRef = doc(subcollectionRef,`${milliseconds ? milliseconds : ""}`)
      const messageDocRefSnapShot = await getDoc(messageDocRef)

      const data = {
        toId:selectedSeeder?.is_SENDER_SEEDER ? selectedSeeder?.from_CANDIDATE_ID : selectedSeeder?.to_CANDIDATE_ID, // Riahna ID - Normal chat (Normal Id), Anon Chat (ID#AnonChat)
        msg: values?.Message, // Hi, How are you?
        read:"",
        type: 'text', // text
        fromId:selectedSeeder?.is_SENDER_SEEDER ? selectedSeeder?.is_SENDER_ALIAS ?  `${selectedSeeder?.from_CANDIDATE_ID}#AnonChat` :         selectedSeeder?.from_CANDIDATE_ID : selectedSeeder?.to_CANDIDATE_ID, // Neha ID - Normal chat (Normal Id), Anon Chat (ID#AnonChat)
        sent: milliseconds ? `${milliseconds}` : "" // string me timestamp
      };
      let messageDocUpdated = false;
      if (messageDocRefSnapShot.exists()) {
        // The document exists, so update it
        await setDoc(messageDocRef, data, { merge: true })
        messageDocUpdated = true;
        setToggle(!toggle)
      } else {
        // The document does not exist, so create it
        await setDoc(messageDocRef, data);
        messageDocUpdated = true;
        setToggle(!toggle)
      }
      if (messageDocUpdated) {
        // const seeder = doc(db, 'network_users', conditionSecond);
        // const friend = doc(db, 'network_users', conditionSecond); 
        // const seederSubcollectionRef = collection(seeder, 'friend_list'); // Adjust 'friend_list' to your subcollection name
        // const friendSubcollectionRef = collection(friend, 'friend_list'); 
        // const seederDocRef = doc(seederSubcollectionRef,conditionSecond);// Adjust 'friend_list' to your subcollection name
        // const friendDocRef = doc(friendSubcollectionRef,conditionSecond);
        // // Define data for subcollections
        // const seederSubcollectionData = {
        //   last_message_text:values ? values?.Message : "",
        //   last_message_time:milliseconds ? String(milliseconds) : ""
        //   // Subcollection data for seeder
        // };
  
        // const friendSubcollectionData = {
        //   last_message_text:values ? values?.Message : "",
        //   last_message_time:milliseconds ? String(milliseconds) : ""
        //   // Subcollection data for friend
        // };
  
        // // Update subcollections
        // await setDoc(seederDocRef, seederSubcollectionData, { merge: true });
        // await setDoc(friendDocRef, friendSubcollectionData, { merge: true });
      }
      setSeverity('success')
      setOpenAlert(true)
      setMessage('Message sent successfully')
    }catch(error:any){
      setSeverity('error')
      // setOpenAlert(true)
      // setMessage(error?.message)
      console.error('Error writing document: ', error.message);
    }finally {
      setFormKey(prevKey => prevKey + 1);
      // setLoading(false);
    }
  }
  async function deleteFromSeederFriendList(){
    setLoading(true)
    close()
    try {
      const conditionFirst = selectedSeeder?.is_SENDER_SEEDER ?  selectedSeeder?.from_CANDIDATE_ID : selectedSeeder?.to_CANDIDATE_ID
      const conditionSecond = selectedSeeder?.is_SENDER_SEEDER ? selectedSeeder?.is_SENDER_ALIAS  ? `${selectedSeeder?.to_CANDIDATE_ID}#AnonChat` : selectedSeeder?.to_CANDIDATE_ID  : selectedSeeder?.is_RECEIVER_ALIAS ? `${selectedSeeder?.from_CANDIDATE_ID}#AnonChat` : selectedSeeder?.from_CANDIDATE_ID
      // Reference to the specific document in the 'friends' sub-collection
      const friendDocRef = doc(db, `network_users/${conditionFirst}/friend_list/${conditionSecond}`);
 
      // Delete the document
        const result =  await deleteDoc(friendDocRef);
        setLoading(false)
        const First = selectedSeeder?.is_SENDER_SEEDER ?  selectedSeeder?.from_CANDIDATE_ID : selectedSeeder?.to_CANDIDATE_ID
        const Second = selectedSeeder?.is_RECEIVER_SEEDER  ?   selectedSeeder?.from_CANDIDATE_ID : selectedSeeder?.to_CANDIDATE_ID
        checkChatStatus(First , Second , selectedSeeder?.is_RECEIVER_ALIAS)
      
      console.log("Friend document successfully deleted!" );
    } catch (error) {
      setLoading(false)
      console.error("Error removing friend document: ", error);
    }
  }
  return (
    <>
      <MDBContainer fluid className="py-5 gradient-custom" style={{height:'76vh' , borderRadius:'30px', padding:'30px'}}>
      <MDBRow style={{height:'100%'}}>
   {/*  {isChat && chatStatus?.isFirebase &&  <div className="text-white " onClick={() => setDeleteOpenModal(true)} style={{textAlign:'right' , cursor:'pointer'}}><Tooltip title="Delete conversation"><DeleteForeverIcon/></Tooltip></div>}*/}
        <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0 custom-scrollbar" style={{height:'90%' , borderRadius:"30px" }}>
         <div  className="custom-scrollbar" style={{display:'flex',justifyContent:'space-between' , alignItems:'center'}}>
          <h1 className="font-weight-bold mb-3  text-white">
            Messages
          </h1>
          <DriveFileRenameOutlineIcon style={{fontSize:'40px'}} className="text-white font-weight-bold" />
         </div>
          <MDBCard style={{height:'88%', overflow:'auto' , borderRadius:'30px'}} className="mask-custom custom-scrollbar">
            <MDBCardBody>
             <div 
              className="custom-scrollbar"
              style={{
                position: "relative",
                height: '90% !important',
                overflow: "auto",
              }}
             >
             {  <MDBTypography className="custom-scrollbar mb-0" listUnStyled >
                         {seederMessages?.map((seeder:any) =>{
                          return  <li
                          className="p-2 border-bottom"
                          onClick={() => handleClick(seeder)}
                          style={{
                            borderBottom: "1px solid rgba(255,255,255,.3) !important",
                            cursor:'pointer',
                            height:'60%'
                          }}
                        >
                          <a
                           style={{flexDirection:'column'}} 
                            className="d-flex justify-content-between link-light"
                          >
                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} className="d-flex flex-row align-items-center">
                              <div style={{display:'flex' , alignItems:'center'}}>
                              <Stack direction="row" spacing={2}>
                      <Avatar className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" sx={{ width: 50, height: 50 }} alt="Remy Sharp" src={!seeder?.is_SENDER_SEEDER ? seeder?.sender_PHOTO : seeder?.receiver_PHOTO} />
                    </Stack>
                    <p className="fw-bold mb-0">{!seeder?.is_SENDER_SEEDER ?    seeder?.sender_NAME?.replace(/(^\w{1})|(\s+\w{1})/g, (letter:string) => letter?.toUpperCase()) : seeder?.receiver_NAME?.replace(/(^\w{1})|(\s+\w{1})/g, (letter:string) => letter?.toUpperCase())}</p>
                  
                              </div>
                              <div >
                              <p className="small mb-1 text-white">{relativeTime(seeder?.creation_DATE)}</p>
                              </div>
    
                            </div>
                              <div style={{display:'flex',justifyContent:'space-between'}} className="pt-1">
                                <p className="small text-white">{seeder?.last_MESSAGE ? seeder?.last_MESSAGE : ""}</p>
                                <div style={{display:'flex',  alignItems:'center'}}>
                                <Stack direction="row" spacing={2}>
                      <Avatar className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={!seeder?.is_RECEIVER_SEEDER ? seeder?.sender_PHOTO : seeder?.receiver_PHOTO} />
                    </Stack>
                    <p style={{fontWeight:'500' , fontSize:'13px'}} className="mb-0">{!seeder?.is_RECEIVER_SEEDER ? seeder?.sender_NAME?.replace(/(^\w{1})|(\s+\w{1})/g, (letter:string) => letter?.toUpperCase()) : seeder?.receiver_NAME?.replace(/(^\w{1})|(\s+\w{1})/g, (letter:string) => letter?.toUpperCase())}</p>

                                </div>
                              </div>
                            
                            {/* <div className="pt-1">
                              <p className="small mb-1 text-white">Just now</p>
                              <span className="badge bg-danger float-end">1</span>
                            </div> */}
                          </a>
                        </li>
                         })}
                        </MDBTypography> }  
             </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol md="6" lg="7" xl="8" style={{ height:'100%' , position:'relative'}}>
          <MDBTypography listUnStyled className=" custom-scrollbar  text-white  pt-3 pe-3" style={{height:"60%",maxHeight:'68%',overflowY:'auto'}}>
  
  {chatStatus?.isBlocked ? <div style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center' ,flexDirection:'column'}}>
    <Stack direction="row" spacing={2}>
        <Avatar className="rounded-circle d-flex align-self-start me-3 shadow-1-strong" sx={{ width: 50, height: 50 }} alt="Remy Sharp" src={blockUser} />
      </Stack>
      <p>This user is blocked</p>
      </div> : chats.length > 0 && chats.map((chat:any) => (
 <>
   <li key={chat?.id} className={`d-flex ${chat?.fromId === (selectedSeeder?.is_SENDER_SEEDER ? selectedSeeder?.is_SENDER_ALIAS ? `${selectedSeeder?.from_CANDIDATE_ID}#AnonChat` :selectedSeeder?.from_CANDIDATE_ID : selectedSeeder?.to_CANDIDATE_ID) ? 'justify-content-end':'justify-content-start' } mb-4`}>
      {chat?.fromId !== selectedSeeder?.to_CANDIDATE_ID && (
        <Stack direction="row" spacing={2}>
        <Avatar className="rounded-circle d-flex align-self-start me-3 shadow-1-strong" sx={{ width: 50, height: 50 }} alt="Remy Sharp" src={selectedSeeder?.sender_PHOTO} />
      </Stack>
      )}
      <MDBCard className="mask-custom" style={{maxWidth:'300px' , borderRadius:'30px'}}>
        <MDBCardHeader
          className="d-flex justify-content-between p-3"
          style={{ borderBottom: "1px solid rgba(255,255,255,.3)" }}
        >
          <p className="fw-bold mb-0">{chat?.fromId !== (selectedSeeder?.is_SENDER_ALIAS ? `${selectedSeeder?.to_CANDIDATE_ID}#AnonChat` : selectedSeeder?.to_CANDIDATE_ID) ?  selectedSeeder?.sender_NAME : selectedSeeder?.receiver_NAME}</p>
          <p className="text-light small mb-0 px-3">
            <MDBIcon far icon="clock" />   {timeAgo(chat?.sent)}
          </p>
        </MDBCardHeader>
        <MDBCardBody style={{padding:'10px 24px !important'}} >
          <p  className="mb-0">
            {chat?.msg}
          </p>
        </MDBCardBody>
      </MDBCard>
      {chat?.fromId === selectedSeeder?.to_CANDIDATE_ID && (
        <Stack direction="row" spacing={2}>
      <Avatar className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" sx={{ width: 50, height: 50 }} alt="Remy Sharp" src={selectedSeeder?.receiver_PHOTO} />
    </Stack>
      )}
    </li>
    <div ref={messagesEndRef} />
 </>
                    ))}
          </MDBTypography >
          { formLoad ? <Box

      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress size={20} />
    </Box> : chatStatus?.isFirebase ? chats.length > 0 && !chatStatus?.isBlocked &&   <form  onSubmit={formik.handleSubmit}>
            <MDBTypography listUnStyled>
              <div className="form-outline form-white" style={{position:'relative',bottom:'0px', width:'97%',maxHeight:'30%',}}>
              <li className="mb-3 ">
              <MDBTextArea  
                id="Message"
                name="Message" 
                label="Message"
                className={`form-outline form-control`}
               onBlur={formik.handleBlur}
               onChange={formik.handleChange} 
               values={formik.values.Message}
               outlineColor="white"
        rows={4} />
            </li>
            <MDBBtn  color="light" size="lg" rounded className="float-end mb-10">
              Send
            </MDBBtn>
              </div>
                
              </MDBTypography></form> : chatStatus?.isBlocked ? "" : !chatStatus?.isFirebase  && chats.length > 0 &&   <div style={{height:'30%',display:'flex',justifyContent:'flexEnd',}}><span style={{backgroundColor:"white", padding:"10px",textAlign:"center" , margin:'auto'}}>This account no longer exist</span></div>}
          
        </MDBCol>
      </MDBRow>
    </MDBContainer>
      <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
       <Dialog
        open={deleteOpenModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><img width="40px" src={deleteUser}/></DialogTitle>

        <DialogTitle>{`Delete conversation`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
       This will remove the conversation from your inbox, but not the other person
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteFromSeederFriendList}>Delete</Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
