// UserCrudComponent.js
import React, { useState, useEffect } from 'react';
import DataGridTable from '../table/DataGridTable';
import GenericForm from '../form/GenericForm';
import Modal from '../Modal/Modal';
import {  GridColDef ,GridRowParams,GridActionsCellItem} from '@mui/x-data-grid';
import { uploadCSV, getUsers  } from '../../app/users/users';
import {Type} from '../../enum/index'
import { uploadSchema } from '../../schema/upload';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import { useAppDispatch } from '../../app/hooks';
import { logoutAdmin } from '../../app/auth/auth';
import UserForm from './UserForm';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { deleteUsers } from '../../app/users/users';
import DeleteModal from '../Modal/DeleteModal';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditUserForm from './EditUserForm';
import { resetSeeders, setCommunitie , setType } from '../../app/seeders/seeders';

interface UserProps{
    addUser?:() => void
    // columns : GridColDef[]
    addUserModal:boolean
    closeModal:() => void
    deleteUserModal:boolean
    editUserModal:boolean
    status:string
    editDetails:any | undefined
    userId:string
    createUser:boolean
    setCreateUser:any
}
interface myFile{
myFile:File

}
const UserGeneralisedComponent:React.FC<UserProps>  = ({ userId, editDetails,addUserModal,closeModal , deleteUserModal , editUserModal,status,createUser,setCreateUser}) => {

  const [users, setUsers] =  useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('');
  const [loading , setLoading] = React.useState<boolean>(false)
  const [deleteModal,setDeleteModal]= useState(false)
  const [selectedUser,setSelectedUser]=useState<any>()
  const [editModal,setEditModal]= useState(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [filteredValue,setFilteredValue]= useState<string>("");
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const dispatch = useAppDispatch();

  
  const initialValues = {
    myFile:undefined
 }

 const columns: GridColDef[] = [
  { field: 'fullName', headerName: 'Full-Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'email', headerName: 'Email', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'profilePhotoUrl', headerName: 'Logo',  width:10, flex: 1,renderCell: (params) => <Stack direction="row" spacing={2}>
  <Avatar alt="Remy Sharp" src={params ? params.value : ""} />
</Stack> },
{
  field: 'actions',
  headerName: 'Actions',
  type: 'actions',
  flex: 1,
  getActions: (params: GridRowParams) => {
    return [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        showInMenu
        onClick={() => {setSelectedUser(params?.row)
          setDeleteModal(true)
        }}
        />,
      <GridActionsCellItem
        icon={<ManageAccountsIcon />}
        label="Edit User"
        showInMenu  
        onClick={() => {setSelectedUser(params?.row)
          setEditModal(true)
          dispatch(setType('Edit'))
          dispatch(setCommunitie(params?.row?.communities))
        }}
      />,
    ];
  },
}
]

 const getUserDataHandler = async () => {
  setDataLoading(true);
  try {
    const response:any =
      await    getUsers(pagination , filteredValue);
      setUsers(response?.data?.list ||  [])
      setRowCount(response?.data?.totalCount || 0)
  } catch (err: any) {
    setSeverity("error");
    setOpenAlert(true);
    setMessage(err?.message || "Something went wrong !");
  } finally {
    setDataLoading(false);
  }
};


  useEffect(() => {
    getUserDataHandler();
  }, [status , pagination , filteredValue]);

 
  const upload = async(values:myFile) =>{
    //Call Api To Add User
    const formData = new FormData();
     formData.append('file', values['myFile']);

    uploadCSV(formData)
    .then((res) =>{
      if(res?.status === 207){
        setSeverity('error')
        setOpenAlert(true)
        setMessage("wrong data format in uploaded file see the correct data format in downloaded file")
        return  res?.data;
      }else if(res?.status === 200){
        setSeverity('success')
        setOpenAlert(true)
        setMessage(res ? res.data.message : "Successfully Uploaded")
      }
    })
    .then((blob) =>{
  if(blob){
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `error.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  }
    })
    .catch((err:any) =>{
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err?.message : "Error")
    })

    
    
      
   
  }


  const handleSubmit = (values:myFile, type:string) => {
    // Handle form submission here
    if(type === Type.Upload){
      closeModal()
      upload(values)
    }
    
  };

  const addModalFields = [
    {
      name: 'myFile',
      label: 'Upload CSV',
      type:'file'
      
    }
 ];
 function handleFilter(value:string){
  setFilteredValue(value ?  value : "")
 }
 function pagenated(pageNo:number , pageSize:number){
  setpagination(() =>{
    return {
      pageNo:pageNo,
      pageSize:pageSize
    }
  })
}
  return(
    <>
    <DataGridTable columns={columns} row={users} loading={dataLoading} handleFilter={handleFilter} pagenated={pagenated} rowCount={rowCount}/>
{/* { Add Modal } */}
     <Modal
        open={addUserModal}
        title="Upload File"
        onClose={closeModal}
        form={<GenericForm title='Upload File' validationSchema={uploadSchema} closeModal={closeModal} label='CSV File' type={Type.Upload} fields={addModalFields}  initialValues={initialValues}    onSubmit={handleSubmit}/>}
      />
       <Modal
        open={editModal}
        title="Edit User"
        onClose={()=>{
          dispatch(resetSeeders())
          setEditModal(false);
        }}
        form={<EditUserForm selectedUser={selectedUser} setOpenAlert={setOpenAlert} setMessage={setMessage} setSeverity={setSeverity} setLoading={setLoading} getUserDataHandler={getUserDataHandler} setEditModal={setEditModal}/>}
      />
        <Modal
        open={createUser}
        title="Add User"
        onClose={()=>{
          setCreateUser(false);
        }}
        form={<UserForm setAddUser={setCreateUser} setOpenAlert={setOpenAlert} setMessage={setMessage} setSeverity={setSeverity} setLoading={setLoading} getUserDataHandler={getUserDataHandler}/>}
      />
       <DeleteModal description={"you want to delete this user."} open={deleteModal} handleClose={()=>{
        setDeleteModal(false);
       }} handleDelete={async()=>{
     
          try{
    const response = await deleteUsers({id:selectedUser?.id})
    if(response?.code === 200){
      getUserDataHandler()
       setSeverity('success')
        setOpenAlert(true)
        setMessage(response?.message || "User Deleted Successfully.")
    }
   
  }
  catch(err:any){
    setSeverity("error");
    setOpenAlert(true);
    setMessage(err?.message || "Something went wrong !");
  }
  finally{
    setDeleteModal(false);
  }
      }} />
      <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>

</>
    
    
)


};

export default UserGeneralisedComponent;