import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TwoFactorAuthenticationState {
  isQrGenerated: boolean;
  is2FaEnabled: boolean;
  is2FaVerified: boolean;
}

export const twoFactorAuthenticationSlice = createSlice({
  name: "twoFactorAuthentication",
  initialState: {
    isQrGenerated: false,
    is2FaEnabled: false,
    is2FaVerified: false,
  } as TwoFactorAuthenticationState,
  reducers: {
    twoFactorAuthenticationStatus(
      state: TwoFactorAuthenticationState,
      action: PayloadAction<TwoFactorAuthenticationState>
    ) {
      return {
        ...state,
        isQrGenerated: action.payload.isQrGenerated,
        is2FaEnabled: action.payload.is2FaEnabled,
        is2FaVerified: action.payload.is2FaVerified,
      };
    },
  },
});

export const { twoFactorAuthenticationStatus } =
  twoFactorAuthenticationSlice.actions;
export const twoFactorAuthentication = (state: any) =>
  state.twoFactorAuthenticationReducer;
export default twoFactorAuthenticationSlice.reducer;
