export enum  FlexDirection {
    Column = 'column'
}

export enum Type {
    Add = "Add",
    Edit = "Edit",
    Upload = 'Upload'
}
export enum PromptTypes{
    BusinessBeyond = "Beyond Business",
    Prompt="Advice Needed",
    Assist="Reguler - No Tag",
    Event="Event"
}
export enum CommunityTypes{
    Public = "PUBLIC",
    Private="PRIVATE",
    Anonymous="ANON",

}
export enum PermissionsTypes{
    PostAndComments = "POST_AND_COMMENT",
    Posts="POST",
    Comments="COMMENT",

}
export enum boosterType{
    comment="COMMENT",
    post="POST"
}
export enum FeedSetting{
    Hot="Hot"
}