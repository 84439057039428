import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Typography } from "@mui/material";
import SubredditCard from "./SubredditCard";
import SubredditForm from "./SubredditForm";
import { getSubredditManager } from "../../app/content/subredditManager";
import { Box } from "@mui/system";
import Toaster from "../Toaster/Toaster";
import { AlertColor } from "@mui/material";
import Loader from "../Loader/Loader";
import { UpvoteBoosterResponse } from "../form/type";

const SubredditManager = () => {
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);

  interface SubredditResponse {
    size: number;
    list: any;
    totalCount: number;
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: UpvoteBoosterResponse<SubredditResponse> =
        await getSubredditManager();
      setData(response?.data?.list || []);
    } catch (err: any) {
      setSeverity("error");
      setMessage(err?.message || "error occurred.");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout title="Subreddit Manager">
      <Typography sx={{ fontWeight: "500", mb: "10px", fontSize: "22px" }}>
        Manage Subreddits
      </Typography>

      {data?.map((item: any) => (
        <Box key={item.id} sx={{ mb: "10px" }}>
          <SubredditCard
            data={item}
            setOpenAlert={setOpenAlert}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setLoading={setLoading}
            fetchData={fetchData}
          />
          <SubredditForm
            data={item}
            setOpenAlert={setOpenAlert}
            setMessage={setMessage}
            setSeverity={setSeverity}
            fetchData={fetchData}
          />
        </Box>
      ))}

      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
      <Loader loading={loading} />
    </Layout>
  );
};

export default SubredditManager;
