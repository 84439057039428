import React from 'react';
import Comment from './Comment';
import dayjs from 'dayjs';

// Type definition for individual comment data
export interface CommentData {
  ans: string | null;
  candidateLinkedInId: string;
  comment: string;
  commentFeedStatus: boolean;
  creation_date: string;
  cred_COUNT: number;
  dislikeCount: number;
  email: string;
  feedCommentCount: number;
  feedCommentResponseDtoResponse: {
    list?: CommentData[]; // Adjusted to handle nested replies in a structured way
  };
  fullName: string;
  id: string;
  isDeleted: boolean;
  isReported: boolean;
  isVerified: boolean;
  isdislike: boolean;
  islike: boolean;
  likeCount: number;
  mutual_interest: boolean;
  photoUrl: string;
  promptDetailsId: string;
  replyCount: number;
  totalCommentRating: number;
  updateDate: string;
  scheduledDateTime?: string; // Ensure this is included if you're using it for scheduling
}

// Type definition for user list data
interface ListData {
  aboutMe: string;
  anonymousName: string;
  communities: [];
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  isSeeder: boolean;
  lastName: string;
  photoUrl: string;
  totalRatingUser: number;
}

// Props for CommentList component
interface CommentListProps {
  comments:any;
  addReply: (commentId: string, replyText: string, username: string, aliasVal: boolean, seederId:any, date:any,photoUrl:any , checkDate:any) => void;
  userList: ListData[];
  deleteData: any
  updateComments: (commentId: string, newReply: CommentData) => void;
  editComment:any,
  handleScroll:(...any) => void,
  rowCount:number

}

// CommentList component
const CommentList: React.FC<CommentListProps> = ({
  comments,
  addReply,
  userList,
  deleteData,
  updateComments,
  editComment,
  handleScroll,
  rowCount
}) => {
  // Check if comments array is not empty and render Comment components
  return (
    <>
      {comments.length > 0 &&
        comments.map((comment) => (
          <Comment
            id={comment.id}
            key={comment.id}
            comment={comment}
            parentDate={comment?.date}
            addReply={addReply}
            updateComments={updateComments}
            userList={userList}
            deleteData={deleteData}
            editComment={editComment}
            handleScroll={handleScroll}
            rowCount={rowCount}
            // childDate={comment?.date}
          />
        ))}
    </>
  );
};

export default CommentList;
