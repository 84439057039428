import * as Yup from 'yup';
interface FileWithSize extends File {
    size: number;
  }
export const communityEditSchema = (name:string) =>{
  let schema;
  const enumValues = ['PRIVATE', 'PUBLIC', 'ANON'];
  if (name === "icon") {
   return  schema = Yup.object({
    myFile: Yup.mixed()
    .nullable()
   .required('Image is required')
   .test('fileType', 'Only JPG, PNG, and JPEG are allowed', (value) => {
    if (!value) return true; // Allow empty values?
    return ['image/jpg', 'image/jpeg', 'image/png'].includes(value && (value as FileWithSize).type);
  }), });
  } else if(name === 'desc') {
    return Yup.object({
      description: Yup.string()
          .matches(/^[a-zA-Z0-9 !@#$%^&*():";',.-]*$/, 'Enter valid description.')
          .transform(value => value.trim().replace(/\s+/g, ''))
          .required('Description is required'),
  });
  }
  else if(name === 'type') {
    return schema =  Yup.object({
      communityType: Yup.string()
        .required('Community type is required')
        .oneOf(enumValues, 'Invalid value')
        .test('anon-type', 'Cannot change type for anon community', function(value) {
          if (value === 'ANON') {
            return false; // Fail validation if trying to change from non-anon to anon
          }
          return true; // Pass validation for other cases
        }),
  
    });
}
}