import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem , GridEditInputCell } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { editCommunityMembersAction } from '../../app/community/community';
import EditCommunityMembersGenericComponent from './editCommunityMembersGeneric';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
export default function EditCommunityMembers() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [educationId, setEducationId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [editedRows, setEditedRows] = React.useState({});
    const [editEducationDetails , setEditEducationDetails] = React.useState<GridRowParams | null>(null)
    const [toggle , setToggle] = React.useState<boolean>(false);
    const [loading , setLoading] = React.useState<boolean>(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity , setSeverity] = React.useState('') 

const closeModal = () =>{
    setAddModal(false)
    setEditModal(false)
    setDeleteModal(false)
}
const editEducationModal = (params :GridRowParams) =>{
    setEditModal(true)
    setEditEducationDetails(params?.row)

}
const handleEditCellChange = (params , event) => {
    const { id, field, value } = params;
    // Check if the edited field is 'name' (Displayed Members)
    if (field === 'displayMemberCount' || event?.target?.value) {
      setEditedRows((prev) => ({
        ...prev,
        [id]: value !== '', // Mark the row as edited if value is not empty
      }));
    }
  };

const editCommunityMemberDisplayNumber = async(editDetails) =>{
    const payload = {
        id:editDetails?.row?.id,
        displayMemberCount:editDetails?.row?.displayMemberCount
    }
  setLoading(true);
  //Call Api To Edit User
editCommunityMembersAction(payload)
  .then((res) =>{
   if(!res?.status){
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(res?.message ? res.message : "Record not found")
   }else{
    setLoading(false)
    setSeverity('success')
    setOpenAlert(true)
    setMessage(res?.message ? res.message : "Successfully Updated")
    setToggle(!toggle)
   }
       
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : "Error")
    
  }).finally(() =>{
    setEditedRows({})
    setEditModal(true)
  })
}
const columns: GridColDef[] = [
  { field: 'communityName', headerName: 'Community', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'realMemberCount', headerName: 'Real Members', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'displayMemberCount', headerName: 'Displayed Members', headerAlign: 'left',align: 'left', flex: 1, editable: true,type: 'number',renderCell: (params) => (params.value ? params.value : 0)  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
        const isEdited = editedRows[params?.id] || false; // Check if the row has been edited
        return [
          <Button onClick={() => {editCommunityMemberDisplayNumber(params)}} variant="contained" disabled={!isEdited}>
            Save
          </Button>,
        ];
      },
  }

]
  return (
  <>
  <Layout  title="Edit Community Members">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}> </div>
        <EditCommunityMembersGenericComponent handleEditCellChange={handleEditCellChange} educationId={educationId}  deleteEducationModal={deleteModal} editDetails={editEducationDetails}  editEducationModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  />
    </div>
    <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </Layout>
  </>
  )
}
