import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getPostManagerAction } from '../../app/seeders/postManager';
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'ans', headerName: 'Description', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'fullName', headerName: 'Posted By', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'communityName', headerName: 'Community', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')},
  { field: 'creationDate', headerName: 'Posted On', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  // {
  //   field: 'actions',
  //   headerName: 'Actions',
  //   type: 'actions',
  //   flex: 1,
  //   getActions: (params: GridRowParams) => {
  //     return [
  //       <GridActionsCellItem
  //       icon={<MarkChatUnreadIcon />}
  //       label="Comments"
  //       showInMenu
  //       onClick={() => {commentModal(params)}}
  //       />,
  //       <GridActionsCellItem
  //         icon={<DeleteIcon />}
  //         label="Delete"
  //         showInMenu
  //         onClick={() => {deletePoliticsModal(params?.row?.id)}}
  //         />,
  //       <GridActionsCellItem
  //         icon={<EditIcon />}
  //         label="Edit"
  //         showInMenu
  //         onClick={() => { editPoliticsModal(params)} }
  //       />,
  //     ];
  //   },
  // }

]
export default function FullScreenDialog({openDialog , handlePostClose , handleSavePost }) {
  const [open, setOpen] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState<boolean>(false);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [severity , setSeverity] = React.useState<string>('');
  const [rows , setRows] = React.useState<any>([]);
  const [rowData , setRowData] = React.useState<any>([]);
  const [post , setPost] = React.useState<any>([]);
  const [rowCount,setRowCount]= React.useState<number>(0);
  React.useEffect(() => {
    setDataLoading(true);
    const param={
      pageNo:pagination.pageNo,
      pageSize:pagination.pageSize,
      seeder:false
    }
    getPostManagerAction(param)
      .then((res) => {
        if(!res?.status){
          setDataLoading(false);
        }else{
          setRowCount(res?.data?.totalCount || 0)
          setRows(res.data.list ? res.data.list : []);
          setDataLoading(false);
        }      
      })
      .catch((err) => {
        setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          setDataLoading(false);
          setDataLoading(false);
      });
  }, [pagination]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  function savePost(value){
    const getRowDataById = (id: string | number): any => {
      return rows.find(data => data?.id === id);
    };
    setRowData(getRowDataById(value[0]))
  }
  const handleClose = () => {
   handlePostClose()
  };
  function handleSave(){
  handleClose()
  handleSavePost(rowData ? rowData?.title : "" , rowData && [rowData])
  }
  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Posts
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DataGridTable savePost={savePost} singleRowSelection={true} checkboxSelection={true} columns={columns} row={rows} pagenated={pagenated} rowCount={rowCount}  loading={dataLoading} />
      </Dialog>
    </React.Fragment>
  );
}