import axios from "../../interceptor/axiosInterceptor";

interface twoFactorAuthenticationEnableMutatePayload {
  enabled: boolean;
  email: string;
}

export interface twoFAResponse<T> {
  message: string;
  code: number;
  data: T;
  status: boolean
}

export interface twoFactorAuthenticationEnableMutateResponse {
    id: string;
    createdAt: string;
    updatedAt: string;
    email: string;
    password: string;
    createdBy: string | null;
    updatedBy: string | null;
    secret: string;
    is2faEnabled: boolean;
    isQrGenerated: boolean;

}

export interface twoFactorAuthenticationEnabledCheckResponse {
 
    isQrGenerated: boolean;
    is2FaEnabled: boolean;

}

export interface twoFactorAuthenticationOTPVerificationPayload {
  email: string,
  totpKey: string,
}
 
export const twoFactorAuthenticationEnabledCheck = async (
  payload: string
): Promise<twoFAResponse<twoFactorAuthenticationEnabledCheckResponse>> => {
  try {
    const response = await axios.get(
      `2fa/isUser2faEnabled/${payload}`
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const twoFactorAuthenticationEnableMutate = async (
  payload: twoFactorAuthenticationEnableMutatePayload
): Promise<twoFAResponse<twoFactorAuthenticationEnableMutateResponse>> => {
  try {
    const response = await axios.post(
      `2fa/enabledUser2fa/${payload?.email}?enabled=${payload?.enabled}`
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const twoFactorAuthenticationOTPVerification = async (
  payload: twoFactorAuthenticationOTPVerificationPayload
): Promise<twoFAResponse<boolean | null>> => {
  try {
    const response = await axios.post(
      `2fa/qrcode/validate/${payload?.email}`,
      { totpKey: payload?.totpKey }
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const twoFactorAuthenticationGetQr = async (
  payload: string
): Promise<Blob> => {
  try {
    const response = await axios.get(
      `2fa/qrcode/get/${payload}`,
      { responseType: "blob" }
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
