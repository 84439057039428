import axios from "../../interceptor/axiosInterceptor";
import { PaginationInterface } from "../../../types";
interface ApiResponse {
  message: string;
  code: number;
  data: Data;
  status: boolean;
}

interface Data {
  size: number;
  reportedCommentsResponseDtos: ReportedCommentResponseDto[];
  totalCount: number;
}

export interface ReportedCommentResponseDto {
  id: string;
  commentCreatorName: string;
  commentCreatorPhotoUrl: string;
  promptType: string;
  isAlias: boolean;
  comment: string;
  commentId: string;
  commentReporterName: string;
  commentReporterPhotoUrl: string;
}

export interface DeleteResponse {
  message: string;
  code: number;
  data: boolean;
  status: boolean;
}

export const getReportedComments = async (pagination:PaginationInterface): Promise<ApiResponse> => {
  try {
    const response = await axios.get(
      `report/admin/ReportedComments?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteReportedComments = async (payload: Array<string>): Promise<DeleteResponse> => {

  try {
    const response = await axios.delete(
      `report/admin/ReportedComments`,
      {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
