import * as Yup from 'yup';
interface FileWithSize extends File {
  size: number;
}
export const communitySchema = Yup.object({
    communityName: Yup.string()
    .min(3,'Minimum 3 char required')
    .max(30,'Maximum 30 char limit reached')
      .required('Name is required'),
    communityType:Yup.string()
      .required('Community is required'),
    myFile: Yup.mixed()
    .nullable()
   .required('Image is required')
   .test('fileType', 'Only JPG, PNG, and JPEG are allowed', (value) => {
    if (!value) return true; // Allow empty values?
    return ['image/jpg', 'image/jpeg', 'image/png'].includes(value && (value as FileWithSize).type);
  }),
  description:Yup.string()
      .min(3,'Description must be at least 3 characters')
      .max(250,'Maximum 250 char limit reached')
      .matches(/^[a-zA-Z0-9 !@#$%^&*():";',.-]*$/, 'Enter valid description.')
      .required('Description is required '),
});