import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";

interface Post {
  subredditName: string;
  title: string;
  content: string | null;
  photoUrl: URL | string | null;
}

const RedditContentScraperCard = ({ posts, setOpenModal, setViewData }) => {
 
 
  const isValidImage = (url: string | URL | null) => {
    const rasterExtensions = [
      ".jpeg",
      ".jpg",
      ".png",
      ".gif",
      ".bmp",
      ".tiff",
      ".tif",
      ".webp",
    ];
    const urlStr = url?.toString();
    const extension = urlStr
      ?.substring(urlStr?.lastIndexOf("."))
      ?.toLowerCase();
    return extension ? rasterExtensions.includes(extension) : false;
  };
 
  return (
    <Grid container>
      {posts?.map((item: Post, index: number) => (
        <Grid item xs={3.8} sx={{ mb: "15px", mr: "10px" }} key={index}>
          <Card>
            {item?.photoUrl &&
              typeof item?.photoUrl === "string" &&
              item?.photoUrl?.includes("youtu") && (
                <iframe
                  width="420"
                  height="200"
                  src={`https://www.youtube.com/embed/${item?.photoUrl?.substring(
                    item?.photoUrl?.lastIndexOf("/") + 1,
                    item?.photoUrl?.indexOf("?") !== -1
                      ? item?.photoUrl?.indexOf("?")
                      : undefined
                  )}`}
                />
              )}
            {isValidImage(item?.photoUrl) && (
              <Box
                component={"img"}
                sx={{
                  height: 200,
                  width: "100%",
                  objectFit: "cover",
                  mb: "10px",
                }}
                src={item?.photoUrl?.toString()}
                alt="post img"
              />
            )}

            <Chip
              label={item?.subredditName}
              size="small"
              color="primary"
              sx={{ ml: "10px", mb: "10px", mt: "10px" }}
            />

            {!isValidImage(item?.photoUrl as string) &&
              !(
                typeof item.photoUrl === "string" &&
                item?.photoUrl?.includes("youtu")
              ) &&
              item?.photoUrl && (
                <Chip
                  label="More Info"
                  size="small"
                  color="primary"
                  variant="outlined"
                  component="a"
                  href={item?.photoUrl as string} // Explicitly cast to string
                  target="_blank"
                  sx={{ ml: "10px", mb: "10px", mt: "10px" }}
                />
              )}

            <Typography
              variant="body2"
              sx={{
                fontSize: "15px",
                ml: "10px",
                mb: "10px",
                fontWeight: "bold",
              }}
            >
              {item?.title}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                ml: "10px",
                mb: "10px",
              }}
            >
              {item?.content &&
                item?.content?.split(" ")?.slice(0, 73)?.join(" ") +
                  (item?.content?.split(" ")?.length > 30 ? "..." : "")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mb: "15px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: "5px" }}
                size="small"
                onClick={() => {
                  setViewData(item);
                  setOpenModal((prev: boolean) => !prev);
                }}
              >
                Post Scheduler
              </Button>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default RedditContentScraperCard;
