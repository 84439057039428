import React from "react";
import GenericForm from "../form/GenericForm";
import * as Yup from "yup";
import { editUsers } from "../../app/users/users";
import { doc, setDoc , addDoc , collection , updateDoc , getDoc} from "firebase/firestore";
import {db} from '../../firebase'
import dayjs from 'dayjs';


const EditUserForm = ({
  selectedUser,
  setOpenAlert,
  setMessage,
  setSeverity,
  setLoading,
  getUserDataHandler,
  setEditModal,
}) => {
  const validation = Yup.object({
    aboutMe: Yup.string(),
    anonymousName: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    birthday:Yup.date()
  });


  const fields = [
    {
      label: "Burner Name",
      name: "anonymousName",
      type: "text",
    },
    {
      label: "First Name",
      name: "firstName",
      type: "text",
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
    },
    {
      name: 'communities',
      label: 'Communities',
      type:'dropDown',
    },
    {
      label: "Birth Year",
      name: "birthday",
      type: "DatePicker",
      disableTimePicker:true,
      datePickerFormat:"YYYY",
      datePickerView:["year"],
      defaultValue:dayjs(`${selectedUser?.birthday}-01-01`),
      maxDate:dayjs(`2004-12-31`),
      minDate:dayjs(`1997-01-01`),
   
    },
    {
      label: "About Me",
      name: "aboutMe",
      type: "textarea",
      required: false,
    },
    {
      name: 'aliasFile',
      label: 'upload alias pic',
      type:'alias'
      
  },
    {
      name: "myFile",
      label: "upload pic",
      type: "file",
    },
  ];


const initialvalues = {
    aboutMe: selectedUser?.aboutMe || "",
    anonymousName: selectedUser?.anonymousName,
    firstName: selectedUser?.firstName,
    lastName: selectedUser?.lastName,
    photoUrl: "",
    myFile: undefined,
    birthday:null,
    aliasUrl:"",  
    communities:null,
  };

  const updateDocumentField = async (documentId, fieldName, newValue) => {
    const docRef = doc(db, "network_users", documentId);
    try {
        await updateDoc(docRef,{
            [fieldName]: newValue
        });
        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error updating document: ", error);
    }
  };
  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        aboutMe: values?.aboutMe || null,
        anonymousName: values?.anonymousName || null,
        firstName: values?.firstName || null,
        id: values?.id,
        communities: values?.communities || null,
        lastName: values?.lastName || null,
        birthday:values?.birthday ? dayjs(values?.birthday).format("YYYY"):selectedUser?.birthday
      };
      const image = values?.photoUrl?.split(",")[1];

      image
        ? (payload["profilePhotoUrl"] = values?.photoUrl?.split(",")[1])
        : "";
        let aliasurl = values['aliasUrl'] ? values['aliasUrl'] .split(',') : ""
            payload['aliasUrl'] = aliasurl[1]
      const response = await editUsers(payload);
      if (response?.code === 200) {
        setEditModal(false);
        setLoading(true);
        setSeverity("success");
        setMessage(response?.message || "User Details updated successfully.");
        setOpenAlert(true);
        updateDocumentField(response?.data?.id ? response?.data?.id  : "" , 'image' , response?.data?.
photoUrl
 ? response?.data?.
photoUrl
 : "")
        updateDocumentField(`${response?.data?.id ? response?.data?.id  : ""}#AnonChat` , 'image' , response?.data?.
photoUrl
 ? response?.data?.
photoUrl
 : "")
        updateDocumentField(response?.data?.id ? response?.data?.id  : "" , 'name' , response?.data?.fullName)
        updateDocumentField(`${response?.data?.id ? response?.data?.id  : ""}#AnonChat` , 'name' , response?.data?.fullName)
        updateDocumentField(response?.data?.id ? response?.data?.id  : "" , 'anonName' , response?.data?.anonymousName)
        updateDocumentField(`${response?.data?.id ? response?.data?.id  : ""}#AnonChat` , 'anonName' , response?.data?.anonymousName)
        updateDocumentField(response?.data?.id ? response?.data?.id  : "" , 'about' , response?.data?.aboutMe)
        updateDocumentField(`${response?.data?.id ? response?.data?.id  : ""}#AnonChat` , 'about' , response?.data?.aboutMe)
        updateDocumentField(response?.data?.id ? response?.data?.id  : "" , 'aliasPhoto' , response?.data?.aliasUrl)
        updateDocumentField(`${response?.data?.id ? response?.data?.id  : ""}#AnonChat` , 'aliasPhoto' , response?.data?.aliasUrl)
        getUserDataHandler();

      }
    } catch (err: any) {
      setEditModal(false);
      setOpenAlert(true);
      setSeverity("error");
      setMessage(err?.message || "Unable to update user details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericForm
      title="Edit User"
      validationSchema={validation}
      editedDetails={{
        aboutMe: selectedUser?.aboutMe || "Miami",
        anonymousName: selectedUser?.anonymousName || "",
        firstName: selectedUser?.firstName || "",
        id: selectedUser?.id || "",
        lastName: selectedUser?.lastName || "",
        photoUrl: selectedUser?.photoUrl,
        aliasUrl:selectedUser?.aliasUrl || ""
      }}
      type="Edit User"
      fields={fields}
      imageUploadField="photoUrl"
      initialValues={initialvalues}
      onSubmit={handleSubmit}
    />
  );
};

export default EditUserForm;
