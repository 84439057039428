import React from "react";
import { useLocation , Navigate , Route } from "react-router-dom";
import { useAppSelector , useAppDispatch } from "../../app/hooks";
import {  logoutAdmin, selectAuthState } from "../../app/auth/auth";
const CheckToken = ( {children} : any) =>{

const dispatch = useAppDispatch()
let token =  useAppSelector(selectAuthState)

const location = useLocation()
React.useEffect(() =>{
 window.addEventListener('storage', function (e) {

  if(e.storageArea  ?  e.storageArea?.length === 0 ? true : false : false){
    dispatch(logoutAdmin())
  }

});
    

},[location.pathname])




return <> {children}</>

};

export default CheckToken;
