import axios from "../../interceptor/axiosInterceptor";
import { DeleteResponse } from "../reportedComments/reportedComments";
import { PaginationInterface } from "../../../types";

export interface BugReport {
id: string;
bugReporterName: string;
message: string;
}

interface ReportedBugsResponse {
size: number;
reportedBugsResponseDtos: BugReport[];
totalCount: number;
}

export interface ApiResponseOfReportedBugs {
message: string;
code: number;
data: ReportedBugsResponse;
status: boolean;
}

export const getReportedBugs = async (pagination:PaginationInterface): Promise<ApiResponseOfReportedBugs> => {
try {
const response = await axios.get(
`linkedin-candidate/reported-bugs/getAll?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`
);
return response.data;
} catch (err: any) {
throw new Error(err);
}
};

export const deleteReportedBugs = async (
payload: Array<string>
): Promise<DeleteResponse> => {
try {
const response = await axios.delete(
`linkedin-candidate/reported-bugs/delete`,
{
data: payload,
headers: {
"Content-Type": "application/json",
},
}
);
return response.data;
} catch (err: any) {
throw new Error(err);
}
};