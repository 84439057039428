// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "netwrk-e09f5.firebaseapp.com",
  databaseURL: "https://netwrk-e09f5-default-rtdb.firebaseio.com",
  projectId: "netwrk-e09f5",
  storageBucket: "netwrk-e09f5.appspot.com",
  messagingSenderId: "637262166649",
  appId: "1:637262166649:web:fbf5c4ce0b5a4c4236b2a8",
  measurementId: "G-55KFP91VN8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app)
export {app , db};