import React , {useEffect , useState}from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import { addTipsAction, getTipsAction , deleteTipsAction } from '../../app/tips/tips'
import { getCommunityAction , addCommunityAction , deleteCommunityAction, getCommunityTopicsAction} from '../../app/community/community'
import { Delete as DeleteIcon, Mode} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import {Type} from '../../enum/index'
import { tipSchema } from '../../schema/tip'
import { TipsPayload } from '../../../types/tipsTypes'
import { CommunityTypes } from '../../enum/index'
import { communitySchema } from '../../schema/community'
import NestedList from '../List/List'
import ModeratorTools from './ModeratorTools'
import { useAppSelector } from '../../app/hooks'
import { selectCommunityEditState } from '../../app/CommunityEdit/communityEdit'
import { editCommunityIconAction } from '../../app/community/community'
import { communityEditSchema } from '../../schema/communityEdit'
import { selectCommunityState } from '../../app/communityEditDetails/communityEdit'
import DeleteModal from '../Modal/DeleteModal'
interface TipsProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editPromptModal:boolean
    editDetails:any
    promptId:string
    deletePromptModal:boolean
    operationType:string
    manage:boolean
    callBackEdit : (name:string) => void
    name:string
}
const CommunityGeneralisedComponent: React.FC<TipsProps> =  ({name , callBackEdit , manage , operationType , deletePromptModal,promptId, addModal , closeModal , columns , editPromptModal , editDetails }) => {
  const [community, setCommunity] =  useState<Array<any>>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity , setSeverity] = useState<string>('');
  const [topics , setTopics] = useState([])
  const [filteredValue,setFilteredValue]= React.useState<string>("");
  const [isEdit , setIsEdit]= useState<boolean>(false)
  const state = useAppSelector(selectCommunityEditState);
  const comm = useAppSelector(selectCommunityState).editDetails
  const [rowCount,setRowCount]= useState<number>(0);
  const [topicRowCount,setTopicRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [paginationForTopics , setPaginationForTopics] = React.useState({
    pageNo:1,
    pageSize:10
  })
  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getCommunityAction(pagination)
      .then((res) => {
        if(!res?.status){
          setDataLoading(false);
        }else{
          setCommunity(res.data?.list ? res.data?.list : []);
          setRowCount(res?.data?.totalCount || 0)
          setDataLoading(false);
        }      
      })
      .catch((err) => {
        setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          setDataLoading(false);
      });
  }, [toggle , pagination]);
  useEffect(() =>{
    setDataLoading(true);
    getCommunityTopicsAction(paginationForTopics , filteredValue)
    .then((res) =>{
      if(!res?.status){
        setDataLoading(false);
      }else{
        setTopics(res ? res?.data?.communityTopicAssociationList : [])
        setDataLoading(false);
        setTopicRowCount(res?.data?.totalCount)
      } 
    })
    .catch((err) =>{
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
      setDataLoading(false);
    })
  },[paginationForTopics , filteredValue])
const initialvalues = {
    communityName:'',
    communityType:'',
    description:'',
    hexColor:"#e6b219",
    myFile:undefined,
    photoUrl:"",
    displayMemberCount:0

} 
const editablevalues:any = editDetails?.name === 'icon' ? {
  photoUrl: "",
  myFile: undefined
} : editDetails?.name === 'desc' ? {
    description:'',
} : editDetails?.name === 'type' ? {
  communityType:'',
} : editDetails?.name === 'topic' ? {
  communityTopic:[],
} : [];



const addCommunity = async(values:any) =>{
  setLoading(true)
  let url = values['photoUrl'] ? values['photoUrl'] .split(',') : ""
  values['photoUrl'] = url[1]
  //Call Api To Add User
  addCommunityAction(values)
    .then((res) =>{
      if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
      }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(operationType === Type.Edit ? 'Successfully Updated': 'Successfully Added')
      setToggle(!toggle)
      }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
    })
}
const editCommunity = async(values:any) =>{
  setLoading(true)
  let url = values['photoUrl'] ? values['photoUrl'] .split(',') : ""
  values['photoUrl'] = url[1]
  //Call Api To Add User
  editCommunityIconAction(values)
    .then((res) =>{
      if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
      }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(operationType === Type.Edit ? 'Successfully Updated': 'Successfully Added')
      setToggle(!toggle)
      }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
    })
}
const deleteCommunity = async (id:string) => {
  setLoading(true)
  deleteCommunityAction(id)
  .then((res) =>{
    if(!res?.status){
      setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Record not found')
    }else{
      setLoading(false)
    setSeverity('success')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Successfully Deleted')
    setToggle(!toggle)
    }
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : "Error")
   
  })
  //Call Api To Delete User
};
const items = [
    {
      candidate_LINKED_IN_ID:CommunityTypes.Public,
  
      key:CommunityTypes.Public
    },
    {
      candidate_LINKED_IN_ID:CommunityTypes.Private,
 
      key:CommunityTypes.Private
    },
    {
      candidate_LINKED_IN_ID:CommunityTypes.Anonymous,
      key:CommunityTypes.Anonymous,
     
    
    } 
  ]

const addModalFields = [
    {
      name: 'communityName',
      label: 'Name',
      type:'text',
      required:true
      
    },
    {
        name: 'communityType',
        label: 'Community Type',
        type:'select',
        items:items,
        key:'candidate_LINKED_IN_ID',
        required:true,
        
      },
      {
        name: 'displayMemberCount',
        label: 'Displayed Members',
        type:'number',
        required:true
    }, 
    {
      name: 'description',
      label: 'Description',
      type:'textarea',
      required:true
      
    },
    {
      name: "hexColor",
      label: "Pick a color",
      type: "color",
      extraStyle:{
        width:"20%"
      }
      
    },
    {
        name: 'myFile',
        label: 'upload pic',
        type:'file'
        
    }
];
function setPage(pageSize){
  setPaginationForTopics((prev) =>{ return {...prev , pageSize:pageSize ? pageSize : prev.pageSize + 10}})
}
function handleFilterChange (value){
  setFilteredValue(value)
}
function reset(){
  setFilteredValue("")
  setPaginationForTopics((prev) =>{
    return {
      ...prev , 
      pageSize:10
    }
  })
}
const editModalFields = editDetails?.name === 'icon' ? [
  {
    name: 'myFile',
    label: 'upload pic',
    type:'file'
    
}
] : editDetails?.name === 'desc' ? [
  {
    name: 'description',
    label: 'Description',
    type:'text',
    required:true
  },
] : editDetails?.name === 'type' ? [
  {
    name: 'communityType',
    label: 'CommunityType',
    type:'select',
    items:items,
    required:true
  },
]: editDetails?.name === 'topic' ? [
  {
    name: 'communityTopic',
    label: 'Community Topic',
    type:'select',
    items:topics,
    key:'name',
    required:true,
    callBack:setPage,
    loading:dataLoading,
    rowCount:topicRowCount,
    reset:reset,
    handleFilterChange:handleFilterChange,
    paginationSize:paginationForTopics?.pageSize,
  },
] : editDetails?.name === 'name' ? [
  {
    name: 'communityName',
    label: 'Community Name',
    type:'text'
    
}
]:editDetails?.name === 'color' ? [
  {
    name: 'hexColor',
    label: 'Edit Color',
    type:'color',
    extraStyle:{
      width:"20%"
    }
    
}
] : []
function changeEditSchema (value:boolean){
  setIsEdit(value);
}

  const handleSubmit = (values : TipsPayload , type : string)=> {

    // Handle form submission here
    if(type === Type.Add){
      closeModal()
      addCommunity(values)
    }else if(type == Type.Edit){
      closeModal()
      editCommunity(values)
    }
    
};
function pagenated(pageNo:number , pageSize:number){
  setpagination(() =>{
    return {
      pageNo:pageNo,
      pageSize:pageSize
    }
  })
}
  return <>
   <DataGridTable columns={columns} row={community} loading={dataLoading} pagenated={pagenated} rowCount={rowCount} />
    {/* { Add Modal } */}
    <Modal
        open={addModal}
        title="Add Community"
        onClose={closeModal}
        form={<GenericForm title='Add Community' imageUploadField='photoUrl' validationSchema={communitySchema} type='Add Community' fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
      />
      {/* Edit Modal */}
      <Modal
              open={editPromptModal}
              title='Edit'
              onClose={() => { setIsEdit(false),closeModal()}}
              form={<GenericForm title='Save' changeSchema={changeEditSchema}  imageUploadField='photoUrl' validationSchema={editDetails?.name === 'icon' ? isEdit ? communityEditSchema('icon') : null: editDetails?.name === 'desc' ? communityEditSchema('desc') : editDetails?.name === 'type' ? communityEditSchema('type') : null} editedDetails={editDetails} type='Edit Icon'  fields={editModalFields} initialValues={editablevalues}   onSubmit={handleSubmit}></GenericForm>}
      />

      {/* delete modal */}
      <DeleteModal description={"you want to delete this community."} open={deletePromptModal} handleClose={closeModal} handleDelete={()=>{
        deleteCommunity(promptId);
        closeModal();
      }} />

      
      {/* community manage modal */}
      <Modal
              open={manage}
              title="Moderator Tools"
              onClose={closeModal}
              icon={<DeleteIcon/>}
              form={<NestedList callBackEdit={callBackEdit}/>}
      />
        <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default CommunityGeneralisedComponent;