import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { PostScheduleResponse, message } from "../../../types/common";
import { PaginationInterface } from "../../../types";
const initialState = {};

export const getPostActionV2 = async (pagination:PaginationInterface) : Promise<any>=>{
try{
  const response = await  axios.get(`linkedin-candidate/JobPostScheduler/getAllPosts?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}

  export const schedulePostv2 = async (payload: any) : Promise<any> =>{
    const body = JSON.stringify(payload)
   try{
    const response = await  axios.post(`linkedin-candidate/JobPostScheduler/schedule`, body)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
  }
export const deletePostActionV2 = async (id : string) : Promise<message> =>{
 try{
  const response = await  axios.delete(`linkedin-candidate/JobPostScheduler/delete/${id}`)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
export const schedulePostv2Slice = createSlice({
    name: "SchedulePostv2",
    initialState,
    reducers:{},
})

