import React from 'react'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import PrivacyPolicyGeneralisedComponent from './PrivacyPolicyGeneralisedComponent'
export default function PrivacyPolicy() {
const [dialogModal , setDialogModal] = React.useState<boolean>(false)

function dialogOpen(){
    setDialogModal(true)
}
function dialogClose(){
    setDialogModal(false)
}
  return (
  <>
  <Layout  title="Privacy Policy">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={dialogOpen}  variant="contained">Add/Edit Privacy Policy</Button></div>
        <PrivacyPolicyGeneralisedComponent  dialog={dialogModal} dialogClose={dialogClose}/>
    </div>
  </Layout>
  </>
  )
}
