import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { Payload } from "../../../types/common";
import {Pagable} from '../../../types/index'
import  {message } from "../../../types/common";
import { RootState } from "../store";
const initialState = {
  communitites:null,
  actionType:''
};
interface Communities{
  id: string, communityName: string
}
interface RemoveImage{
  code :number,
  data:null,
  message:string,
  status:boolean
}
export interface SeederUsersList {
  aboutMe: string
  anonymousName: string
  communities: Communities[]
  email: string
  firstName: string
  fullName: string
  id: string
  isSeeder: boolean
  lastName: string
  photoUrl: string
  totalRatingUser: number
  }
  interface Data{
    size: number
    totalCount: number
    list:[],
  }
  export interface PostManagerUsers{
      message: string,
      code: number,
      data: Data,
      status:boolean
  }  
interface pagination  {
  pageNo:number,
  pageSize:number,
  seeder:boolean
}
export const getPostManagerAction = async (param:pagination) : Promise<PostManagerUsers> =>{
try{
  const response = await  axios.get(`linkedin-candidate/seeder/get-prompt-feed?pageNumber=${param?.pageNo}&pageSize=${param?.pageSize}&seeder_posts=${param?.seeder}`)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}

export const editPostManagerAction = async (payload: any) : Promise<any> =>{
  const body = JSON.stringify(payload)
 try{
  const response = await  axios.post(`linkedin-candidate/seeder/update-prompt-feed`,body)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}

export const deletePostManagerAction =async (id : string) : Promise<any> =>{
  try{
   const response = await  axios.delete(`linkedin-candidate/seeder/prompt-feed/${id}`)
   return response.data;
  }catch(err:any){
   throw new Error(err)
  }
 }
 export const deleteImageAction =async (id : string) : Promise<RemoveImage> =>{
  try{
   const response = await  axios.delete(`linkedin-candidate/seeder/deletePostPic/${id}`)
   return response.data;
  }catch(err:any){
   throw new Error(err)
  }
 }
export const postManagerSlice = createSlice({
    name: "postManager",
    initialState,
    reducers:{
      setCommunitie: (state, action) => {
        return {
          ...state,
          communitites: action.payload,
        };
      },
      setType: (state, action) => {
        return {
          ...state,
          actionType: action.payload,
        };
      },
     
    },
})

export const { setCommunitie , setType } = postManagerSlice.actions;
export const selectPostManagerState = (state: RootState) => state?.postManagerReducer;
export default postManagerSlice.reducer;