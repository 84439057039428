import React , {useEffect , useState}from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import dayjs from 'dayjs';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import {Type} from '../../enum/index'
import { notificationSchema } from '../../schema/notificationSchema'
import {NotificationSchedulerResponse} from'../../app/notificationScheduler/NotificationScheduler'
import { addNotificationScheduler , getJobScheduled} from '../../app/notificationScheduler/NotificationScheduler'
import mixpanel from 'mixpanel-browser'
mixpanel.init(`${process.env.REACT_APP_MIXPANEL_APIKEY}`,  {debug: true});
interface TipsProps{
    addModal:boolean
    closeModal:() => void
    rowCount:number
    columns : GridColDef[]
    operationType:string
    dataLoading:boolean,
    data:Array<NotificationSchedulerResponse>
    getScheduleNotificationHandler:() => Promise<void>
    pagenated:(pageNo:number , pageSize:number) => void
  
}
const NotificationSchedulerGeneralisedComponent: React.FC<TipsProps> =  ({operationType ,rowCount,pagenated, addModal , closeModal , columns,data,dataLoading,getScheduleNotificationHandler   }) => {
  
  const [loading , setLoading] = useState<boolean>(false);
 
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity , setSeverity] = useState<string>('');



const initialvalues = {
        title:"",
        body:"",
        dateTime:"",
        promptDetailId:""
} 


interface NotificationPayload {
  body: string;
  dateTime: {
    $D:number
    $H:number
    $W:number
    $L: string;
    $u: undefined;
    $d: Date;
    $isDayjsObject:boolean;
    $m:number
    $ms:number
    $s:number
    $x:{}
    $y: number;
    $M: number;
    // Add other properties based on the actual structure
  }
  title: string;
}
function setEventOnPanel(values : any){
mixpanel.track("Notification",{
'title':`${values.title}` || '',
'body':`${values?.body}` || '',
'date':`${values?.dateTime}` || ''
})
}
const addNotification = async(values:NotificationPayload) =>{
  setLoading(true)
  const date = values.dateTime.$d;

  const payload = {
    ...values,
    timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateTime:dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  }
  addNotificationScheduler(payload)
    .then((res) =>{
    if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
    }else{
      setEventOnPanel(payload ? payload : "")
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      getScheduleNotificationHandler()
      setMessage(res?.message ? res.message : 'Successfully Scheduled')
  
    }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err?.message ? err?.message : 'Error' )
    })
}


const addModalFields = [
    {
      name: 'title',
      label: 'Title',
      type:'text',
      required:true
      
    },
    {
      name: 'body',
      label: 'Body',
      type:'text',
      required:true
      
    },
    {
      name: 'promptDetailId',
      label: 'promptDetailId',
      type:'posts',
    },
    {
      name: 'dateTime',
      label: 'Date Picker',
      type:"DatePicker"
    }
];



  const handleSubmit = (values : NotificationPayload , type : string)=> {
  
    // Handle form submission here
    if(type === Type.Add || type == Type.Edit){
      closeModal()
      addNotification(values)
    }
    
};

  return <>
   <DataGridTable columns={columns} row={data} loading={dataLoading} pagenated={pagenated} rowCount={rowCount} />
    {/* { Add Modal } */}
    <Modal
        open={addModal}
        title="Schedule Notification"
        onClose={closeModal}
        form={<GenericForm title='Schedule Notification' validationSchema={notificationSchema} type='Add Schedule Notification' fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
      />
        <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default NotificationSchedulerGeneralisedComponent;