import React , {useEffect , useState}from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import { BasePayload } from '../../../types/common'
import { addEducationAction , editEducationAction, getEducationAction,deleteEducationAction } from '../../app/education/educationSlice'
import { editCommunityMembersAction, getCommunityMembersAction } from '../../app/community/community'
import { Delete as DeleteIcon} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import { EducationList } from '../../app/education/educationSlice'
import { CommunityList } from '../../../types/communityType'
interface EducationProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editEducationModal:boolean
    educationId:string
    editDetails:GridRowParams | null
    deleteEducationModal:boolean,
    handleEditCellChange:(params:any , value:number) =>  void
}
const EditCommunityMembersGenericComponent: React.FC<EducationProps> =  ( {handleEditCellChange , deleteEducationModal ,addModal , closeModal , columns , editEducationModal , editDetails,educationId }) => {
  const [communityMembers, setCommunityMembers] =  useState<CommunityList[]>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('') 
  const [editValue , setEditValue] = React.useState<number>(0) 
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  useEffect(() => {
    // Fetch user data from an API when the component mounts
    getCommunityMembersAction(pagination)
    .then((res) =>{
      if(!res.status){
      }else{
        setCommunityMembers(res.data.list ? res.data.list.reverse() : [])
        setRowCount(res?.data?.totalCount || 0)
      }
     
    })
    .catch((err) =>{
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
    }).finally(() =>{
        setDataLoading(false);
    })
  
 
  }, [toggle , editEducationModal]);

const handleEdit = (params , value) =>{
 setEditValue(value ? value : "")
 handleEditCellChange(params , value)
}

function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }



 
  return <>
   <DataGridTable handleEdit={handleEdit} columns={columns} row={communityMembers} loading={dataLoading} pagenated={pagenated} rowCount={rowCount} />
        <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default EditCommunityMembersGenericComponent;