
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Typography,
  Avatar,
  FormControl,
} from "@mui/material";
import ReplyIcon from '@mui/icons-material/Reply';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControlLabel, Checkbox } from '@mui/material';

export interface DetailData{
  aboutMe:string, 
anonymousName:string, 
communities:[],
email:string, 
firstName:string, 
fullName:string, 
id:string, 
isSeeder:boolean,
lastName:string,
photoUrl:string, 
totalRatingUser:number 
}

const Comment = ({ comment, addReply, userList,deleteData }) => {
  const [replyText, setReplyText] = useState<string>("");
  const [showReplyForm, setShowReplyForm] = useState<boolean>(false);
  const [hideComment, setHideComment] = useState<boolean>(false);
  const [author, setAuthor] = useState<string>("");
  const [aliasVal,setAliasVal]= useState(false);

  const handleReplySubmit = (e) => {
    e.preventDefault();
    if (replyText) {
      let value:DetailData = userList.filter((val:DetailData)=>val.fullName == author)
      let username = value[0]?.id
      addReply(comment.id, replyText,username,aliasVal);
      setReplyText("");
      setAuthor("");
      setShowReplyForm(false);setAliasVal(false);
    }
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
      <Grid container>
        <Box display="flex" alignItems="center" width={'100%'}>
            <Grid item display="flex" justifyContent={"left"} sm={1} xs={12}>
          <Avatar>
          <img 
              src={comment.photoUrl} 
              alt="photo" 
              style={{width: '100%', height: '100%', objectFit: 'cover' }} 
            />
          </Avatar>
          </Grid>
          <Grid item sm={9} xs={12}>
          <Typography variant="h6" sx={{pl:"12px"}}>
            {comment.fullName}
            </Typography>
            </Grid>
            <Grid item sm={2} xs={12}>
              { !comment.isDeleted && 
                <Button onClick={() => deleteData(comment.id)} sx={{ mt: 1, display:"flex", alignItems:'right', justifyContent:'right'}}>
                <DeleteIcon/>
              </Button>
              }
          
            </Grid>
            </Box>
            </Grid>
      
        <Typography variant="body1" sx={{ mt: 1 }}>
         {comment.isDeleted ? `[deleted]` : comment.comment}
        </Typography>
        <Button onClick={() => setShowReplyForm(!showReplyForm)} sx={{ mt: 1 }}>
        {showReplyForm ? "Cancel" : 
        <>
       <ReplyIcon/> {"Reply"}
       </>
        }
        </Button>
        <Button onClick={()=>setHideComment(!hideComment)} sx={{ mt: 1 }}>
          <ChatIcon/> {hideComment? "Hide comments" : "Show comments"}
          </Button>
        {showReplyForm && (
          <form onSubmit={handleReplySubmit} style={{ marginTop: "10px" }}>
            <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={12}>
           <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={userList?.map((val) => val?.fullName || '')}
              fullWidth
              onChange={(event, newValue) => {
                if(newValue !== null){
                  setAuthor(newValue)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="status"
                  label="Seeders"
                />
              )}
              value={author}
            />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
              label="Reply"
              variant="outlined"
              fullWidth
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              sx={{ mt: 1 }}
            />
            </Grid>
            <Grid item xs={12}>
            <FormControlLabel
          control={<Checkbox
            id="isAlias"
            checked={aliasVal}
            onChange={()=>{
              setAliasVal((prv)=>!prv)
            }}
            color="primary"
          />}
          label="Use Alias"
        />
            </Grid>
           
            </Grid>

            <Button type="submit" variant="contained" sx={{ mt: 1 }}>
              Submit
            </Button>
          </form>
        )}
          
        {hideComment && (
          <>
          {comment.feedCommentResponseDtoResponse && comment.feedCommentResponseDtoResponse.list &&
            comment.feedCommentResponseDtoResponse.list.map((reply) => (
              <Box key={reply.id} sx={{ ml: 4, mt: 2 }}>
                <Comment comment={reply} addReply={addReply} userList={userList} deleteData={deleteData}/>
              </Box>
            ))}
          </>
        )}
        
      </CardContent>
    </Card>
  );
};

export default Comment;