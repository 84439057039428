import { Grid } from "@mui/material";
import React, { useState } from "react";
import GenericForm from "../../form/GenericForm";
import * as Yup from "yup";
import { getCommunityAction } from "../../../app/community/community";
import { getSeederCandidatesByCommunity } from "../../../app/seeders/seeders";
import dayjs from "dayjs";
import { schedulePost } from "../../../app/post/post";
import { Box } from "@mui/system";
import { PaginationInterface } from "../../../../types";

interface CommunityTopicResponse {
  id: string;
  name: string;
  imageUrl: string;
  hexColor: string;
}

interface Community {
  id: string;
  communityName: string;
  photoUrl: string;
  description: string;
  hexColor: string;
  createdBy: string;
  updatedBy: string;
  communityType: "PUBLIC" | "ANON" | "PRIVATE";
  communityTopicResponses: CommunityTopicResponse[];
}

interface CommunitySeeders {
  id: string;
  communityName: string;
  photoUrl: string;
  description: string;
  communityType: "PUBLIC" | "ANON" | "PRIVATE";
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  photoUrl: string;
  aboutMe: string;
  anonymousName: string;
  isSeeder: boolean;
  totalRatingUser: number;
  communities: CommunitySeeders[];
}

interface ApiResponse<T> {
  message: string;
  code: number;
  data: T;
  status: boolean;
}

interface Pagination {
  pageNo: number;
  pageSize: number;
}

interface DataObject {
  pagination: Pagination;
  id: string;
}

interface PostSchedulePayload {
  communityId: string;
  candidateLinkedInId: string;
  title: string;
  body: string;
  dateTime: string;
}

interface PostScheduleApiResponse {
  code: number;
  message: string;
  data: {
    jobId: string;
    jobGroup: string;
    message: string;
    status: boolean;
  };
  status: boolean;
}

const SchedulePostForm = ({
  setLoading,
  setSeverity,
  setOpenAlert,
  setMessage,
  loading,
  viewData,
  setOpenModal,
}) => {
  const [communities, setCommunities] = useState<Community[]>([]);
  const [count, setCount] = useState(0);
  const [picture, setPicture] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState(null);
  const [seeders, setSeeders] = useState<User[]>([]);

  const getCommunities = async (payload: PaginationInterface) => {
    try {
      setLoading(true);
      const res: ApiResponse<any> = await getCommunityAction(payload);
      setLoading(false);

      if (res?.status && res?.data) {
        setCount(res?.data?.totalCount);
        setCommunities(res?.data?.list || []);
      } else {
        setCount(0);
        setCommunities([]);
      }
    } catch (error: any) {
      setLoading(false);
      setSeverity("error");
      setOpenAlert(true);
      setMessage(error ? error?.message : "Error");
    }
  };

  const getSeeder = async () => {
    try {
      setLoading(true);
      const res: ApiResponse<any> = await getSeederCandidatesByCommunity(
        selectedCommunityId
      );

      if (res?.status && res?.data) {
        setLoading(false);
        res?.data?.seederCandidateListResponseDtos?.length > 0
          ? setPicture(true)
          : setPicture(false);
        setSeeders(
          res?.data?.seederCandidateListResponseDtos
            ? res?.data?.seederCandidateListResponseDtos
            : []
        );
      } else {
        setLoading(false);
        setPicture(false);
        setSeeders([]);
      }
    } catch (error: any) {
      setPicture(false);
      setLoading(false);
      setSeverity("error");
      setOpenAlert(true);
      setMessage(error ? error?.message : "Error");
    }
  };

  const getCommunityId = (data: any) => {
    setSelectedCommunityId(data.id);
  };

  const isValidImage = (url: string | URL | null) => {
    const allowExtensions = [".jpeg", ".jpg", ".png", ".gif"];

    const urlStr = url?.toString();
    const extension = urlStr
      ?.substring(urlStr?.lastIndexOf("."))
      ?.toLowerCase();
    return extension ? allowExtensions.includes(extension) : false;
  };

  const isValidYoutubeLink = (url: string | URL | null): string => {
    if (url) {
      const urlStr = url.toString();
      let videoID = "";
      if (urlStr.includes("youtu.be/")) {
        videoID = urlStr.split("youtu.be/")[1].split("?")[0];
      } else if (urlStr.includes("youtube.com/watch?v=")) {
        videoID = urlStr.split("v=")[1]?.split("&")[0];
      } else if (urlStr.includes("youtube.com/live/")) {
        videoID = urlStr.split("live/")[1]?.split("?")[0];
      } else if (urlStr.includes("youtube.com/shorts/")) {
        videoID = urlStr.split("shorts/")[1]?.split("?")[0];
      } else if (urlStr.includes("youtube.com/embed/")) {
        videoID = urlStr.split("embed/")[1]?.split("?")[0];
      }

      if (videoID) {
        const thumbnailURL = `https://i.ytimg.com/vi/${videoID}/default.jpg`;
        return thumbnailURL;
      }
    }
    return "";
  };

  const fields = [
    {
      label: "Select Community",
      name: "communityId",
      type: "drop",
      items: communities,
      key: "communityName",
      callBack: getCommunities,
      loading: loading,
      totalCount: count,
      handleChange: getCommunityId,
      more: true,
      payload: {
        pagination: true,
      },
      picture: false,
      required: true,
    },
    {
      label: "Select sedder",
      name: "candidateLinkedInId",
      type: "drop",
      items: seeders,
      key: "fullName",
      specialKey: "communityId",
      callBack: getSeeder,
      more: false,
      payload: {
        id: true,
        pagination: true,
      },
      disable: false,
      picture: picture,
      required: true,
    },

    {
      label: "Title",
      name: "title",
      type: "text",
      required: true,
    },
    {
      label: "Content",
      name: "body",
      type: "textarea",
    },
    {
      label: "Schedule Time",
      name: "dateTime",
      type: "DatePicker",
    },
    {
      label: "Use Alias",
      name: "isAlias",
      type: "checkbox",
    },
  ];

  const initialvalues = {
    communityId: "",
    candidateLinkedInId: "",
    title: viewData?.title || "",
    body: viewData?.content || "",
    dateTime: "",
    isAlias: false,
  };

  const validation = Yup.object({
    communityId: Yup.string().required("community Name is required."),
    candidateLinkedInId: Yup.string().required("seeder Name is required."),
    title: Yup.string().required("title is required."),
    body: Yup.string(),
    dateTime: Yup.date(),
  });

  const handleSubmit = async (data: PostSchedulePayload) => {
    setLoading(true);
    setOpenModal(false);
    const date = dayjs(data.dateTime);

    const payload = {
      ...data,
      postUrl: data?.body
        ? ""
        : isValidImage(viewData?.photoUrl)
        ? viewData?.photoUrl
        : isValidYoutubeLink(viewData?.photoUrl),
      promptType: "PROMPT",
      timeZone: data?.dateTime
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : null,
      dateTime: data?.dateTime
        ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : null,
    };

    const response: PostScheduleApiResponse = await schedulePost(payload);

    if (response?.code === 200) {
      setOpenAlert(true);
      setSeverity("success");
      setLoading(false);
      setMessage(response?.data?.message || "Successfully Posts scheduled.");
    } else {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(response?.message || "Unable to schedule post.");
      setLoading(false);
    }
  };

  return (
    <Grid container>
      {viewData?.photoUrl && viewData?.photoUrl?.includes("youtu") && (
        <iframe
          width="100%"
          height="200"
          src={`https://www.youtube.com/embed/${viewData?.photoUrl?.substring(
            viewData?.photoUrl?.lastIndexOf("/") + 1,
            viewData?.photoUrl?.indexOf("?") !== -1
              ? viewData?.photoUrl?.indexOf("?")
              : undefined
          )}`}
        />
      )}

      {isValidImage(viewData?.photoUrl) && (
        <Box
          component={"img"}
          sx={{
            height: 200,
            width: "100%",
            objectFit: "cover",
            mb: "10px",
          }}
          src={viewData?.photoUrl}
          alt="post img"
        />
      )}
      <Grid item xs={12}>
        <GenericForm
          title="Submit"
          validationSchema={validation}
          type=" "
          fields={fields}
          initialValues={initialvalues}
          onSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default SchedulePostForm;
