import {useState} from "react";
import dayjs from "dayjs";
const useCommentTree = (initialComments) => {
  const [comments, setComments] = useState(initialComments);
  const insertNode = (tree, commentId, content) => {
    return tree.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [...comment.replies, content],
        };
      } else if (comment.replies && comment.replies.length > 0) {
        return {
          ...comment,
          replies: insertNode(comment.replies, commentId, content),
        };
      }
      return comment;
    });
  };
  const insertComment = (commentId, content,payload) => {
    const newComment = {
        ...payload,
      id: Date.now(),
      replies: [],
    };
    if (commentId) {
      setComments((prevComments) =>
        insertNode(prevComments, commentId, newComment)
      );
    } else {
      setComments((prevComments) => {
        return [newComment, ...prevComments]
    });
    }
   
  };
  const editNode = (tree, nodeId, content , newDate, alias) => {
    const da:any = dayjs(newDate)
    const d = da?.$d
    return tree.map((node) => {    
      if (node.id === nodeId) {
        return {
          ...node,
          dateTime:dayjs(d).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          date:dayjs(newDate),
          reply: content || node?.reply,
          comment: content || node?.comment ,
          candidateLinkedInId:node?.candidateLinkedInId || "",
          isAlias:alias,
          anonName:node?.anonName,
          aliasUrl:node?.aliasUrl,
          author:  node?.author  ,
          photoUrl: node?.photoUrl,
          timestamp: new Date().toISOString(),
        };
      } else if (node.replies && node.replies.length > 0) {
        return {
          ...node,
          replies: editNode(node.replies, nodeId, content , newDate , alias),
        };
      }
      return node;
    });
  };
  const editComment = (commentId, content , newDate , aliasVal) => {
    setComments((prevComments) => editNode(prevComments, commentId, content , newDate , aliasVal));
  };
  const deleteNode = (tree, nodeId) => {
    return tree.reduce((acc, node) => {
      if (node.id === nodeId) {
        return acc;
      } else if (node.replies && node.replies.length > 0) {
        node.replies = deleteNode(node.replies, nodeId);
      }
      return [...acc, node];
    }, []);
  };
  const deleteComment = (commentId) => {
    setComments((prevComments) => deleteNode(prevComments, commentId));
  };
  return {
    comments,
    insertComment,
    editComment,
    deleteComment,
  };
};
export default useCommentTree;