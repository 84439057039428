import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  
});
const globalStyle = {
'.css-ltyecm-MuiSvgIcon-root':{
display:'none !important'
},
'.MuiDrawer-docked':{
  height:'100vh',
  overflow:'scroll',
  overflowX:'hidden',
},
'.MuiDataGrid-panel':{
  transform:'translate(112px , 69px) !important'
}
};
export default theme;
export {globalStyle}
