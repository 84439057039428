import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../../../layout/Layout'
import { Button } from '@mui/material'
import ModeratorGeneralisedComponent from './ModeratorsGenricComponent'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
export default function Moderators() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [promptId, setPromptId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [editFeedDetails , setEditFeedDetails] = React.useState<GridRowParams | null>(null)

const closeModal = () =>{
        setAddModal(false)
        setEditModal(false)
        setDeleteModal(false)
}
const editFeedModal = (params :GridRowParams) =>{
    setEditModal(true)
    setEditFeedDetails(params ? params?.row : [])

}
function deletePromptModal(id: string) {
  setPromptId(id)
  setDeleteModal(true)
}
const columns: GridColDef[] = [
  { field: 'fullName', headerName: 'Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'photoUrl',type:"actions", headerName: 'Logo', width:10, flex: 1,renderCell: (params) =>  <Card>
  <CardMedia
    component="img"
    alt="not available"
    height="45"
    image={params ? params.value : ''} // Replace with the URL of your image
  />
</Card>},
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deletePromptModal(params?.row?.id)}}
          />
      ];
    },
  }

]
  return (
  <>
  <Layout  title="Moderators">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => setAddModal(true)} variant="contained">Add Moderator</Button></div>
        <ModeratorGeneralisedComponent promptId={promptId} deletePromptModal={deleteModal}  editDetails={editFeedDetails}  editPromptModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  />
    </div>
  </Layout>
  </>
  )
}
