import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
const initialState = {};

  export interface FeedManagerResponse{
    id: string,
    createdAt: string | null,
    updatedAt: string | null,
    feedSetting: string,
    createdBy: string | null,
    updatedBy: string | null,
    kvalue: number,
    wvalue:number
  }
  
  export interface FeedManager{
    message: string,
    code: number,
    data: FeedManagerResponse[]
    status: boolean
  }
export interface FeedManagerPayload{
    
        feedSetting: string,
        id: string,
        kvalue: 0,
        wvalue: 0
      
}
export const getFeedManagerAction = async () : Promise<FeedManager> =>{
  try{
    const response = await  axios.get(`linkedin-candidate/seeder/getFeedManager`)
  return response.data;
  }catch(err:any){
    throw new Error(err)
  }
}

export const editFeedManagerAction = async (payload: FeedManagerPayload) : Promise<FeedManager> =>{
    const body = JSON.stringify(payload)
   try{
    const response = await  axios.put(`linkedin-candidate/seeder/editFeedManager`,body)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
}

export const feedManagerSlice = createSlice({
    name: "Feed Manager",
    initialState,
    reducers:{},
})

