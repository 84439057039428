import React from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import { Close as CloseIcon, Delete as DeleteIcon } from "@mui/icons-material";
import confirmAnimation from "../../images/confirm-img.json";
import Lottie from "lottie-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

const ConfirmationPopup = ({
  open,
  handleClose,
  handleConfirm,
  description,
  btn1,
  btn2,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="body2"
          sx={{ fontSize: "25px", fontWeight: 500, textAlign: "center" }}
        >
          Are you sure ?
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "15px",
            fontWeight: 500,
            textAlign: "center",
            mt: "2px",
          }}
        >
          {description || "you want to cancel this item."}
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Lottie
            animationData={confirmAnimation}
            loop={true}
            style={{
              height: 150,
              width: 140,
            }}
          />
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleConfirm}
          >
            {btn1 || "Yes"}
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{ mr: "5px" }}
          >
            {btn2 || "No"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationPopup;
