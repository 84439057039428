import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { LoginPayload } from "../../../types/login";
import { useAppDispatch } from "../hooks";
import { RootState } from "../store";

const initialState = {
    token: null,
    email:null
};


export const loginAction = createAsyncThunk(
    'user/login',
    async (payload: LoginPayload) => {
    const body = JSON.stringify(payload)
    try{
      const response = await axios.post(`${process.env.REACT_APP_LOGIN_URL}`,
      body
     
       );
      return response.data

    }catch(err:any){
      throw new Error(err)
    }

  }
);

export const logOut = createAsyncThunk(
  'user/logout',
  async () => { 

  }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setToken: (state, action) => {
          return {
            ...state,
            token: action.payload.token,
            email:action.payload.email
          };
        },
        logoutAdmin:(state)=> {
           return {
            token:null,
            email:null
           }
   
        }
      },
    extraReducers:(builder) =>{
        builder.addCase(loginAction.fulfilled,(state , action) =>{
            state.token = action?.payload?.data?.token
            state.email = action?.payload?.data?.email 
        })
        
    }
})

export const { setToken  , logoutAdmin} = authSlice.actions;
export const selectAuthState = (state: RootState) => state.authReducer.token;
export default authSlice.reducer;