import axios from "../../interceptor/axiosInterceptor";

export const getRedditContentScraper = async (payload:string): Promise<any> => {
  try {
    const response = await axios.get(
      `/linkedin-candidate/subreddit-manager/reddit_scraper?${payload}`
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};