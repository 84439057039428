import authReducer from "./auth/auth";
import communityReducer from './communityEditDetails/communityEdit';
import communityEdit from "./CommunityEdit/communityEdit";
import seederReducer from '../app/seeders/seeders'
import postManagerReducer from '../app/seeders/postManager';
import drawerReducer from "../layout/DrawerSlice";
import twoFactorAuthenticationReducer from "../pages/TwoFactorAuthenticationSlice";
 
const rootReducers = {
   authReducer :  authReducer,
   communityReducer : communityReducer,
   communityEdit : communityEdit,
   seederReducer : seederReducer,
   postManagerReducer:postManagerReducer,
   drawerReducer:drawerReducer,
   twoFactorAuthenticationReducer:twoFactorAuthenticationReducer
}

export default rootReducers;