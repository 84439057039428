import React, { useState } from "react";
import { Layout } from "../../layout/Layout";
import GenericForm from "../form/GenericForm";
import * as Yup from "yup";
import { Typography } from "@mui/material";
import { boosterType } from "../../enum";
import { upvoteBoosterPost } from "../../app/seeders/upvoteBooster";
import { AlertColor } from "@mui/material";
import Toaster from "../Toaster/Toaster";
import Loader from "../Loader/Loader";
import { UpvoteBoosterResponse } from "../form/type";

const UpVoteBoosterForm = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      label: "Boost Type",
      name: "boostType",
      type: "select",
      items: [
        {
          candidate_LINKED_IN_ID: boosterType.comment,
          key: boosterType.comment,
        },
        {
          candidate_LINKED_IN_ID: boosterType.post,
          key: boosterType.post,
        },
      ],
      required: true,
      key: "candidate_LINKED_IN_ID",
      uniqueKey: "boostType",
      specialKey: "boostType",
    },
    {
      label: "Start Date(Optional)",
      name: "startDate",
      type: "DatePicker",
      required: false,
      disableTimePicker: true,
    },
    {
      label: "End Date (Optional)",
      name: "endDate",
      type: "DatePicker",
      required: false,
      disableTimePicker: true,
    },
    {
      label: "Minimum Upvotes",
      name: "minUpvote",
      type: "number",
      required: true,
    },
    {
      label: "Maximum Upvotes",
      name: "maxUpvote",
      type: "number",
      required: true,
    },
  ];

  const handleSubmit = async (values: any) => {
    const startDate = new Date(values.startDate);
    const endDate = new Date(values.endDate);
    const today = new Date();

    if (endDate < startDate) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("End date should be greater than start date.");
      return;
    }

    if (startDate > today || endDate > today) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Not allow future date.");
      return;
    }

    if (values?.minUpvote < 0 || values?.maxUpvote < 0) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Upvote should be greater than 0.");
      return;
    }

    if (values?.minUpvote >= values?.maxUpvote) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Max upvotes should be greater than min upvotes.");
      return;
    }
    setLoading(true);
    const response: UpvoteBoosterResponse<Array<string>> =
      await upvoteBoosterPost({
        ...values,
        startDate: values.startDate? new Date(values.startDate)
          .toLocaleDateString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"):"",
        endDate: values.endDate ? new Date(values.endDate)
          .toLocaleDateString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"):"",
      });
    if (response.code == 200) {
      setOpenAlert(true);
      setSeverity("success");
      setLoading(false);
      setMessage(response.message || "Record Added Successfully");
    } else {
      setOpenAlert(true);
      setSeverity("error");
      setLoading(false);
      setMessage(response.message || "Error");
    }
  };

  const validation = Yup.object({
    boostType: Yup.string(),
    startDate: Yup.date(),
    endDate: Yup.date(),
    minUpvote: Yup.number().required("Min Upvote is required."),
    maxUpvote: Yup.number().required("Max Upvote is required."),
  });

  const initialvalues = {
    boostType: "",
    startDate: undefined,
    endDate: undefined,
    minUpvote: "",
    maxUpvote: "",
  };

  return (
    <Layout title="Upvote Booster">
      <Typography sx={{ fontWeight: "500" }}> Upvote Booster </Typography>

      {loading ? (
        <Loader loading={loading} />
      ) : (
        <GenericForm
          title="Boost Upvotes"
          validationSchema={validation}
          type="Upvote Booster"
          fields={fields}
          initialValues={initialvalues}
          onSubmit={handleSubmit}
        />
      )}

      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
    </Layout>
  );
};

export default UpVoteBoosterForm;
