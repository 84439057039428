import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface AppWidgetSummaryProps<T extends number | string> {
  title: string;
  total: T;
  icon?: ReactNode;
  color?: string;
  sx?: React.CSSProperties;
}

export default function AppWidgetSummary<T extends number | string>({
  title,
  total,
  icon,
  color = 'primary',
  sx,
  ...other
}: AppWidgetSummaryProps<T>) {
  return (
    <Card
      component={Stack}
      spacing={5}
      direction="column" // Changed direction to column
      sx={{
        px: 2,
        py: 2,
        borderRadius: 2,
        backgroundColor: '#4375d0',
        transition: 'box-shadow 0.3s',  // Add a smooth transition for a better user experience
        ':hover': {
          boxShadow: '5px 10px 8px rgba(50, 25, 25, 0.5)',  // Add box shadow on hover
        },
        maxHeight: 'auto',
        textAlign: 'center', // Center the content
        ...sx,
      }}
      {...other}
    >
      {/* Icon and Total */}
      <Stack spacing={0.5} sx={{ color: 'white', width: '100%' }}>
        {icon && <Box sx={{ width: 64, height: 64, color: 'white', margin: '0 auto' }}>{icon}</Box>}
        <Typography variant="h4" sx={{ width: '100%' }}>
          {total}
        </Typography>
      </Stack>

      {/* Title */}
      <Stack spacing={0.5} sx={{ color: 'white', width: '100%' }}>
        <Typography variant="subtitle2">{title}</Typography>
      </Stack>
    </Card>
  );
}

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
