import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useAppSelector , useAppDispatch} from '../../app/hooks';
import { selectCommunityEditState } from '../../app/CommunityEdit/communityEdit';
import { setState } from '../../app/CommunityEdit/communityEdit';
import GroupIcon from '@mui/icons-material/Group';
interface listProps{
callBackEdit : (name:string) => void
}
 const NestedList: React.FC<listProps> = ({callBackEdit}) =>{
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const communityState = useAppSelector(selectCommunityEditState).current
    function handleClick(name:string){
        if(name === 'icon'){
            callBackEdit('icon');
        }else if(name === 'desc'){
          callBackEdit('desc');
        }else if(name === 'type'){
          callBackEdit('type');
        }else if(name === 'topic'){
          callBackEdit('topic');
          // navigate('/community-topics')
        }else if(name === 'name'){
          callBackEdit('name');
        }else if(name === 'color'){
          callBackEdit('color');
        }else if(name === 'moderator'){
          navigate('/moderator')
        } else if(name === 'approved'){
          navigate('/approved-users')
        }else if(name === 'editCommunityMembers'){
          navigate('/edit-community-members')
        }
    }

  return (
    <>
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          General
        </ListSubheader>
      }
       >
           <ListItemButton onClick={() => handleClick('name')}>
        <ListItemIcon>
        <Icon  icon="icon-park-solid:edit-name"  style={{ fontSize: '24px' }} />
        </ListItemIcon>
        <ListItemText primary="Community Name" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('color')}>
        <ListItemIcon>
        <Icon  icon="mdi:color"   style={{ fontSize: '24px' }} />
        </ListItemIcon>
        <ListItemText primary="Color Edit" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('editCommunityMembers')}>
        <ListItemIcon>
       <GroupIcon style={{ fontSize: '24px' }} />
        </ListItemIcon>
        <ListItemText primary="Edit Community Members" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('icon')}>
        <ListItemIcon>
        <Icon icon="heroicons:camera-16-solid"  style={{ fontSize: '24px' }} />
        </ListItemIcon>
        <ListItemText primary="Community Icon" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('desc')}>
        <ListItemIcon>
        <Icon style={{ fontSize: '24px' }} icon="ic:round-mode-edit" />
        </ListItemIcon>
        <ListItemText primary="Description" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('type')}>
        <ListItemIcon>
        <Icon style={{ fontSize: '24px' }} icon="uim:lock" />
        </ListItemIcon>
        <ListItemText primary="Community Type" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('topic')}>
        <ListItemIcon>
        <Icon style={{ fontSize: '24px' }} icon="material-symbols-light:topic-sharp" />
        </ListItemIcon>
        <ListItemText primary="Add Community Topic" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Community Rules" />
      </ListItemButton>
    </List>
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          User Management
        </ListSubheader>
      }
       >
      <ListItemButton onClick={() => handleClick('moderator')}>
        <ListItemIcon>
        <Icon icon="material-symbols-light:shield-person"  style={{ fontSize: '24px' }} />
        </ListItemIcon>
        <ListItemText primary="Moderators" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick('approved')}>
        <ListItemIcon>
      <PersonIcon style={{ fontSize: '24px' }}/>
        </ListItemIcon>
        <ListItemText primary="Approved Users" />
      </ListItemButton>
    </List>
    </>
  );
}
export default NestedList;