// src/NotFound.tsx
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate} from 'react-router-dom';

const NotFound: React.FC = () => {
const navigate = useNavigate()
  const handleHomeClick = () => {
    navigate('/users')
  };

  return (
    <Container
      maxWidth="sm"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
    >
      <Typography variant="h1" align="center" color="textSecondary" gutterBottom>
        404 - Not Found
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        Sorry, the page you are looking for does not exist.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleHomeClick} style={{ marginTop: '16px' }}>
        Go to Home Page
      </Button>
    </Container>
  );
};

export default NotFound;
