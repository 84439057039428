import React , {useEffect , useState}from 'react'
import Dialog from '@mui/material/Dialog';
import Loader from '../Loader/Loader'
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Toaster from '../Toaster/Toaster'
import { getPrivacyPolicy , addPrivacyPolicy} from '../../app/privacyPolicy/privacyPolicy'
import Toolbar from '@mui/material/Toolbar';
import Editor from '../Editor';
import Modal from '../Modal/Modal';
import { AlertColor } from '@mui/material';
import Typography from '@mui/material/Typography';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from "react-quill";
import ResizeModule from "@botom/quill-resize-module";


interface PrivacyProps {
    dialog:boolean
    dialogClose:() => void
}
const PrivacyPolicyGeneralisedComponent: React.FC<PrivacyProps> =  ({dialog , dialogClose}) => {
const [openAlert, setOpenAlert] = React.useState(false);
const [message, setMessage] = React.useState("");
const [severity , setSeverity] = React.useState('') 
const [loading , setLoading] = useState<boolean>(false);
const [unsaved , setUnsaved] = useState<boolean>(false);
const [openDialog , setOpenDialog] = useState<boolean>(false);
const [toggle , setToggle] = useState<boolean>(false);
const [htmlContent, setHtmlContent] = useState<string>("");
const [disable , setDisable] = useState<boolean>(true)
const [contentLength , setContentLength] = useState<number>(0)

  useEffect(() => {
    setLoading(true)
    // Fetch user data from an API when the component mounts
    getPrivacyPolicy()
      .then((res) => {
        if (/^<html>\s*<body>[\s\S]*<\/body>\s*<\/html>$/.test(res) && !/<(?!\/?(html|body)\b)[^>]+>/.test(res)) {
          res = res.replace(/<\/?(html|body)>/g, '').replace(/^\s*|\s*$/g, '').replace(/\s{2,}/g, ' ');
          // If no <p> tags, replace <html> and <body> with <p>
          res = '<p>' + res + '</p>'
      }else{
        res = res.replace(/<\/?(html|body)>|\s{2,}/g, ' ').trim();
      }
       
       setContentLength(res ? res.length : 0)
        setHtmlContent(`${res}`)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setSeverity('error')
        setOpenAlert(true)
        setMessage(err ? err.message : 'Error')
      });
  }, [toggle]);

function savedChanges(){
    setOpenDialog(true)
}

function handlePopupClose(){
    setOpenDialog(false)
    setUnsaved(false)
}
function handleClose () {
    setDisable(true)
    dialogClose();
    setOpenDialog(false);
    setUnsaved(false);
    disable === false ? setToggle(!toggle) : null
}
function onChange(value : string , text :string){
  value = value.replace(/<\/?(html|body)>|\s{2,}/g, ' ').trim()
    setHtmlContent(value ? value : '')

    if(value.length > contentLength || value.length < contentLength){
      setDisable(false)
    }else{
      setDisable(true)
    }
}

function unsavedChanges (){
    setUnsaved(true)
}

function addPolicy(){
    setLoading(true)
    const payload = {
        privacyPolicy : htmlContent,
        id:"b86c12bd-adc0-4d38-8e28-cdcbdbb8e99d",
        policyType:'PRIVACYPOLICY',
        termsAndConditions: "",
        disclaimer: "",
    }
    addPrivacyPolicy(payload)
    .then((res) =>{
      if(res?.status === false){
        setSeverity('error')
        setOpenAlert(true)
        setMessage(res?.message ? res?.message : 'Record not found' )
        handleClose()
        setLoading(false)
      }else{
        setSeverity('success')
        setOpenAlert(true)
        setMessage('Successfully Added')
        setToggle(!toggle)
        handleClose()
        setLoading(false)
      }
     
       
       
    })
    .catch((err) =>{
        setSeverity('error')
        setOpenAlert(true)
        setMessage(err?.message ? err?.message : 'Error' )
        handleClose()
        setLoading(false)
    })
}

return <>
<Paper elevation={0} sx={{ minHeight:'70vh' , maxHeight:'auto', width: '100%' , padding:"10px" }}>
{htmlContent ? <ReactQuill
        value={htmlContent}
        readOnly={true}
        theme=''
      />: 
  <Typography variant="body2" color="textSecondary" align="center">
  No data available
</Typography>
  }

</Paper>

<Dialog
        fullScreen
        open={dialog}
        onClose={handleClose}
>
    <Toolbar style={{ justifyContent: "flex-end" }}>
        <div style={{ flex:"1" , textAlign:"center" , height:"min-content" , fontSize:"1.3rem" }}>
            Privacy Policy
        </div>
        <Button disabled={disable} onClick={savedChanges}>Save Changes</Button>
    <IconButton
     
              edge="end"
              color="inherit"
              onClick={disable === false ? unsavedChanges : handleClose}
              aria-label="close"
            >
              <CloseIcon />
    </IconButton>

    </Toolbar>
    <Editor
      maxHeight="80%"
      overflow="auto"
      minHeight="600px"
      width="100"
      content={htmlContent}
      onUpdate={(value, text) => {onChange(value , text )}}
/>

</Dialog>
   
    <Modal
    open={openDialog}
    title='Are you sure you want to save the changes?'
    onClose={handlePopupClose}
    form={<div style={{display:'flex' , justifyContent:'space-around'}}>
    <Button onClick={addPolicy}  type='button' variant="contained" color="primary">Save</Button>
    <Button onClick={handleClose} type='button' variant="contained" color="primary">Leave</Button>
        </div>}
      />
    <Modal
    open={unsaved}
    title='Do you want to save the changes before leaving this page?'
    onClose={handlePopupClose}
    form={<div style={{display:'flex' , justifyContent:'space-around'}}>
    <Button onClick={addPolicy}  type='button' variant="contained" color="primary">Save</Button>
    <Button onClick={handleClose} type='button' variant="contained" color="primary">Leave</Button>
        </div>}
      />
       <Loader loading={loading}/>
       <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default PrivacyPolicyGeneralisedComponent;