import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { GridColDef } from "@mui/x-data-grid";
import DataGridTable from "../table/DataGridTable";
import {
  generateInviteCode,
  getInviteCodeGenerators,
  ApiResponseOfGenerateInviteCode,
  ApiResponseGetInviteCode,
} from "../../app/inviteCodeGenerator/inviteCodeGenerator";
import Toaster from "../Toaster/Toaster";
import { AlertColor, Button } from "@mui/material";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

interface InviteCode {
  inviteCode: string;
  inviteCodeCreatorName: string;
  id: number;
}

const InviteCodeGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<InviteCode[]>([]);
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [buttonLoad, setButtonLoad] = useState(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })

  const getDataHandler = async () => {
    setLoading(true);
    try {
      const response: ApiResponseGetInviteCode =
        await getInviteCodeGenerators(pagination);

      const dataWithId: InviteCode[] =
        response?.data?.generatedInviteCodeResponseDtos?.map((item, index) => {
          return { ...item, id: index };
        });

      setData(dataWithId);
      setRowCount(response?.data?.totalCount || 0)
    } catch (err: any) {
      setSeverity("error");
      setOpenAlert(true);
      setMessage(err?.message || "Something went wrong !");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataHandler();
  }, [pagination]);

  const handleGenerateCode = async () => {
    setButtonLoad(true);
    const response: ApiResponseOfGenerateInviteCode =
      await generateInviteCode();
    if (response?.code === 200) {
      setSeverity("success");
      setOpenAlert(true);
      setButtonLoad(false);
      setMessage(`New Invite Code ${response?.data} Generate Successfullly!`);
      getDataHandler();
    } else {
      setSeverity("error");
      setOpenAlert(true);
      setButtonLoad(false);
      setMessage(response?.message || "Unable to generate invite code !");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "inviteCode",
      headerName: "Invite Code",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
    {
      field: "inviteCodeCreatorName",
      headerName: "Invite Code Creator Name",
      flex: 1,
      renderCell: (params) => (params.value ? params.value : "No Data"),
    },
  ];
  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }

  return (
    <Layout title="Invite Code Generator">
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "7px" }}>
        <Button
          onClick={handleGenerateCode}
          variant="contained"
          disabled={buttonLoad}
        >
          {buttonLoad ? <CircularProgress size={20} /> : "Generate"}
        </Button>
      </Box>
      <DataGridTable columns={columns} row={data} loading={loading} pagenated={pagenated} rowCount={rowCount} />
      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
    </Layout>
  );
};

export default InviteCodeGenerator;
