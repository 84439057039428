import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useRef } from "react";
import MessangeSentForm from "./MessangeSentForm";
import { Message } from "./SeederChat";
import { ChatBubbleSharp } from "@mui/icons-material";

const ChatList = ({
  chats,
  selectedUser,
  setSeverity,
  setMessage,
  setOpenAlert,
  chatStatus,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  function timeAgo(timestamp: number): string {
    const now = Date.now();
    const timeDiff = now - timestamp;

    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;

    if (timeDiff < msInMinute) {
      return "now";
    } else if (timeDiff < msInHour) {
      const minutes = Math.floor(timeDiff / msInMinute);
      return `${minutes}Min ago`;
    } else if (timeDiff < msInDay) {
      const hours = Math.floor(timeDiff / msInHour);
      return `${hours}Hr ago`;
    } else if (timeDiff < 2 * msInDay) {
      return "yesterday";
    } else {
      const days = Math.floor(timeDiff / msInDay);
      return `${days}D ago`;
    }
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#e6e8e6",
        height: "75vh",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          minHeight: "50vh",
          maxHeight: "50vh",
          backgroundColor: "#e6e8e6",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "primary.main",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: " #c9c7c7",
          },
        }}
      >
{chats?.map((item: Message) => {

  //mylogic
  const seeder = selectedUser.is_SENDER_SEEDER ? (selectedUser.is_SENDER_ALIAS ? `${selectedUser.from_CANDIDATE_ID}#AnonChat`: selectedUser.from_CANDIDATE_ID) : selectedUser?.to_CANDIDATE_ID;
  
  const user = !selectedUser.is_SENDER_SEEDER ? selectedUser.from_CANDIDATE_ID : selectedUser?.to_CANDIDATE_ID;

  // Determine the sender ID, taking into account the alias case
  const senderId = selectedUser?.is_SENDER_ALIAS 
    ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat`
    : selectedUser?.from_CANDIDATE_ID;
  
  // Determine if the current message is sent by the selected user
  const isSender = item?.fromId === (selectedUser?.is_SENDER_SEEDER ? senderId : selectedUser?.to_CANDIDATE_ID);
  
  // Set the avatar and other display properties based on whether the current user is the sender
  const bubbleColor = isSender ? "primary.main" : "white";
  const textColor = isSender ? "white" : "black";
  const borderBottomColor = isSender ? "white" : "#7a7976";
  const nameColor = isSender ? "white" : "#4a4a49";

  return (
    <Grid
      item
      key={item.id}
      xs={12}
      sx={{
        display: "flex",
        justifyContent: item?.fromId === seeder  ? "flex-end" : "flex-start",
        mt: "13px",
        mb: "2px",
      }}
    >
      {item?.fromId === (!selectedUser?.is_SENDER_SEEDER ? (selectedUser?.is_SENDER_ALIAS ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat` : selectedUser?.from_CANDIDATE_ID) : selectedUser?.is_SENDER_ALIAS ? `${selectedUser?.to_CANDIDATE_ID}#AnonChat` : selectedUser?.to_CANDIDATE_ID)  && (
        <Avatar
          alt="img"
          src={item?.fromId ===  (selectedUser?.is_SENDER_ALIAS ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat`: selectedUser?.from_CANDIDATE_ID)  ? selectedUser?.sender_PHOTO : selectedUser?.receiver_PHOTO}
          sx={{ ml: "5px", mr: "5px" }}
        />
      )}
      <Box
        sx={{
          backgroundColor: bubbleColor,
          p: "10px",
          borderRadius: "10px",
          maxWidth: "300px",
          color: textColor,
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid",
            borderBottomColor: borderBottomColor,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              mr: "15px",
              color: nameColor,
            }}
          >
            {item?.fromId === (selectedUser?.is_SENDER_ALIAS ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat` :  selectedUser?.from_CANDIDATE_ID) ? selectedUser?.sender_NAME : selectedUser?.receiver_NAME}
          </Typography>

          <Typography
            sx={{
              fontSize: "10px",
              color: textColor,
            }}
          >
            {timeAgo(Number(item?.sent))}
          </Typography>
        </Box>
        <Typography
          sx={{
            mt: "5px",
            fontSize: "14px",
            color: textColor,
          }}
        >
          {item?.msg}
        </Typography>
      </Box>
      {item?.fromId === (selectedUser?.is_SENDER_SEEDER ? (selectedUser?.is_SENDER_ALIAS ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat` : selectedUser?.from_CANDIDATE_ID) : selectedUser?.to_CANDIDATE_ID)&& (
        <Avatar
          alt="img"
          src={item?.fromId === (selectedUser?.is_SENDER_ALIAS ? `${selectedUser?.from_CANDIDATE_ID}#AnonChat`: selectedUser?.from_CANDIDATE_ID)   ? selectedUser?.sender_PHOTO : selectedUser?.receiver_PHOTO}
          sx={{
            mr: "5px",
            ml: "5px",
          }}
        />
      )}
    </Grid>
  );
})}


        <div ref={messagesEndRef} />
      </Grid>
      <Grid item xs={12} sx={{ mt: "15px" }}>
        {chatStatus?.isFirebase ? (
          <MessangeSentForm
            selectedUser={selectedUser}
            setSeverity={setSeverity}
            setMessage={setMessage}
            setOpenAlert={setOpenAlert}
          />
        ) : (
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              textAlign: "center",
              margin: "auto",
              width: "50%",
            }}
          >
            This account no longer exist
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatList;
