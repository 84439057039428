import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import {Pagable} from '../../../types/index'
import { DeleteResponse } from "../reportedComments/reportedComments";
import { PaginationInterface } from "../../../types/index";
const initialState = {
    users:null
};
export interface ReportedUsersList {
id:string,
createdAt: string,
updatedAt: string,
userId:string,
reportedUserId: string,
message: string,
reason: string,
createdBy: string 
updatedBy: string 
userName: string,
reportedUserName:string
}
interface Data{
pageNumber : number
pageSize   : number
reportedUsers : ReportedUsersList[]
totalPageSize : number

}
export interface ReportedUsers{
    message: string,
    code: number,
    data: Data
    status: boolean
  }
export const getReportedUsers = async (pagination:PaginationInterface) : Promise<ReportedUsers> =>{
    try{
        const response = await  axios.get(`report/admin/getAll?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`)
    return response.data;
    }catch(err:any){
        throw new Error(err)
    }
}

export const deleteReportedUsers= async (payload: Array<string>): Promise<DeleteResponse> => {

    try {
      const response = await axios.delete(
        `report/admin/ReportedUsers`,
        {
          data: payload,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

export const userSlice = createSlice({
    name: "ReportUsers",
    initialState,
    reducers:{},
})

