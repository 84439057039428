
import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SchoolIcon from "@mui/icons-material/School";
import ConstructionIcon from "@mui/icons-material/Construction";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import InterestsIcon from "@mui/icons-material/Interests";
import { Collapse, List } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PostAddIcon from '@mui/icons-material/PostAdd';

interface MainListItems {
  pathname: string;
  isToggleOpen:boolean;
 
}
const MainListItems: React.FC<MainListItems> = ({ pathname,isToggleOpen }) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = (e:any) => {
    e.stopPropagation()
    setOpen(!open);
  };



  const popper = {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
        {
          marginTop: "0px",
       
        },
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
        {
          marginBottom: "0px",
        
        },
      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
        {
          marginLeft: "2px",
          
        },
      [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
        {
          marginRight: "0px",
          
        },
    },
  };
  return (
    <>
      {/* <NavLink
        to="/analytics"
        style={{ textDecoration: "none", color: "black" }}
    
      >
        <Tooltip
          title= {isToggleOpen ? "":"analytics"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/analytics"}
            style={{
              backgroundColor:
                pathname === "/analytics" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="clarity:analytics-solid"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItemButton>
        </Tooltip>
      </NavLink> */}

      <NavLink to="/users" style={{ textDecoration: "none", color: "black" }}>
        <Tooltip
          title={isToggleOpen ? "": "users"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/users"}
            style={{
              backgroundColor: pathname === "/users" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      {/* <NavLink to='/networking'  style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/networking' ? true : false} style={{ backgroundColor: pathname === '/networking' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
        <Diversity3Icon/>
      </ListItemIcon>
      <ListItemText primary="Networking Goals" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/industries' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/industries' ? true : false}  style={{ backgroundColor: pathname === '/industries' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
        <ConstructionIcon/>
      </ListItemIcon>
      <ListItemText primary="Industries" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/education' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/education' ? true : false}  style={{ backgroundColor: pathname === '/education' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <SchoolIcon/>
      </ListItemIcon>
      <ListItemText primary="Education" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/college' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/college' ? true : false} style={{ backgroundColor: pathname === '/college' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <Icon  icon="fa-solid:user-graduate" style={{ fontSize: '24px' }} />
      </ListItemIcon>
      <ListItemText primary="Colleges" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/prompt' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/prompt' ? true : false} style={{ backgroundColor: pathname === '/prompt' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <Icon  icon="bxs:terminal" style={{ fontSize: '24px' }} />
      </ListItemIcon>
      <ListItemText primary="Prompts" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/politics' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton  selected={pathname === '/politics' ? true : false} style={{ backgroundColor: pathname === '/politics' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <Icon icon="map:political" style={{ fontSize: '24px' }} />
      </ListItemIcon>
      <ListItemText primary="Politics" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to="/tips" style={{ textDecoration: "none", color: "black" }}>
        <Tooltip
          title="tips"
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/tips"}
            style={{
              backgroundColor: pathname === "/tips" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="icon-park-solid:tips-one"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Tips" />
          </ListItemButton>
        </Tooltip>
      </NavLink> */}
      {/* <NavLink to='/maximize-success' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/maximize-success' ? true : false} style={{ backgroundColor: pathname === '/maximize-success' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <Icon icon="fa6-solid:maximize" style={{ fontSize: '24px' }}/>
      </ListItemIcon>
      <ListItemText primary="Maximize Success" />
    </ListItemButton>
   </NavLink> */}
    <NavLink
        to="/feed-manager"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ?"": "feed-manager"}
          placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/feed-manager"}
            style={{
              backgroundColor:
                pathname === "/feed-manager" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <DynamicFeedIcon
              style={{ fontSize: "24px" }}
              />
              {/* <Icon
                icon="material-symbols:report-off"
                style={{ fontSize: "24px" }}
              /> */}
            </ListItemIcon>
            <ListItemText primary="Feed Manager" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/post-scheduler-v2"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ?"": "post-scheduler-v2"}
          placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/post-scheduler-v2"}
            style={{
              backgroundColor:
                pathname === "/post-scheduler-v2" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
           <PostAddIcon
              style={{ fontSize: "24px" }}
              />
              {/* <Icon
                icon="material-symbols:report-off"
                style={{ fontSize: "24px" }}
              /> */}
            </ListItemIcon>
            <ListItemText primary="Post Scheduler v2" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/reported-users"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ?"": "reported-users"}
          placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/reported-users"}
            style={{
              backgroundColor:
                pathname === "/reported-users" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="material-symbols:report-off"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Reported Users" />
          </ListItemButton>
        </Tooltip>
      </NavLink>

      <NavLink
        to="/reported-posts"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ?"": "reported-posts"}
          placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/reported-posts"}
            style={{
              backgroundColor:
                pathname === "/reported-posts" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="material-symbols:post"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Reported Posts" />
          </ListItemButton>
        </Tooltip>
      </NavLink>

      <NavLink
        to="/reported-comments"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ?"": "reported-comments"}
          placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/reported-comments"}
            style={{
              backgroundColor:
                pathname === "/reported-comments" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="material-symbols:comment"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Reported Comments" />
          </ListItemButton>
        </Tooltip>
      </NavLink>

      <NavLink
        to="/reported-bugs"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ?"": "reported-bugs"}
          placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/reported-bugs"}
            style={{
              backgroundColor:
                pathname === "/reported-bugs" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="material-symbols:bug-report"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Reported Bugs" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/privacy-policy"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen ? "": "privacy-policy"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/privacy-policy"}
            style={{
              backgroundColor:
                pathname === "/privacy-policy" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon icon="ic:round-privacy-tip" style={{ fontSize: "24px" }} />
            </ListItemIcon>
            <ListItemText primary="Privacy Policy" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/terms-and-conditions"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title= { isToggleOpen ? "": "terms-and-condition"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/terms-and-conditions"}
            style={{
              backgroundColor:
                pathname === "/terms-and-conditions" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon icon="mdi:paper" style={{ fontSize: "24px" }} />
            </ListItemIcon>
            <ListItemText primary="Terms And Conditions" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/disclaimer"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen? "": "disclaimer"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/disclaimer"}
            style={{
              backgroundColor:
                pathname === "/disclaimer" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon icon="ph:warning-fill" style={{ fontSize: "24px" }} />
            </ListItemIcon>
            <ListItemText primary="Disclaimer" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/invite-code-generator"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen? "": "Invite Code Generator"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/invite-code-generator"}
            style={{
              backgroundColor:
                pathname === "/invite-code-generator" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon icon="ph:code-fill" style={{ fontSize: "24px" }} />
            </ListItemIcon>
            <ListItemText primary="Invite Code Generator" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to="/schedule-notification"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen? "":"schedule-notification" }
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/schedule-notification"}
            style={{
              backgroundColor:
                pathname === "/schedule-notification" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="mingcute:notification-fill"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Schedule Notification" />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      {/* <NavLink to='/invite-code' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/invite-code' ? true : false} style={{ backgroundColor: pathname === '/invite-code' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <Icon icon="mingcute:invite-fill" style={{ fontSize: '24px' }}  />
      </ListItemIcon>
      <ListItemText primary="Invite Code" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/feeds' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/feeds' ? true : false} style={{ backgroundColor: pathname === '/feeds' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <Icon icon="fluent:feed-32-filled" style={{ fontSize: '24px' }}  />
      </ListItemIcon>
      <ListItemText primary="Feeds" />
    </ListItemButton>
   </NavLink> */}
      {/* <NavLink to='/posts' style={{textDecoration:"none" , color:"black"}}>
   <Tooltip title="posts" placement="top">
   <ListItemButton selected={pathname === '/posts' ? true : false} style={{ backgroundColor: pathname === '/posts' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
    
     <Icon icon="mdi:local-post-office" style={{ fontSize: '24px' }}  />
     
      </ListItemIcon>
      <ListItemText primary="Posts" />
    </ListItemButton>
    </Tooltip>
   </NavLink> */}
      {/* <NavLink to='/interest' style={{textDecoration:"none" , color:"black"}}>
   <ListItemButton selected={pathname === '/Interest' ? true : false} style={{ backgroundColor: pathname === '/Interest' ? '#becbca' : 'inherit' }}>
      <ListItemIcon>
      <InterestsIcon/>
     
      </ListItemIcon>
      <ListItemText primary="Interest" style={{ fontSize: '24px' }} />
    </ListItemButton>
   </NavLink> */}
      <NavLink
        to="/community"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Tooltip
          title={isToggleOpen?"":"community"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/community"}
            style={{
              backgroundColor:
                pathname === "/community" ? "#becbca" : "inherit",
            }}
          >
            <ListItemIcon>
              <Icon
                icon="fluent:people-community-28-filled"
                style={{ fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Community" style={{ fontSize: "24px" }} />
          </ListItemButton>
        </Tooltip>
      </NavLink>
      <NavLink to="/seeders" style={{ textDecoration: "none", color: "black" }}>
        <Tooltip
          title={isToggleOpen?"":"seeders"}
         placement="right-start"
          slotProps={{
            popper,
          }}
        >
          <ListItemButton
            selected={pathname === "/seeders"}
            style={{
              backgroundColor: pathname === "/seeders" ? "#becbca" : "inherit",
            }}
            onClick={handleClick}
          >
            <ListItemIcon>
              <Icon icon="mdi:user-help" style={{ fontSize: "24px" }} />
            </ListItemIcon>
            <ListItemText primary="Seeders" style={{ fontSize: "24px" }} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </Tooltip>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              to="/posts"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip
                title={isToggleOpen?"":"Post Scheduler"}
               placement="right-start"
                slotProps={{
                  popper,
                }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={pathname === "/posts"}
                  style={{
                    backgroundColor:
                      pathname === "/posts" ? "#becbca" : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <Icon
                      icon="fa6-solid:address-card"
                      style={{ fontSize: "24px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Post Scheduler" />
                </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink
              to="/postmanager"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip
                title={isToggleOpen?"":"Post Manager"}
               placement="right-start"
                slotProps={{
                  popper,
                }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={pathname === "/postmanager"}
                  style={{
                    backgroundColor:
                      pathname === "/postmanager" ? "#becbca" : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <Icon icon="fa6-solid:bahai" style={{ fontSize: "24px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Post Manager" />
                </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink
              to="/upvotebooster"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip
                title={isToggleOpen?"":"Upvote Booster"}
               placement="right-start"
                slotProps={{
                  popper,
                }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  selected={pathname === "/upvotebooster"}
                  style={{
                    backgroundColor:
                      pathname === "/upvotebooster" ? "#becbca" : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <Icon icon="fa-arrow-up" style={{ fontSize: "24px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Upvote Booster" />
                </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink
              to="/Subreddit"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip
                title={isToggleOpen?"":"content"}
               placement="right-start"
                slotProps={{
                  popper,
                }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  // selected={pathname === "/content"}
                  // style={{
                  //   backgroundColor: pathname === "/content" ? "#becbca" : "inherit",
                  // }}
                >
                  <ListItemIcon>
                    <Icon icon="fa-list" style={{ fontSize: "15px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Content"
                    style={{ fontSize: "24px" }}
                  />
                </ListItemButton>
              </Tooltip>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    to="/Subreddit"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Tooltip
                      title={isToggleOpen?"":"Subtreddit Manager"}
                     placement="right-start"
                      slotProps={{
                        popper,
                      }}
                    >
                      <ListItemButton
                        sx={{ pl: 5 }}
                        selected={pathname === "/Subreddit"}
                        style={{
                          backgroundColor:
                            pathname === "/Subreddit" ? "#becbca" : "inherit",
                        }}
                      >
                        <ListItemIcon>
                          <Icon
                            icon="fa6-solid:table-list"
                            style={{ fontSize: "20px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Subreddit Manager" />
                      </ListItemButton>
                    </Tooltip>
                  </NavLink>
                  <NavLink
                    to="/redditContentScraper"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Tooltip
                      title={isToggleOpen?"":"Reddit Content Scraper"}
                     placement="right-start"
                      slotProps={{
                        popper,
                      }}
                    >
                      <ListItemButton
                        sx={{ pl: 5 }}
                        selected={pathname === "/redditContentScraper"}
                        style={{
                          backgroundColor:
                            pathname === "/redditContentScraper" ? "#becbca" : "inherit",
                        }}
                      >
                        <ListItemIcon>
                          <Icon
                            icon="fa6-solid:bars"
                            style={{ fontSize: "20px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Reddit Content Scraper" />
                      </ListItemButton>
                    </Tooltip>
                  </NavLink>
                
                </List>
              </Collapse>

            </NavLink>
            {/* <NavLink
                    to="/seeder-messages"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Tooltip
                      title={isToggleOpen?"":"seeder-messages"}
                     placement="right-start"
                      slotProps={{
                        popper,
                      }}
                    >
                      <ListItemButton
                        sx={{ pl: 5 }}
                        selected={pathname === "/seeder-messages"}
                        style={{
                          backgroundColor:
                            pathname === "/seeder-messages" ? "#becbca" : "inherit",
                        }}
                      >
                        <ListItemIcon>
                        <Icon icon="jam:messages-alt-f" style={{ fontSize: '27px' }}  />
                        </ListItemIcon>
                        <ListItemText primary="seeder messages" />
                      </ListItemButton>
                    </Tooltip>
                  </NavLink> */}
                  <NavLink
                    to="/seeder-chat"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Tooltip
                      title={isToggleOpen?"":"seeder-chat"}
                     placement="right-start"
                      slotProps={{
                        popper,
                      }}
                    >
                      <ListItemButton
                        sx={{ pl: 5 }}
                        selected={pathname === "/seeder-chat"}
                        style={{
                          backgroundColor:
                            pathname === "/seeder-chat" ? "#becbca" : "inherit",
                        }}
                      >
                        <ListItemIcon>
                        <Icon icon="fa6-solid:message" style={{ fontSize: '16px' }}  />
                        </ListItemIcon>
                        <ListItemText primary="seeder messages" />
                      </ListItemButton>
                    </Tooltip>
                  </NavLink>
          </List>
        </Collapse>
    
      </NavLink>
    </>
  );
};
export default MainListItems;

