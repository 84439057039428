import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { BasePayload } from "../../../types/common";
import {Pagable} from '../../../types/index'
import { message } from "../../../types/common";
const initialState = {};
export interface PoliticsList{
  createdAt: string
  createdBy: string
  id: string
  name: string
  updatedAt : string
  updatedBy: string
}
export interface politicsResponse{
  size:number
  list:PoliticsList[];

}

export interface Politics{
  message: string,
  code: number,
  data: politicsResponse
  status: boolean
}
export const getPoliticsAction = async () : Promise<Politics> =>{
  try{
    const response = await  axios.get(`politics/admin/getAll`)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
}

export const addPoliticsAction = async (payload: BasePayload) : Promise<message> =>{
  const body = JSON.stringify(payload)
  try{
    const response = await  axios.post(`politics/add`,body)
  return response.data;
  }catch(err:any){
    throw new Error(err)
  }
}
export const editPoliticsAction =async (payload: BasePayload) : Promise<message> =>{
  const body = JSON.stringify(payload)
try{
  const response = await  axios.put(`politics/update`,body)
  return response.data;
}catch(err:any){
  throw new Error(err)
}
}
export const deletePoliticsAction = async (id : string) : Promise<message> =>{
 try{
  const response = await  axios.delete(`politics/${id}`)
  return response.data;
 }catch(err:any){
  throw new Error(err)
 }
}
export const politicsSlice = createSlice({
    name: "Politics",
    initialState,
    reducers:{},
})

