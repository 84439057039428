import React, { useState } from "react";
import { Layout } from "../../../layout/Layout";
import RedditContentScraperForm from "./RedditContentScraperForm";
import { Typography } from "@mui/material";
import RedditContentScraperCard from "./RedditContentScraperCard";
import Modal from "../../Modal/Modal";
import SchedulePostForm from "./SchedulePostForm";
import Toaster from "../../Toaster/Toaster";
import { AlertColor } from "@mui/material";
import Loader from "../../Loader/Loader";

const RedditContentScraper = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewData, setViewData] = useState({});

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <Layout title="Reddit Content Scraper">
      <Typography sx={{ fontWeight: "500", mb: "10px", fontSize: "20px" }}>
        Reddit Content Scraper
      </Typography>
      <RedditContentScraperForm
        setData={setData}
        setLoading={setLoading}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
        setMessage={setMessage}
      />
      <RedditContentScraperCard
        posts={data}
        setOpenModal={setOpenModal}
        setViewData={setViewData}
      />
      <Modal
        open={openModal}
        title="Schedule Post"
        onClose={closeModal}
        form={
          <SchedulePostForm
            setLoading={setLoading}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
            setMessage={setMessage}
            loading={loading}
            viewData={viewData}
            setOpenModal={setOpenModal}
          />
        }
        maxWidth="sm"
        fullWidth={false}
      />
      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
      <Loader loading={loading} />
    </Layout>
  );
};

export default RedditContentScraper;
