import * as React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useRoutes,
  redirect,
  useLocation,
} from "react-router-dom";
import Auth from "../pages/Auth";
import Users from "../components/user/Users";
import NetworkingGoals from "../components/NetworkingGoals/NetworkingGoals";
import Education from "../components/Education/Education";
import Industries from "../components/Industries/Industries";
import { useAppSelector } from "../app/hooks";
import Politics from "../components/Politics/Politics";
import Prompt from "../components/Prompt/Prompt";
import College from "../components/College/College";
import Tips from "../components/Tips/Tips";
import MaximizeSuccess from "../components/MaximizeSuccess/MaximizeSuccess";
import ReportUser from "../components/ReportedUser/ReportedUser";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import NotificationScheduler from "../components/NotificationScheduler/NotificationScheduler";
import TermsAndCondition from "../components/TermsAndConditions/TermsAndConditions";
import Disclaimer from "../components/Disclaimer/Disclaimer";
import Analytics from "../components/Analytics/Analytics";
import InviteCode from "../components/InviteCode/InviteCode";
import Feeds from "../components/Feeds/Feeds";
import { selectAuthState } from "../app/auth/auth";
import CheckToken from "../components/CheckToken/CheckToken";
import NotFound from "../components/NotFound/NotFound";
import Post from "../components/Post/Post";
import Interest from "../components/Interest/Interest";
import Community from "../components/Community/Community";
import Moderators from "../components/Community/SubCommunity/Moderators/Moderators";
import ApprovedUsers from "../components/Community/SubCommunity/ApprovedUser/ApprovedUser";
import Seeders from "../components/Seeders/Seeders";
import PostManager from "../components/PostManager/PostManager";
import UpVoteBoosterForm from "../components/UpvoteBooster/UpVoteBoosterForm";
import SeederMessages from '../components/SeederMessages/SeederMessages';
import ComposeMessage from '../components/SeederMessages/ComposeMessage';
import SubredditManager from "../components/Content/SubredditManager";
import RedditContentScraper from "../components/Content/RedditContentScraper/RedditContentScraper";
import ReportedPostTable from "../components/ReportedPost/ReportedPostTable";
import ReportedCommentsTable from "../components/ReportedComments/ReportedCommentsTable";
import ReportedBugs from "../components/ReportedBug/ReportedBugs";
import SeederChat from "../components/SeederChat/SeederChat";
import InviteCodeGenerator from "../components/InviteCodeGenerator/InviteCodeGenerator";
import GoogleAuthenticator from "../pages/GoggleAuthenticator";
import { useSelector } from "react-redux";
import FeedManager from "../components/FeedManager/FeedManager";
import EditCommunityMembers from "../components/EditCommunityMembers/editCommunityMembers";
import PostSchedulerv2 from "../components/PostSchedulerv2/PostSchedulerV2";

export const pages = [
  { path: "/", exact: true, component: Users, auth: true },
  { path: "/analytics", exact: true, component: Analytics, auth: true },
  { path: "/authenticate", exact: true, component: GoogleAuthenticator },
  // { path: "/interest", exact: true, component:Interest , auth:true },
  { path: "/users", exact: true, component: Users, auth: true },
  { path: "/feed-manager", exact: true, component: FeedManager, auth: true },
  // { path: "/networking", exact: true, component: NetworkingGoals, auth:true },
  // { path: "/industries", exact: true, component:Industries , auth:true },
  // { path: "/education", exact: true, component: Education, auth:true },
  // { path: "/college", exact: true, component: College, auth:true },
  // { path: "/prompt", exact: true, component: Prompt, auth:true },
  // { path: "/politics", exact: true, component: Politics, auth:true },
  { path: "/tips", exact: true, component: Tips, auth: true },
  // { path: "/maximize-success", exact: true, component:MaximizeSuccess , auth:true },
  { path: "/login", exact: true, component: Auth },
  { path: "/reported-users", exact: true, component: ReportUser, auth: true },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicy,
    auth: true,
  },
  {
    path: "/schedule-notification",
    exact: true,
    component: NotificationScheduler,
    auth: true,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    component: TermsAndCondition,
    auth: true,
  },
  { path: "/disclaimer", exact: true, component: Disclaimer, auth: true },
  // { path: "/invite-code", exact: true, component:InviteCode , auth:true },
  // { path: "/feeds", exact: true, component:Feeds , auth:true },
  { path: "/posts", exact: true, component:Post , auth:true },
  { path: "/community", exact: true, component:Community , auth:true },
  {path: "/approved-users", exact: true, component:ApprovedUsers , auth:true },
  {path: "/moderator", exact: true, component:Moderators , auth:true },
  {path: "/postmanager", exact: true, component:PostManager, auth:true },
  {path: "/seeders", exact : true, component:Seeders , auth:true },
  {path: "/seeder-messages", exact : true, component:SeederMessages , auth:true },
  {path: "/seeder-chat", exact : true, component:SeederChat , auth:true },
  {path: "/compose-message", exact : true, component:ComposeMessage , auth:true },
  {path: "/post-scheduler-v2", exact : true, component:PostSchedulerv2 , auth:true },
  {path: "/edit-community-members", exact : true, component:EditCommunityMembers , auth:true },
  {
    path: "/upvotebooster",
    exact: true,
    component: UpVoteBoosterForm,
    auth: true,
  },
  // { path: "/seeders", exact: true, component: Seeders, auth: true },
  { path: "/Subreddit", exact: true, component: SubredditManager, auth: true },
  
  { path: "/redditContentScraper", exact: true, component: RedditContentScraper, auth: true },
  { path: "/reported-posts", exact: true, component: ReportedPostTable, auth: true },
  { path: "/reported-comments", exact: true, component: ReportedCommentsTable, auth: true },
  { path: "/reported-bugs", exact: true, component: ReportedBugs, auth: true },
  { path: "/invite-code-generator", exact: true, component: InviteCodeGenerator, auth: true },
  { path: "*", component: NotFound },
];

export default function MyRoutes() {
  let token = useAppSelector(selectAuthState);
  const basename = process.env.REACT_APP_BASE_PATH || "";
  const {is2FaEnabled, is2FaVerified} = useSelector((state: any) => state?.twoFactorAuthenticationReducer);

  return (
    <BrowserRouter basename={basename}>
      <CheckToken> 
        <Routes>
          {pages?.map(({ component: Component, path, auth }) => {
            if (auth) {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    (token && is2FaVerified) || (token && !is2FaEnabled) ? <Component /> : <Navigate to="/login" />
                  }
                />
              );
            }else if(path == '/authenticate') {

              return <Route key={path} path={path} element={token && is2FaEnabled && !is2FaVerified ? <Component />: <Navigate to="/" />} />;
            } else {
              return <Route key={path} path={path} element={<Component />} />;
            }
          })}
        </Routes>
      </CheckToken>
    </BrowserRouter>
  );
}
