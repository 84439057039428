import React from 'react'
import { Layout } from "../../layout/Layout"
import GenericForm from "../form/GenericForm"
import { FeedSetting } from '../../enum'
import Box from '@mui/material/Box';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import Typography from '@mui/material/Typography';
import { getFeedManagerAction ,editFeedManagerAction, FeedManagerResponse } from '../../app/feedmanager/feedManagerSlice';
import { AlertColor } from '@mui/material';
interface FeedDeatils {
    kvalue:number,
    wvalue:number,
    feedSetting:string,
    id:string
}

export default function FeedManager(){
const [loading , setLoading] = React.useState<boolean>(false);
const [data, setData] = React.useState<FeedManagerResponse[]>([]);
const [openAlert, setOpenAlert] = React.useState(false);
const [message, setMessage] = React.useState("");
const [severity , setSeverity] = React.useState('');
const [toggle , setToggle] = React.useState<boolean>(false);
const [editFeedDetails , setEditFeedDetails] = React.useState<FeedDeatils | undefined>(undefined);
React.useEffect(() =>{
getFeedManager();
},[toggle])
React.useEffect(() => {
    setEditFeedDetails(data?.length > 0 ? {
      kvalue: data[0]?.kvalue ?? 0,  
      wvalue: data[0]?.wvalue ?? 0,  
      feedSetting: "HOT",
      id: data[0]?.id || "",
    } : undefined);  // Set to undefined if no data
  }, [data]);
function getFeedManager(){
    setLoading(true);
    // Fetch user data from an API when the component mounts
    getFeedManagerAction()
      .then((res) => {
        setLoading(false);
        if(!res.status){
            setSeverity('error')
            setOpenAlert(true)
            setMessage(res ? res?.message : 'Error')
            setLoading(false);
        }else{
            setData(res?.data || []);    
        }     
      })
      .catch((err) => {
            setSeverity('error')
            setOpenAlert(true)
            setMessage(err ? err.message : 'Error')
            setLoading(false);
      });

}

const editFeedManager = async(values : any) =>{
    setLoading(true)
    const payload = {
        ...values,
        id:data[0]?.id || "",
        kvalue:values?.kvalue || "",
        wvalue:values?.wvalue || "",
    }
    editFeedManagerAction(payload)
    .then((res) =>{
     if(res?.status === false){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Record not found')
     }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'College Updated Successfully')
      setToggle(!toggle)
     }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
    })
  }
const initialvalues = {
    kvalue: data[0]?.kvalue ?? 0,  // Ensure default number
    wvalue: data[0]?.wvalue ?? 0,  // Ensure default number
    feedSetting: "HOT",
    id: data[0]?.id || ""
} 

const items = [
    {
      candidate_LINKED_IN_ID:FeedSetting.Hot,
  
      key:FeedSetting.Hot
    },
  
  ]

const feedManagerFields = [
        {
            name: 'kvalue',
            label: 'K Value (Decay factor)',
            type:'number',
            required:true,
             step:'any'
        },  
        {
            name: 'wvalue',
            label: 'W Value (Weight for Comments)',
            type:'number',
            required:true  ,
            step:'any'  
        }, 
        {
            name: 'feedSetting',
            label: 'Default Feed Setting:',
            type:'select',
            items:items,
            key:'candidate_LINKED_IN_ID',
            required:true,
            default:true     
        },
];
function handleSubmit(values){
    editFeedManager(values)
}
return (
    <Layout title="Feed Manager">
     <GenericForm key={JSON.stringify(editFeedDetails)} title='Save' fields={feedManagerFields} editedDetails={editFeedDetails} initialValues={initialvalues} onSubmit={handleSubmit} />
        <Box component="section" sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom>
        Current Formula
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
        ((Reactions + 1) * (Comments * {data[0]?.wvalue || ""} + 1) / (Time Since Posted in Hours)^{data[0]?.kvalue || ""})
      </Typography>
        </Box>
        <Loader loading={loading}/>
      <Toaster handleClose={() =>  setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
    </Layout>
    )
}