import axios from "../../interceptor/axiosInterceptor";
import {
  UpvoteBoosterPayload,
  UpvoteBoosterResponse,
} from "../../components/form/type";

export const upvoteBoosterPost = async (
  payload: UpvoteBoosterPayload
): Promise<UpvoteBoosterResponse<Array<string>>> => {
  const body = JSON.stringify(payload);
  try {
    const response = await axios.post(
      `linkedin-candidate/seeder/addUpVoteBooster`,
      body
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
