import { createSlice } from "@reduxjs/toolkit";
import axios from "../../interceptor/axiosInterceptor";
import { AddPolicy , PolicyPayload} from "../../../types/common";

const initialState = {
};



export const getPrivacyPolicy = async () : Promise<string> =>{
   try{
    const response = await  axios.get(`policy/PRIVACYPOLICY`)
    return response.data;
   }catch(err:any){
    throw new Error(err)
   }
}

export const addPrivacyPolicy = async (payload: PolicyPayload) : Promise<AddPolicy> =>{
    const body = JSON.stringify(payload)
  try{
    const response = await  axios.post(`policy/add`,body)
    return response.data;
  }catch(err:any){
    throw new Error(err)
  }
  }
export const privacySlice = createSlice({
    name: "PrivacyPolicy",
    initialState,
    reducers:{},
})

