import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import TipsGeneralisedComponent from './TipsGenralisedComponent'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {Type} from '../../enum/index'
export default function Tips() {
    const [addModal , setAddModal] = React.useState<boolean>(false)
    const [editModal, setEditModal] = React.useState(false);
    const [promptId, setPromptId] = React.useState<string>("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [operationType , setOperationType] = React.useState("")
    const [editTipsDetails , setEditTipsDetails] = React.useState<GridRowParams | null>(null)

const closeModal = () =>{
        setAddModal(false)
        setEditModal(false)
        setDeleteModal(false)
}
const editPromptModal = (params :GridRowParams) =>{
  setOperationType(Type.Edit)
    setEditModal(true)
    setEditTipsDetails(params ? params?.row : [])

}
function deletePromptModal(id: string) {
  setPromptId(id)
  setDeleteModal(true)
}
const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'body', headerName: 'Body', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data') },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deletePromptModal(params?.row?.id)}}
          />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          showInMenu
          onClick={() => {editPromptModal(params)} }
        />,
      ];
    },
  }

]
  return (
  <>
  <Layout  title="Tips">
    <div>
    <div style={{ textAlign: "right", marginBottom: "10px", outline: 'none' }}>   <Button onClick={() => {setAddModal(true), setOperationType(Type.Add)}} variant="contained">Add Tip</Button></div>
        <TipsGeneralisedComponent operationType={operationType} promptId={promptId} deletePromptModal={deleteModal}  editDetails={editTipsDetails}  editPromptModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}  />
    </div>
  </Layout>
  </>
  )
}
