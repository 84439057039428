import React from 'react'
import { GridRowParams , GridColDef , GridActionsCellItem  } from '@mui/x-data-grid'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import ReportUserGeneralisedComponent from './ReportedUserGeneralisedComponent'
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
export default function ReportUser() {

const columns: GridColDef[] = [
  { field: 'userName', headerName: 'User Name', flex: 1 ,renderCell: (params) => (params.value ? params.value : 'No Data') },
  { field: 'reportedUserName', headerName: 'Reported User Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'message', headerName: 'Message', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'reason', headerName: 'Reason', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
]
  return (
  <>
  <Layout  title="Reported-Users">
    <div>
        <ReportUserGeneralisedComponent  columns={columns}   />
    </div>
  </Layout>
  </>
  )
}
