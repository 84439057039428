import React, { useEffect, useState } from "react";
import DataGridTable from "../table/DataGridTable";
import { GridColDef } from "@mui/x-data-grid";
import {
  deleteReportedUsers,
  getReportedUsers,
} from "../../app/reportusers/reportusers";
import Loader from "../Loader/Loader";
import { ReportedUsersList } from "../../app/reportusers/reportusers";
import Toaster from "../Toaster/Toaster";
import { AlertColor, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DeleteResponse } from "../../app/reportedComments/reportedComments";
import DeleteModal from "../Modal/DeleteModal";

interface ReportedUserProps {
  columns: GridColDef[];
}

const ReportUserGeneralisedComponent: React.FC<ReportedUserProps> = ({
  columns,
}) => {
  const [prompt, setPrompt] = useState<Array<ReportedUsersList>>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [selected, setSelected] = useState<string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })

  const getDataHandler = async () => {
    setDataLoading(true);
    try {
      const response = await getReportedUsers(pagination);
      setPrompt(response?.data?.reportedUsers || []);
      setRowCount(response?.data?.totalPageSize || 0)
    } catch (err: any) {
      setSeverity("error");
      setOpenAlert(true);
      setMessage(err?.message || "Something went wrong !");
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getDataHandler();
  }, [toggle , pagination]);

  const handleDelete = () => {
    setOpenDeletePopup(true);
  };

  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "7px" }}>
        <Button
          onClick={handleDelete}
          disabled={selected?.length > 0 ? false : true}
          variant="outlined"
          color="error"
        >
          Delete
        </Button>
      </Box>
      <DataGridTable
        columns={columns}
        row={prompt}
        checkboxSelection={true}
        setSelected={setSelected}
        loading={dataLoading}
        pagenated={pagenated} 
        rowCount={rowCount}
      />
      <Loader loading={loading} />
      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
      <DeleteModal
        description={"you want to delete selected reported users?"}
        open={openDeletePopup}
        handleClose={() => {
          setOpenDeletePopup(false);
        }}
        handleDelete={async () => {
          setLoading(true);
          const payload = selected;
          const response: DeleteResponse = await deleteReportedUsers(payload);
          if (response?.code === 200) {
            getDataHandler();
            setSeverity("success");
            setOpenAlert(true);
            setMessage(
              response?.message || "Reported user delete Successfully."
            );
          } else {
            setOpenAlert(true);
            setSeverity("error");
            setMessage(response?.message || "Unable to delete reported user.");
          }
          setOpenDeletePopup(false);
          setLoading(false);
         
        }}
      />
    </>
  );
};
export default ReportUserGeneralisedComponent;
