import axios from "../../interceptor/axiosInterceptor";
import { PaginationInterface } from "../../../types";

export interface ApiResponseOfGenerateInviteCode {
  message: string;
  code: number;
  data: string;
  status: boolean;
}
interface InviteCodeResponse {
  inviteCode: string;
  inviteCodeCreatorName: string;
}

interface Data {
  size: number;
  generatedInviteCodeResponseDtos: InviteCodeResponse[];
  totalCount: number;
}

export interface ApiResponseGetInviteCode {
  message: string;
  code: number;
  data: Data;
  status: boolean;
}

export const getInviteCodeGenerators = async (pagination:PaginationInterface): Promise<ApiResponseGetInviteCode> => {
  try {
    const response = await axios.get(
      `linkedin-candidate/getAll-invite-codes?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`
    );

    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const generateInviteCode =
  async (): Promise<ApiResponseOfGenerateInviteCode> => {
    try {
      const response = await axios.get(
        `linkedin-candidate/invite-code-generator`
      );

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };
