import React, {useState , useEffect} from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import {  GridColDef,GridRowParams } from '@mui/x-data-grid';
import { addIndustry, editIndustry, getIndustry, deleteIndustry } from '../../app/industry/industry';
import { Delete as DeleteIcon} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import {Type} from '../../enum/index'
import { Payload } from '../../../types/common'
import { IndustryList } from '../../app/industry/industry';
import { nameAndDescSchema } from '../../schema/common';
import { addSeederAction, editSeederAction, getSeederAction , deleteSeederAction } from '../../app/seeders/seeders';
import { getCommunityAction } from '../../app/community/community';
import { useAppSelector , useAppDispatch } from '../../app/hooks';
import { selectSeederState } from '../../app/seeders/seeders';
import { setCommunitie } from '../../app/seeders/seeders';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import { seederSchema } from '../../schema/seeders';
import { SeederUsersList } from '../../app/seeders/seeders';
import { doc, setDoc , addDoc , collection , updateDoc , getDoc} from "firebase/firestore";
import {db} from '../../firebase'
import * as Yup from "yup";
import DeleteModal from '../Modal/DeleteModal';
import dayjs from 'dayjs';
interface EditDetails{
    aboutMe: string;
    anonymousName: string;
    communities: string[];
    email: string;
    firstName: string;
    id: string;
    isSeeder: boolean;
    lastName: string;
    photoUrl: string;
    birthday: string | null; 

}
interface IndustryProps{
  addModal:boolean
  closeModal:() => void
  columns : GridColDef[]
  editIndustryModal:boolean
  editDetails:EditDetails
  deleteIndustryModal:boolean
  industryId:string
}
interface Community {
  id: string;
  communityName: string;
  photoUrl: string;
  description: string;
  communityType: string
}
interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  photoUrl: string;
  aboutMe: string;
  anonymousName: string;
  isSeeder: boolean;
  totalRatingUser: number;
  communities: Community[];
  aliasUrl:string
}
const  SeedersGeneralisedComponent: React.FC<IndustryProps> = ({industryId,deleteIndustryModal,addModal ,closeModal , editDetails, columns , editIndustryModal}) => {
  const [industry, setIndustry] =  useState<Array<SeederUsersList>>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('') 
  const [rows , setRows] = React.useState<number|any>() 
  const [communities , setCommunities] = React.useState<any>([])
  const [filteredValue,setFilteredValue]= useState<string>("");
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const dispatch = useAppDispatch();
  const communitiesData = useAppSelector(selectSeederState).communitites
  const actionType = useAppSelector(selectSeederState).actionType

 
  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getSeederAction(pagination , filteredValue)
    .then((res) =>{
      if(!res.status){
        setDataLoading(false);
      }else{
        setIndustry(res?.data?.seederCandidateListResponseDtos ? res?.data?.seederCandidateListResponseDtos : [])
        setRows(res?.data?.totalCount ? res?.data?.totalCount : "")
        setDataLoading(false)
      }    
    })
    .catch((err) =>{
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
      setDataLoading(false);
    })
 
  }, [toggle , pagination , filteredValue]);

const initialvalues = {
firstName:"",
lastName:"",
anonymousName:"",
aboutMe:"",
communities:null,
email:"",
photoUrl:"",
myFile:undefined,
birthday:null,
aliasUrl:"",
} 

const addUser = async (values:any) =>{
  const now = new Date();
  const milliseconds = now.getTime();
  try{
  
    const docRef = doc(db, 'network_users', values?.id); // Create a document reference with the custom ID

    await setDoc(docRef, {
      id:`${values?.id}`,
      name: `${values?.fullName}` ,
      about:`${values?.aboutMe}`,
      aliasPhoto:`${values?.aliasUrl}`,
      email:`${values?.email}`,
      anonName:`${values?.anonymousName}`,
      push_token:`Take FCM Token From Login API Response`,
      image:`${values?.photoUrl}`,
      created_at:milliseconds ? String(milliseconds) : "",
      is_online:true,
      last_active:milliseconds ? String(milliseconds) : "",
      isSeeder:true

    });
  }catch(err:any){
    throw new Error(err)
  }
}
const addAnonUser = async (values:User) =>{
  const now = new Date();
  const milliseconds = now.getTime();
  try{
  
    const docRef = doc(db, 'network_users', `${values?.id}#AnonChat`); // Create a document reference with the custom ID

    await setDoc(docRef, {
      id:`${values?.id}#AnonChat`,
      name: `${values?.fullName}` ,
      about:`${values?.aboutMe}`,
      email:`${values?.email}`,
      anonName:`${values?.anonymousName}`,
      aliasPhoto:`${values?.aliasUrl}`,
      push_token:`Take FCM Token From Login API Response`,
      image:`${values?.photoUrl}`,
      created_at:milliseconds ? String(milliseconds) : "",
      is_online:true,
      last_active:milliseconds ? String(milliseconds) : "",
      isSeeder:true
    });
  }catch(err:any){
    throw new Error(err)
  }
 }
const closeDropdown = () =>{
  // if(actionType === 'Edit'){
  //   setCommunities(communities)
  // }
  setCommunities([])
}
const addSeeder = async(values:any) =>{
  setLoading(true)
  let url = values['photoUrl'] ? values['photoUrl'] .split(',') : ""
  values['photoUrl'] = url[1]
  let aliasurl = values['aliasUrl'] ? values['aliasUrl'] .split(',') : ""
  values['aliasUrl'] = aliasurl[1]
  values['birthday'] = dayjs(values?.birthday).format("YYYY")

//Call Api To Add User
addSeederAction(values)
.then((res) =>{
  if(!res?.status){
  setLoading(false)
  setSeverity('error')
  setOpenAlert(true)
  setMessage( res?.message ? res.message : 'Request failed due to duplicity')
  }else{
  addUser(res?.data)
  .then(() =>{
    addAnonUser(res?.data)
    .then(() =>{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Successfully Added')
      setToggle(!toggle)
    }).catch((err:any) =>{
      deleteSeederById(res?.data?.id ? res?.data?.id : "" , 'firebase')
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage('Error while adding seeder')
    })
   
  })
  .catch((err:any) =>{
    deleteSeederById(res?.data?.id ? res?.data?.id : "" , 'firebase')
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage('Error while adding seeder')
  })

  }
  
})
.catch((err) =>{
  setLoading(false)
  setSeverity('error')
  setOpenAlert(true)
  setMessage(err ? err.message : "Error")
 
})
}
const getComunities = (name:string) =>{
  setLoading(true)
  getCommunityAction(pagination)
  .then((res) =>{
    setLoading(false)
    setCommunities(res.data?.list ? res.data?.list : []);
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err?.message : 'Error')
  })
}
const updateDocumentField = async (documentId, fieldName, newValue) => {
  const docRef = doc(db, "network_users", documentId);
  try {
      await updateDoc(docRef,{
          [fieldName]: newValue
      });
      console.log("Document successfully updated!");
  } catch (error) {
      console.error("Error updating document: ", error);
  }
};

const editSeeder = async(values : any) =>{
  setLoading(true)
//Call Api To Edit User
let url = values['photoUrl'] ? values['photoUrl'] .split(',') : ""
values['photoUrl'] = url[1]
let aliasurl = values['aliasUrl'] ? values['aliasUrl'] .split(',') : ""
values['aliasUrl'] = aliasurl[1]
values['birthday'] = values?.birthday ? dayjs(values?.birthday).format("YYYY"):editDetails?.birthday
editSeederAction(values)
.then((res) =>{
    if(!res?.status){
      setSeverity('error')
        setOpenAlert(true)
        setMessage(res?.message ? res.message : 'Record not found') 
        setLoading(false)
    }else{
      updateDocumentField(res?.data?.id ? res?.data?.id  : "" , 'image' , res?.data?.photoUrl ? res?.data?.photoUrl : "")
      updateDocumentField(`${res?.data?.id ? res?.data?.id  : ""}#AnonChat` , 'image' , res?.data?.aliasUrl ? res?.data?.aliasUrl : "")
      updateDocumentField(res?.data?.id ? res?.data?.id  : "" , 'name' , res?.data?.fullName)
      updateDocumentField(`${res?.data?.id ? res?.data?.id  : ""}#AnonChat` , 'name' , res?.data?.fullName)
      updateDocumentField(res?.data?.id ? res?.data?.id  : "" , 'anonName' , res?.data?.anonymousName)
      updateDocumentField(`${res?.data?.id ? res?.data?.id  : ""}#AnonChat` , 'anonName' , res?.data?.anonymousName)
      updateDocumentField(res?.data?.id ? res?.data?.id  : "" , 'about' , res?.data?.aboutMe)
      updateDocumentField(`${res?.data?.id ? res?.data?.id  : ""}#AnonChat` , 'about' , res?.data?.aboutMe)
      setSeverity('success')
        setOpenAlert(true)
        setMessage(res?.message ? res.message : 'Successfully Updated')
        setToggle(!toggle)   
        setLoading(false)
    }
})
.catch((err) =>{
  setSeverity('error')
  setOpenAlert(true)
  setMessage(err ? err.message : "Error")
  setLoading(false)
})
}

const deleteSeederById = async(id : string , isFirebase:string) =>{
  setLoading(true)
  //Call Api To Edit User
  deleteSeederAction(id)
  .then((res) =>{
    if(!res?.status){
    setSeverity('error')
    setOpenAlert(true)
    setMessage(res?.message ? res.message : 'Record not found')
    setLoading(false)
    }else{
    if(!isFirebase){
      setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Successfully Deleted')
      setToggle(!toggle)
      setLoading(false)
    }
    }
  })
  .catch((err) =>{
    setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
      setLoading(false)
  })
  }

  const validation = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("last Name is required"),
    anonymousName: Yup.string().required("Burner Name is required"),
    email: Yup.string().required("email is required"),
    birthday:Yup.date().required("Birth year required.")
  });

const addModalFields = [
    {
      name: 'firstName',
      label: 'FirstName',
      type:'text',
      required:true
      
    },
    {
      name: 'lastName',
      label: 'LastName',
      type:'text',
      required:true
      
    },
    {
      name: 'communities',
      label: 'Communities',
      type:'dropDown',
      items:communities,
      key:'communityName',
      callBack:getComunities,
      close:closeDropdown 
    },
    {
        name: 'anonymousName',
        label: 'Burner Username',
        type:'text',
        required:true
        
    },
    {
      name: 'email',
      label: 'Email',
      type:'email',
      required:true
    },
    {
      label: "Birth Year",
      name: "birthday",
      type: "DatePicker",
      disableTimePicker:true,
      datePickerFormat:"YYYY",
      datePickerView:["year"],
      maxDate:dayjs(`2004-12-31`),
      minDate:dayjs(`1997-01-01`),
      required:true
    },
    {
      name: 'aboutMe',
      label: 'AboutMe',
      type:'textarea',
      required:true
      
    },
    {
      name: 'aliasFile',
      label: 'upload alias pic',
      type:'alias'
      
  },
    {
      name: 'myFile',
      label: 'upload pic',
      type:'file'    
  },   
  ];


  const editModalFields = [
    {
      name: 'firstName',
      label: 'FirstName',
      type:'text',
      required:true
      
    },
    {
      name: 'lastName',
      label: 'LastName',
      type:'text',
      required:true
      
    },
    {
      name: 'communities',
      label: 'Communities',
      type:'dropDown',
      items:communities,
      key:'communityName',
      callBack:getComunities,
      close:closeDropdown, 
    },
    {
        name: 'anonymousName',
        label: 'Burner Username',
        type:'text',
        required:true
        
    },
    {
      name: 'email',
      label: 'Email',
      type:'email',
      required:true
    },
    {
      label: "Birth Year",
      name: "birthday",
      type: "DatePicker",
      disableTimePicker:true,
      datePickerFormat:"YYYY",
      datePickerView:["year"],
      defaultValue:dayjs(`${editDetails?.birthday}-01-01`),
      maxDate:dayjs(`2004-12-31`),
      minDate:dayjs(`1997-01-01`),
    },
    {
      name: 'aboutMe',
      label: 'AboutMe',
      type:'textarea',
      required:true
      
    },
    {
      name: 'aliasFile',
      label: 'upload alias pic',
      type:'alias'
      
  },
    {
      name: 'myFile',
      label: 'upload pic',
      type:'file'
      
  }
    
  ];
  function pagenated(pageNo:number , pageSize:number){
    setpagination(() =>{
      return {
        pageNo:pageNo,
        pageSize:pageSize
      }
    })
  }

  const handleSubmit = (values : Payload , type : string)=> {
    // Handle form submission here
    if(type === Type.Add){
      closeModal()
      addSeeder(values)

    }else if(type === Type.Edit){
      closeModal()
      editSeeder(values)
    }
    
  };
  function handleFilter(value:string){
    setFilteredValue(value ?  value : "")
   }
  return (
    <>
   <DataGridTable handleFilter={handleFilter} columns={columns} row={industry} loading={dataLoading} pagenated={pagenated} rowCount={rows} />
     <Modal
      open={addModal}
      title="Add New Seeder"
      onClose={closeModal}
      form={<GenericForm title='Add Seeder'  imageUploadField='photoUrl'   type='Add Seeder'  validationSchema={validation} fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
    />
       {/* Edit Modal */}
       <Modal
              open={editIndustryModal}
              title="Edit Seeder"
              onClose={closeModal}
              form={<GenericForm title='Save'  imageUploadField='photoUrl' editedDetails={{...editDetails,birthday:dayjs(`${editDetails?.birthday}-01-01`)}} type='Edit Seeder' fields={editModalFields} initialValues={initialvalues}    onSubmit={handleSubmit}></GenericForm>}
      />
     
{/*   
  delete modal */}
    <DeleteModal description={"you want to delete this seeders."} open={deleteIndustryModal} handleClose={closeModal} handleDelete={()=>{
        deleteSeederById(industryId , "");
        closeModal();
      }} />
      <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
    </>

     
  )
}
export default SeedersGeneralisedComponent;