import React from 'react'
import { Layout } from '../../layout/Layout'
import GenericForm from '../form/GenericForm'
import { getSeederAction } from '../../app/seeders/seeders';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import { sendSingleNotification , updateHasMessage } from '../../app/seeders/seeders';
import { getAllNonSeeders } from '../../app/users/users';
import { doc, setDoc , addDoc , collection , updateDoc , getDoc} from "firebase/firestore";
import {db} from '../../firebase'
import { message } from '../../../types/common';

interface Message {
  seeder: string;
  user: string;
  Message: string;
}
interface UserProfile {
  id: string;
  email: string;
  full_name: string;
  photo_url: string | null;
  anonymous_name: string | null;
}
interface Community {
  id: string;
  communityName: string;
  photoUrl: string;
  description: string;
  communityType: string;
}
interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  photoUrl: string;
  aboutMe: string;
  anonymousName: string;
  isSeeder: boolean;
  totalRatingUser: number;
  communities: Community[];
}

interface UserDetails {
  id: string;
  email: string;
  full_name: string;
  photo_url: string;
  anonymous_name: string | null;
  is_anon_member: boolean;
}

export default function ComposeMessage() {
    const [seeders , setSeeders] = React.useState<any>([]);
    const [users  , setUsers] = React.useState<Array<UserProfile>>([]);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity , setSeverity] = React.useState('') 
    const [loading , setLoading] = React.useState<boolean>(false);
    const [seederDetails , setSeederDetails] = React.useState<User>()
    const [userDetails, setUserDetails] = React.useState<UserDetails | undefined>(undefined);
    const [formKey, setFormKey] = React.useState(0);
    const [category, setCategory] = React.useState(false);
    const [rowCount,setRowCount]= React.useState<number>(0);
    const [filteredValue,setFilteredValue]= React.useState<string>("");
    const [pagination , setpagination] = React.useState({
      pageNo:1,
      pageSize:10
    })

    React.useEffect(() =>{
        setLoading(true)
        getSeederAction(pagination ,filteredValue)
        .then((res) =>{
        setLoading(false)
          if(!res.status){
          }else{
            setRowCount(res?.data?.totalCount)
            setSeeders(res.data?.seederCandidateListResponseDtos ? res.data?.seederCandidateListResponseDtos : [])
          }    
        })
        .catch((err) =>{
        setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")

        })
    },[pagination , filteredValue])
    React.useEffect(() =>{
        setLoading(true)
        getAllNonSeeders()
        .then((res) =>{
        setLoading(false)
          if(!res.status){
          }else{
            setUsers(res.data?.list ? res.data?.list : [])
          }    
        })
        .catch((err) =>{
        setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")

        })
    },[])

    function hashCode(str:string){
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
          let char = str.charCodeAt(i);
          hash = (hash * 31 + char) >>> 0; // Ensure 32-bit integer using unsigned right shift
      }
      return hash;
  }

  function sendNotification(values : Message){
    setLoading(true);
    const payload = {
      
        body: "",
        chatMessage: values?.Message ? values?.Message : "",
        groupId: null,
        receiverAlias: category,
        receiverId: values?.user ? values?.user : "",
        senderAlias: category,
        senderId: values?.seeder ? values?.seeder : "",
        title: ""
      
    }
      //Call Api To Add User
       sendSingleNotification(payload)
        .then((res) =>{
        if(!res?.status){
          setLoading(false)
          // setSeverity('error')
          // setOpenAlert(true)
          // setMessage(res?.message ? res.message : 'Request failed due to duplicity')
        }else{
          setLoading(false)
          // setSeverity('success')
          // setOpenAlert(true)
          // setMessage(res?.message ? res.message :  "Successfully Added")
        }
        })
        .catch((err) =>{
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          
        })
  }
  function updateMessage(values ){
    setLoading(true);

    const payload = {
      id:values?.user,
      hasMessage:true
      
    }
      //Call Api To Add User
      updateHasMessage(payload)
        .then((res) =>{
        if(!res?.status){
          setLoading(false)
          // setSeverity('error')
          // setOpenAlert(true)
          // setMessage(res?.message ? res.message : 'Request failed due to duplicity')
        }else{
          setLoading(false)
          // setSeverity('success')
          // setOpenAlert(true)
          // setMessage(res?.message ? res.message :  "Successfully Added")
        }
        })
        .catch((err) =>{
          setLoading(false)
          setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : "Error")
          
        })
  }

function hashGen(str: string): number {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
      hash = ((hash << 5) + hash) + str.charCodeAt(i); // hash * 33 + c
  }
  return hash >>> 0; // Ensure the result is a positive integer
}
    function selectedSeederDetail (details:any){
      setSeederDetails(details)
    
    }
    function selectedCategory(seederDetails:any , category:string){
      setCategory(category === 'true' ? true : false)
      setUserDetails((prev:any) =>{
        return {
          ...prev,
          ...seederDetails,
          is_anon_member:category === "false" ? false : true 
        }
      })
    }
    function setPage(){
      setpagination((prev) =>{ return {...prev , pageSize:prev.pageSize + 10}})
    }
    function handleFilterChange (value){
      setFilteredValue(value)
    }
    function reset(){
      setFilteredValue("")
      setpagination((prev) =>{
        return {
          ...prev , 
          pageSize:10
        }
      })
    }
    let initialvalues = {
      
        } 
    const addModalFields = [
        {
            name: 'seeder',
            label: 'Choose Seeder',
            type:'drop',
            items:seeders  ,
            key:'fullName',  
            details: selectedSeederDetail,
            callBack:setPage ,
            rowCount:rowCount,
            paginationSize:pagination?.pageSize,
            handleFilterChange:handleFilterChange,
            reset:reset
          },
          {
            name: 'user',
            label: 'Choose User',
            type:'grouping',
            items:users  ,
            keyFirst:'full_name',  
            keySecond:'anonymous_name', 
            categoryFirst:'Real Names',
            categorySecond:'Burner Usernames',
            category:selectedCategory
          },
          {
            name: 'Message',
            label: 'Message',
            type:'textarea',
            required:true
            
          },
    ];
    async function setChat(values:any){
      try{
        const now = new Date();
        const milliseconds = now.getTime();
        const conditionFirst = `${hashGen(`${values?.user}#AnonChat`) <= hashGen(`${values?.seeder}#AnonChat`) ? `${values?.user}#AnonChat_${values?.seeder}#AnonChat` : `${values?.seeder}#AnonChat_${values?.user}#AnonChat`}`
        const conditionSecond = `${hashGen(values?.user) <= hashGen(values?.seeder) ?   `${values?.user}_${values?.seeder}` :  `${values?.seeder}_${values?.user}`}`
        const chatDocRef = doc(db, 'chats', userDetails?.is_anon_member ? conditionFirst : conditionSecond)
        const subcollectionRef = collection(chatDocRef, 'messages');
        const messageDocRef = doc(subcollectionRef , `${milliseconds ? milliseconds : ""}`)
        const messageDocRefSnapShot = await getDoc(messageDocRef)

        const data = {
          toId:userDetails?.is_anon_member ? `${values?.user}#AnonChat` :  values?.user, // Riahna ID - Normal chat (Normal Id), Anon Chat (ID#AnonChat)
          msg: values?.Message, // Hi, How are you?
          read:"",
          type: 'text', // text
          fromId:userDetails?.is_anon_member ? `${values?.seeder}#AnonChat` : values?.seeder, // Neha ID - Normal chat (Normal Id), Anon Chat (ID#AnonChat)
          sent: milliseconds ? `${milliseconds}` : "" // string me timestamp
        };
        if (messageDocRefSnapShot.exists()) {
          // The document exists, so update it
          await setDoc(messageDocRef, data, { merge: true })
          setSeverity('success')
          setOpenAlert(true)
          setMessage('Document successfully updated!')
        } else {
          // The document does not exist, so create it
          await setDoc(messageDocRef, data);
          setSeverity('success')
          setOpenAlert(true)
          setMessage('Document successfully updated!')
        }
      }catch(error:any){
        setSeverity('error')
        setOpenAlert(true)
        // setMessage(error?.message)
        console.error('Error writing document: ', error);
      }finally {
        setLoading(false);
      }
    }
async function sendMessageToUser(values:any , time:boolean ){
  setLoading(true)
  try {
    const now = new Date();
    const milliseconds = now.getTime();
    const userDocRef = doc(db, 'network_users', time ?  values?.user : values?.seeder);
    const subcollectionRef = collection(userDocRef, 'friend_list');
    const friendDocRef = doc(subcollectionRef,time ?  values?.seeder : values?.user);

    // Check if the friend document exists in the subcollection
    const friendDocSnapshot = await getDoc(friendDocRef);

    const data = {
      is_anon_member:userDetails?.is_anon_member,
      last_message_text:values?.Message,
      last_message_time:milliseconds ? String(`${milliseconds}`) : "",
      member_anon_name:time ? seederDetails?.anonymousName : userDetails?.anonymous_name,
      member_id:time ? seederDetails?.id :  userDetails?.id,
      member_image:time ? seederDetails?.photoUrl : userDetails?.photo_url ,
      member_name:time ? seederDetails?.fullName :  userDetails?.full_name,
    };

    if (friendDocSnapshot.exists()) {
      // The document exists, so update it
      await setDoc(friendDocRef, data, { merge: true })
       time && await setChat(values)
      setSeverity('success')
      setOpenAlert(true)
      setMessage('Message sent successfully')
    } else {
      // The document does not exist, so create it
       await setDoc(friendDocRef, data);
       time && await setChat(values)
      setSeverity('success')
      setOpenAlert(true)
      setMessage('Message sent successfully')
    }
  } catch (error:any) {
    setSeverity('error')
    setOpenAlert(true)
    // setMessage(error?.message)
    console.error('Error writing document: ', error);
  }finally {
    setLoading(false);
    setFormKey(prevKey => prevKey + 1);
  }
 
}
async function sendMessageToAnon(values:any , time:boolean){
  setLoading(true)
  try {
    const now = new Date();
    const milliseconds = now.getTime();
    const userDocRef = doc(db, 'network_users', time ?  `${values?.user}` : `${values?.seeder}`);
    const subcollectionRef = collection(userDocRef, 'friend_list');
    const friendDocRef = doc(subcollectionRef,time ? `${values?.seeder}#AnonChat` : `${values?.user}#AnonChat`);

    // Check if the friend document exists in the subcollection
    const friendDocSnapshot = await getDoc(friendDocRef);

    const data = {
      is_anon_member:userDetails?.is_anon_member,
      last_message_text:values?.Message,
      last_message_time:milliseconds ? String(`${milliseconds}`) : "",
      member_anon_name:time ? seederDetails?.anonymousName : userDetails?.anonymous_name,
      member_id:time ? `${seederDetails?.id}#AnonChat` :  `${userDetails?.id}#AnonChat`,
      member_image:time ? seederDetails?.photoUrl : userDetails?.photo_url ,
      member_name:time ? seederDetails?.fullName :  userDetails?.full_name,
    };

    if (friendDocSnapshot.exists()) {
      // The document exists, so update it
      await setDoc(friendDocRef, data, { merge: true });
      time && await setChat(values)
      setSeverity('success')
      setOpenAlert(true)
      setMessage('Message sent successfully')
    } else {
      // The document does not exist, so create it
      await setDoc(friendDocRef, data);
      time && await setChat(values)
      setSeverity('success')
      setOpenAlert(true)
      setMessage('Message sent successfully')
    }
  } catch (error) {
    setSeverity('error')
    setOpenAlert(true)
    // setMessage(error?.message)
    console.error('Error writing document: ', error);
  }finally {
    setFormKey(prevKey => prevKey + 1);
    setLoading(false);
  }
 
}
  const handleSubmit =  (values:Message )=> {
    userDetails?.is_anon_member ? (sendMessageToAnon(values , true) , sendMessageToAnon(values , false)) : (sendMessageToUser(values , true ),sendMessageToUser(values , false))
    sendNotification(values)
    updateMessage(values)
    
  };

  return (
<>
<Layout title="Compose Messsage">
 <GenericForm title='Send Message'  key={formKey}   type='Edit Education' fields={addModalFields} initialValues={initialvalues}    onSubmit={handleSubmit}></GenericForm>
   </Layout>
   <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
</>
  )
}
