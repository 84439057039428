import React from "react";
import GenericForm from "../form/GenericForm";
import * as Yup from "yup";
import { addUsers } from "../../app/users/users";
import { doc, setDoc , addDoc , collection , updateDoc , getDoc} from "firebase/firestore";
import {db} from '../../firebase'
import dayjs from 'dayjs';

const UserForm = ({
  setAddUser,
  setOpenAlert,
  setMessage,
  setSeverity,
  setLoading,
  getUserDataHandler,
}) => {
  
  const initialvalues = {
    aliasName: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    isBornAfter2004: false,
    birthday:null
  };

  const addUser = async (values:any) =>{
    const now = new Date();
    const milliseconds = now.getTime();
    try{
    
      const docRef = doc(db, 'network_users', values?.id); // Create a document reference with the custom ID
  
      await setDoc(docRef, {
        id:`${values?.id}`,
        name: `${values?.fullName}` ,
        about:`${values?.aboutMe ? values?.aboutMe  : "Miami"}`,
        email:`${values?.email}`,
        anonName:`${values?.anonymousName}`,
        push_token:`Take FCM Token From Login API Response`,
        image:`${values?.profilePhotoUrl ?   values?.profilePhotoUrl : ""}`,
        created_at:milliseconds ? String(milliseconds) : "",
        is_online:true,
        last_active:milliseconds ? String(milliseconds) : "",
        isSeeder:false,
        aliasPhoto:`${values?.aliasUrl}`
  
      });
    }catch(err:any){
      throw new Error(err)
    }
  }
  const addAnonUser = async (values:any) =>{
    const now = new Date();
    const milliseconds = now.getTime();
    try{
    
      const docRef = doc(db, 'network_users', `${values?.id}#AnonChat`); // Create a document reference with the custom ID
  
      await setDoc(docRef, {
        id:`${values?.id}#AnonChat`,
        name: `${values?.fullName}` ,
        about:`${values?.aboutMe ? values?.aboutMe  : "Miami"}`,
        email:`${values?.email}`,
        anonName:`${values?.anonymousName}`,
        push_token:`Take FCM Token From Login API Response`,
        image:`${values?.profilePhotoUrl ?   values?.profilePhotoUrl : ""}`,
        created_at:milliseconds ? String(milliseconds) : "",
        is_online:true,
        last_active:milliseconds ? String(milliseconds) : "",
        isSeeder:false,
        aliasPhoto:`${values?.aliasUrl}`
      });
    }catch(err:any){
      throw new Error(err)
    }
   }
  const validation = Yup.object({
    aliasName: Yup.string().required("Alias Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string(),
    phoneNumber: Yup.string().matches(
      /^[0-9]{10}$/,
      "Phone number must be exactly 10 digits"
    ),
    password: Yup.string().required("Password is required"),
    isBornAfter2004: Yup.boolean(),
    birthday:Yup.date().required("Birth year required.")
  });

  const fields = [
    {
      label: "Burner Name",
      name: "aliasName",
      type: "text",
      required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "text", 
      required: true,
    },
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
    },
    {
      label: "Birth Year",
      name: "birthday",
      type: "DatePicker",
      disableTimePicker:true,
      datePickerFormat:"YYYY",
      datePickerView:["year"],
      maxDate:dayjs(`2004-12-31`),
      minDate:dayjs(`1997-01-01`),
      required:true
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      required: true,
    },
    {
      label: "Born After 2004",
      name: "isBornAfter2004",
      type: "checkbox",
    },
  ];

  const handleSubmit = async (values: any) => {
    const payload = {
      aliasName: values?.aliasName || null,
      email: values?.email || null,
      firstName: values?.firstName || null,
      lastName: values?.lastName || "",
      phoneNumber: values?.phoneNumber || "",
      password: values?.password || null,
      fcmToken: null,
      isBornAfter2004: values?.isBornAfter2004,
      birthday:dayjs(values?.birthday).format("YYYY")
    };

    try {
      const response = await addUsers(payload);
      if (response?.code === 200) {
         addUser(response?.data)
  .then(() =>{
    addAnonUser(response?.data)
    .then(() =>{
      setAddUser(false);
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(response?.message ? response.message : 'Successfully Added')
      getUserDataHandler();
    }).catch((err:any) =>{
      // deleteSeederById(response?.data?.id ? response?.data?.id : "" , 'firebase')
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err?.message || 'Error while adding user')
    })
   
  })
  .catch((err:any) =>{
    // deleteSeederById(response?.data?.id ? response?.data?.id : "" , 'firebase')
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage('Error while adding user')
  })
        
      }
    } catch (err: any) {
      setAddUser(false);
      setOpenAlert(true);
      setSeverity("error");
      setMessage(err?.message || "Unable to create the user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericForm
      title="Add User"
      validationSchema={validation}
      type="Add User"
      fields={fields}
      initialValues={initialvalues}
      onSubmit={handleSubmit}
    />
  );
};

export default UserForm;
