import React from 'react';
import Comment from './Comment';

export interface CommentData{
ans: string | null
candidateLinkedInId:string, 
comment:string, 
commentFeedStatus:boolean, 
creation_date:string, 
cred_COUNT:number,
dislikeCount:number,
email:string, 
feedCommentCount:number,
feedCommentResponseDtoResponse:{}
fullName:string, 
id:string, 
isDeleted:boolean,
isReported:boolean,
isVerified:boolean,
isdislike:boolean,
islike:boolean,
likeCount:number, 
mutual_interest:boolean,
photoUrl:string,
promptDetailsId:string,
replyCount:number,
totalCommentRating:number,
updateDate:string, 
}

interface ListData{
aboutMe:string,
anonymousName:string,
communities:[],
email:string,
firstName:string,
fullName:string,
id:string,
isSeeder:boolean,
lastName:string,
photoUrl:string,
totalRatingUser:number,
}
interface CommentListProps {
  comments: CommentData[];
  addReply: (commentId: string, replyText: string,username:string,aliasVal:boolean) => void;
  userList: ListData[];
  deleteData:(deleteId:string) => void;
}

const CommentList: React.FC<CommentListProps> = ({ comments, addReply, userList,deleteData }) => {
  return (
    <>
      {comments.length>0 && comments.map((comment) => (
        <Comment key={comment.id} comment={comment} addReply={addReply} userList={userList} deleteData={deleteData}/>
      ))}
    </>
  );
};

export default CommentList;
